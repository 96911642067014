import request from "@/utils/request";
const host = process.env.VUE_APP_MED || '';
// export function routertTo(params) {
//   return request({
//     url: "http://114.55.52.103:4026/api/banner/detail",
//     method: "get",
//     params,
//   });
// }
export function routertTo(params) {
  return request({
    url: host + "/api/banner/detail",
    method: "get",
    params,
  });
}

