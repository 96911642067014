<template>
  <div>
    <section>
      <div class="main1">
        <img
          width="100%"
          src="https://img.alicdn.com/imgextra/i4/O1CN01Oc2uOs1zPOvlXJ3dg_!!6000000006706-2-tps-1920-6555.png"
          alt=""
        />
        <div class="first" @click="goshop"></div>
        <div class="first1" @click="goshop1"></div>
        <div class="first2" @click="goshop3"></div>
        <div class="second" @click="gowang"></div>
        <div class="second1" @click="gowang1"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rightshow: true,
      phoneshow: false,
      a: "visible",
    };
  },
  methods: {
    changeStyle() {
      if (this.a === "visible") {
        this.a = "hidden";
      } else {
        this.a = "visible";
      }
    },
    gobuy() {
      let returnEle = null;
      returnEle = document.querySelector("#buy");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    goshop() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/588");
    },
    goshop1() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/589");
    },
    goshop3() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/590");
    },
    gowang() {
      window.open(
        "https://market.m.taobao.com/app/mtb/wwlight-web-air/wwlight.html?ver=3&touid=%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E5%AE%A2%E6%BB%A1&siteid=cntaobao&status=1&charset=utf-8"
      );
    },
     gowang1() {
      window.open(
        "https://www.taobao.com/go/market/webww/ww.php?ver=3&touid=txws大伟&siteid=cntaobao&status=1&charset=utf-8"
      );
    },
  },
};
</script>

<style scoped>
.first {
  position: absolute;
  width: 15.57%;
  height: 0.59%;
  bottom: 37.3%;
  left: 56.66%;
  cursor: pointer;
  top: 44.81%;
}

.first1 {
  position: absolute;
  width: 15.72%;
  height: 0.59%;
  bottom: 33.5%;
  left: 56.56%;
  top: 51.05%;
  cursor: pointer;
}

.first2 {
  position: absolute;
  width: 16.19%;
  height: 0.61%;
  top: 57.69%;
  left: 56.51%;
  cursor: pointer;
}

.second {
  position: absolute;
  width: 0.88%;
  height: 0.19%;
  bottom: 25.92%;
  left: 43.69%;
  top: 97.36%;
  cursor: pointer;
}

.second1 {
  position: absolute;
  width: 0.88%;
  height: 0.22%;
  bottom: 22.05%;
  left: 42.65%;
  top: 97.86%;
  cursor: pointer;
}

#buy {
  position: absolute;
  top: 0;
}
.buy {
  width: 470px;
  height: 55px;
  color: white;
  position: absolute;
  bottom: 13%;
  left: 50%;
  line-height: 50px;
}
.buy:hover {
  cursor: pointer;
}
.buysum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1 {
  text-align: center;
  padding-top: 50px;
  position: relative;
}

section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;

  margin: -50px auto 0;
  box-shadow: inset 0px 45px 10px -15px white;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
.headimg {
  position: absolute;
  width: 25%;
  bottom: 25%;
  left: 52%;
  transform: translateX(-50%);
}
.headimg:hover {
  cursor: pointer;
}
</style>
