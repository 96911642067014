<template>
  <h1 data-spm-anchor-id="0.0.0.i0.7303f3f1iOPuRs" style="text-align: center;">
    如果无法打开聊天窗口，请确保已经安装阿里旺旺或千牛客户端，<a
      href="//wangwang.taobao.com/"
      >下载地址</a
    >
  </h1>
</template>

<script>
export default {
  data() {
    return {
      bannerId: 0,
    };
  },
  methods: {
    close() {
      setTimeout(() => {
        if (
          navigator.userAgent.indexOf("Firefox") != -1 ||
          navigator.userAgent.indexOf("Chrome") != -1
        ) {
          window.location.href = "about:blank";
          window.close();
        } else {
          window.opener = null;
          window.open("", "_self");
          window.close();
        }
      }, 5000);
    },
  },
  created() {
    window.location.href =
      "aliim:sendmsg?touid=cntaobao" +
      this.$route.params.customerName +
      "&site=cntaobao&status=1";
    this.close();
  },
};
</script>
