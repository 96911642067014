<template>
  <div>
    <!-- <embed
      src="https://idaxue.taobao.com/live/ilive.jhtml?liveId=EDA634820EAC97724C93AF0BBC50461D"
      width="100%"
      height="1000"
    /> -->
    <!-- <embed
      src="https://idaxue.taobao.com/live/ilive.jhtml?liveId=EDA634820EAC97724C93AF0BBC50461D"
      width="2000"
      height="2000"
    /> -->

    <iframe
      seamless
      sandbox
      security="restricted"
      id="url_mainframe"
      frameborder="0"
      scrolling="yes"
      name="main"
      src="https://idaxue.taobao.com/live/ilive.jhtml?liveId=EDA634820EAC97724C93AF0BBC50461D"
      width="100%"
      height="1000"
    >
      该网站使用了框架技术，但是您的浏览器不支持框架，请升级您的浏览器以便正常访问。
    </iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {
    // var url = this.$route.params.search;
    // var url = window.location.search;
    // console.log(url);
  },
};
</script>

<style scoped></style>
