import request from "@/utils/request";

// 栏目列表
export function getTrends(params) {
  return request({
    url: "/api/product/content/list",
    method: "get",
    params,
  });
}
