<template>
  <div>
    <div class="content-header">
      <h2>{{ content.title }}</h2>
      <div class="headertitle">
        <img src="http://cdnupload.iwshang.com/23-23.png" alt="" />
        <div style="margin-left:10px">{{ content.author }}</div>
        <div>{{ content.createTime }}</div>
        <!-- <p>{{ content.author }} {{ content.createTime }}</p> -->
      </div>
      <div>
        <div class="content-section">
          <span>TAGS:</span>
          <span
            class="content-tag"
            v-for="(item, index) in content.tagList"
            :key="index"
          >
            <router-link
              :to="'/taglist/' + item.tagId + '?name=' + item.tagName"
              >{{ item.tagName }}</router-link
            >
          </span>
          <span class="content-span">
            分享：
            <span class="content-share"
              ><a
                :href="
                  `http://service.weibo.com/share/share.php?url=${url}&title=${content.title}&desc=天下网商-传播新商业,赋能新商业,成就新商业`
                "
                target="_blank"
                class="a1"
              >
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconweibo"></use>
                </svg>
              </a>
              <a
                :href="
                  `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${url}&sharesource=qzone&title=${content.title}&pics=${img}&summary=天下网商-传播新商业,赋能新商业,成就新商业`
                "
                target="_blank"
                class="a2"
                ><svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconkongjian"></use>
                </svg>
              </a>
              <a
                :href="
                  `http://connect.qq.com/widget/shareqq/index.html?url=${url}&sharesource=qq&title=${content.title}&pics=${img}&desc=天下网商-传播新商业,赋能新商业,成就新商业`
                "
                target="_blank"
                class="a3"
              >
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconqq"></use></svg></a></span
          ></span>
        </div>
      </div>
    </div>
    <hr />
    <div class="content-body" ecsape="false">
      <div class="summary"><em>摘要：</em> {{ content.intro }}</div>
      <span v-html="content.content" class="con-img"></span>
    </div>
    <div style="height:100px">
      <span class="content-span">
        分享：
        <span class="content-share"
          ><a
            :href="
              `http://service.weibo.com/share/share.php?url=${url}&title=${content.title}&desc=天下网商-传播新商业,赋能新商业,成就新商业`
            "
            target="_blank"
            class="a1"
          >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconweibo"></use>
            </svg>
          </a>
          <a
            :href="
              `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${url}&sharesource=qzone&title=${content.title}&pics=${img}&summary=天下网商-传播新商业,赋能新商业,成就新商业`
            "
            target="_blank"
            class="a2"
            ><svg class="icon" aria-hidden="true">
              <use xlink:href="#iconkongjian"></use>
            </svg>
          </a>
          <a
            :href="
              `http://connect.qq.com/widget/shareqq/index.html?url=${url}&sharesource=qq&title=${content.title}&pics=${img}&desc=天下网商-传播新商业,赋能新商业,成就新商业`
            "
            target="_blank"
            class="a3"
          >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconqq"></use></svg></a></span
      ></span>
    </div>
  </div>
</template>

<script>
import { getDetail } from "@/api/detail";
export default {
  name: "Section",
  data() {
    return {
      content: {},
      contentid: 0,
      url: "",
      img: "",
    };
  },
  methods: {
    getContent(cid) {
      let params = {};
      params.contentId = cid;

      getDetail(params).then((res) => {
        this.content = res.data.data;
        this.$bus.emit("sendColumnId", this.content.columnId);
        document.title =
          this.content.title + "-天下网商-传播新商业,赋能新商业,成就新商业";
      });
    },
  },
  mounted() {
    this.getContent(this.$route.params.cid);
    this.contentid = this.$route.params.cid;
    this.url = window.location.href;

    this.img = document.images[1].src;
  },
};
</script>

<style scoped>
.headertitle {
  display: flex;
  align-items: center;
}
.headertitle div {
  font-size: 14px;
  color: #898989;
  margin: 20px 0 20px 20px;
}
.content-header {
  bottom: 1px solid #dbdbdb;
  padding-bottom: 20px;
}
.content-header h2 {
  font-size: 36px;
  line-height: 48px;
  color: #010101;
  margin: 0;
}
.content-header p {
  font-size: 14px;
  color: #898989;
  padding: 20px 0 13px;
  margin-bottom: 0;
}
.content-section {
  width: 729px;
}
.content-tag {
  padding-top: 5px;
}
.content-tag a {
  background-color: #4aabf0;
  border-radius: 2px;
  color: #fff;
  height: 19px;
  line-height: 19px;
  padding: 2px 5px;
  margin-right: 10px;
  margin-left: 5px;
  font-size: 13px;
}

.content-span {
  float: right;
}
.content-share {
  float: right;
  width: 110px;
  display: block;
  height: 30px;
}
.content-share .a1 {
  float: left;
  text-decoration: none;
  width: 25px;
  height: 25px;
  display: block;
  margin-left: 0;
}
.content-share .a2 {
  float: left;
  text-decoration: none;
  width: 25px;
  height: 25px;
  display: block;
  margin-left: 10px;
}
.content-share .a3 {
  float: left;
  text-decoration: none;
  width: 25px;
  height: 25px;
  display: block;
  margin-left: 10px;
}

.content-body {
  margin-top: 30px;
}
.content-body p {
  font-size: 16px;
  color: #333;
  line-height: 30px;
}
.content-body p img {
  max-width: 100%;
  text-align: center;
}
.summary {
  background-color: #f6f6f6;
  border-radius: 3px;
  padding: 13px 30px;
  font-size: 14px;
  color: #4a4a4a;
  line-height: 30px;
  margin-bottom: 25px;
}
.summary em {
  font-weight: bold;
  color: #000;
}
.con-img /deep/ p {
  color: #333;
  line-height: 30px;
  text-align: justify;
  font-size: 16px;
  margin: 0 0 26px 0;
}
.con-img /deep/ img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  display: block;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  font-size: 25px;
  color: rgb(87, 164, 216);
}
</style>
