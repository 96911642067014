<template>
  <div>
    <header>
      <span>
        <img
          src="https://img.alicdn.com/tfs/TB1_6Gqvj39YK4jSZPcXXXrUFXa-105-36.png"
          alt=""
        />
      </span>
      <el-menu
        default-active="1"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="transparent"
        text-color="black"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">参会嘉宾</el-menu-item>
        <el-menu-item index="3">大会议程</el-menu-item>
        <el-menu-item index="4">早鸟票预售</el-menu-item>
        <el-menu-item index="5">合作媒体</el-menu-item>
        <el-menu-item index="6">联系我们</el-menu-item>
      </el-menu>
      <el-button type="info" style="margin:0 200px"
        ><a
          href="https://www.tianxiawangshang.com/product/active_wsfh/newispower/review/index.html"
          >峰会回顾</a
        ></el-button
      >
    </header>
    <div class="banner" id="home">
      <img
        src="https://img.alicdn.com/tfs/TB1xu1jp9slXu8jSZFuXXXg7FXa-1920-552.jpg"
        alt=""
      />
    </div>
    <section>
      <div>
        <div class="left">
          <p>2020注定是一个被写进商业史册的年份</p>
          <p>一场突如其来的疫情</p>
          <p>加速了全社会商业数字化的进程</p>
          <p>新消费人群更迭，新消费品牌崛起</p>

          <p>新营销持续“造浪”，新制造蓬勃发展</p>
          <p>在时代的变革中</p>
          <p>新的商业力量在孕育</p>

          <p style="margin-top: 50px;">2020年末，我们继续以新商业媒体身份</p>
          <p>与数千万新商业从业者一起</p>
          <p>盘点这一年的商业里程碑大事件</p>
          <p>共同评选新网商年度影响力人物</p>
        </div>
        <div class="right">
          <p>
            时间：2020年12月22日
          </p>
          <p>地点：中国·杭州 泛海钓鱼台酒店</p>
        </div>
      </div>
      <div class="middle">
        <span class="topspan">亮点速度</span>
        <div class="boxsum">
          <div class="box1">
            <img
              style="width:100%"
              src="https://img.alicdn.com/imgextra/i2/O1CN013AFae31uKIC9NerPJ_!!6000000006018-2-tps-157-78.png"
              alt=""
            />
          </div>
          <!-- <div class="box2"></div>
          <div class="box3"></div> -->
          <div class="span1">
            <div>五年沉淀</div>
            <div>极具电商影响力</div>
          </div>
          <div class="span2">
            <div>超百家</div>
            <div>媒体报道</div>
          </div>
          <div class="span2">
            <div>全媒体矩阵</div>
            <div>千万曝光</div>
          </div>
        </div>
        <div class="forspan" id="comeperson">FORUM GUESTS</div>
        <div class="comespan">参会嘉宾</div>
        <div class="personsum">
          <div>
            <div class="person">
              <img
                style="width:100%"
                src="https://img.alicdn.com/imgextra/i4/O1CN01yHZpGw1EBOdjpBWow_!!6000000000313-2-tps-210-210.png"
                alt=""
              />
            </div>
            <div class="name">吴敏芝</div>
            <div class="position">阿里巴巴集团</div>
            <div class="position">首席客户官</div>
          </div>
          <div>
            <div class="person">
              <img
                style="width:100%"
                src="https://img.alicdn.com/imgextra/i4/O1CN01XUowzm1uYwruzskT9_!!6000000006050-2-tps-212-212.png"
                alt=""
              />
            </div>
            <div class="name">董本洪</div>
            <div class="position">阿里巴巴集团</div>
            <div class="position">首席市场官</div>
          </div>
          <div>
            <div class="person">
              <img
                style="width:100%"
                src="https://img.alicdn.com/imgextra/i3/O1CN01H47xzG1la1Fhu1gsU_!!6000000004834-2-tps-212-212.png"
                alt=""
              />
            </div>
            <div class="name">葛景栋</div>
            <div class="position">微博</div>
            <div class="position">高级副总裁</div>
          </div>
          <div>
            <div class="person">
              <img
                style="width:100%"
                src="https://img.alicdn.com/imgextra/i1/O1CN01s3Q0or1e3JessL2mw_!!6000000003815-2-tps-213-213.png"
                alt=""
              />
            </div>
            <div class="name">漫天</div>
            <div class="position">阿里巴巴集团淘宝内容</div>
            <div class="position">电商事务部资深总裁</div>
          </div>
          <div>
            <div class="person">
              <img
                style="width:100%"
                src="https://img.alicdn.com/imgextra/i4/O1CN01GOAlGm1erhEIldJc3_!!6000000003925-2-tps-210-210.png"
                alt=""
              />
            </div>
            <div class="name">周树颖</div>
            <div class="position">泡泡玛特</div>
            <div class="position">首席消费者运营官</div>
          </div>
          <div>
            <div class="person">
              <img
                style="width:100%"
                src="https://img.alicdn.com/imgextra/i3/O1CN014dmzRY1roZX3brOyS_!!6000000005678-2-tps-210-210.png"
                alt=""
              />
            </div>
            <div class="name">度岳</div>
            <div class="position">天猫快速消费品事业部</div>
            <div class="position">
              全链路营销&
            </div>
            <div class="position">商家运营中心总经理</div>
          </div>
          <div>
            <div class="person">
              <img
                style="width:100%"
                src="https://img.alicdn.com/imgextra/i3/O1CN01XEfBRt1sQ80bLqYvr_!!6000000005760-2-tps-210-210.png"
                alt=""
              />
            </div>
            <div class="name">蓝飞</div>
            <div class="position">溪木源</div>
            <div class="position">联合创始人</div>
          </div>
          <div>
            <div class="person">
              <img
                style="width:100%"
                src="https://img.alicdn.com/imgextra/i2/O1CN01Bvp9mQ1bvPayQHFrN_!!6000000003527-2-tps-213-213.png"
                alt=""
              />
            </div>
            <div class="name">孙仕军</div>
            <div class="position">认养一头牛</div>
            <div class="position">联合创始人-CEO</div>
          </div>
          <div>
            <div class="person">
              <img
                style="width:100%"
                src="https://img.alicdn.com/imgextra/i3/O1CN015yOMsG1eABbMeARP7_!!6000000003830-2-tps-213-213.png"
                alt=""
              />
            </div>
            <div class="name">吾悾</div>
            <div class="position">天猫家装</div>
            <div class="position">营销总经理</div>
          </div>
        </div>
        <div class="forspan" id="meeting">THE AGENDA</div>
        <div class="comespan">大会议程</div>
        <div class="pm">上午场</div>
        <div class="pmmain">
          <div class="pmtime">
            <div>9:30-10:00</div>
            <div>10:00-10:30</div>
            <div>10:30-11:00</div>
            <div>11:00-11:30</div>
            <div>11:30-12:00</div>
            <div>12:00-12:15</div>
          </div>
          <div class="pmstep">
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
          </div>
          <div class="pmcontent">
            <div>
              <div class="pmcontent1">数字新体验构造品牌力</div>
              <div class="pmcontent2">阿里巴巴集团首席市场官 吴敏芝</div>
            </div>
            <div>
              <div class="pmcontent1">品牌数字化的三股浪潮</div>
              <div class="pmcontent2">阿里巴巴集团首席市场官 董本洪</div>
            </div>
            <div>
              <div class="pmcontent1">社交新生力-微博电商价值之路</div>
              <div class="pmcontent2">微博高级副总裁 葛景栋</div>
            </div>
            <div>
              <div class="pmcontent1">内容电商引领新增长</div>
              <div class="pmcontent2">
                阿里巴巴集团淘宝内容电商事业部资深总监 漫天
              </div>
            </div>
            <div>
              <div class="pmcontent1">潮玩化营销与IP破圈</div>
              <div class="pmcontent2">泡泡玛特首席消费者运营官 周树颖</div>
            </div>
            <div>
              <div class="pmcontent1">揭晓仪式</div>
              <div class="pmcontent2">2020年度新商业人物</div>
            </div>
          </div>
        </div>
        <div class="am">下午场</div>
        <div class="amtop">新势力《新锐品牌的商业势能》</div>
        <div class="ammain">
          <div class="amtime">
            <div>13:30-14:00</div>
            <div>14:00-14:30</div>
            <div>14:30-15:00</div>
            <div>15:00-15:10</div>
          </div>
          <div class="amstep">
            <div class="amstep1"></div>
            <div class="amstep2"></div>
            <div class="amstep1"></div>
            <div class="amstep2"></div>
            <div class="amstep1"></div>
            <div class="amstep2"></div>
            <div class="amstep1"></div>
          </div>
          <div class="amcontent">
            <div>
              <div class="amcontent1">消费创新下的新品牌生态</div>
              <div class="amcontent2" style="font-size:28px">
                天猫快速消费品事业部全链路营销&商家运营中心总经理 度岳
              </div>
            </div>
            <div>
              <div class="amcontent1">新消费 新形态</div>
              <div class="amcontent2">溪木源联合创始人 蓝飞</div>
            </div>
            <div>
              <div class="amcontent1">新消费品牌的破圈之道</div>
              <div class="amcontent2">认养一头牛联合创始人、CEO 孙仕军</div>
            </div>
            <div>
              <div class="amcontent1">揭晓仪式</div>
              <div class="amcontent2">2020新网商服务商奖</div>
            </div>
          </div>
        </div>
        <div class="amtop">新增长《新赛道下，天猫家装增长的机会与挑战》</div>
        <div class="ammain">
          <div class="amtime">
            <div>15:10-15:40</div>
            <div>15:40-16:10</div>
            <div>16:10-17:20</div>
          </div>
          <div class="amstep">
            <div class="amstep1"></div>
            <div class="amstep2"></div>
            <div class="amstep1"></div>
            <div class="amstep2"></div>
            <div class="amstep1"></div>
          </div>
          <div class="amcontent">
            <div>
              <div class="amcontent1">后疫情时代黑马行业的增长密码</div>
              <div class="amcontent2">天猫家装营销总经理 吾悾</div>
            </div>
            <div>
              <div class="amcontent1">天猫家装消费趋势洞察报告</div>
              <div class="amcontent2">天猫家装x阿里妈妈</div>
            </div>
            <div>
              <div class="amcontent1" id="ticket">天猫家装品牌代表</div>
              <div class="amcontent2">抓住新商业风口，他们为何在2020年爆发</div>
            </div>
          </div>
        </div>
        <div class="topticket">门票预售</div>
        <div class="ticket">
          <div class="ticket1">
            <div style="display:flex">
              <div class="chooseticket">普通票</div>
              <div class="money">580元</div>
            </div>
            <div>
              <div class="link">
                <span
                  ><a href="http://r6d.cn/Le8a" target="_blank"
                    >点击购票</a
                  ></span
                >
              </div>
            </div>
            <div class="desc">普通票权益：大会全天通票</div>
          </div>
          <div class="ticket2">
            <div style="display:flex">
              <div class="chooseticket">VIP票</div>
              <div class="money">580元</div>
            </div>
            <div>
              <div class="link">
                <span
                  ><a href="http://r6d.cn/Le8a" target="_blank"
                    >点击购票</a
                  ></span
                >
              </div>
            </div>
            <div class="desc">
              VIP票专属权益：仅限100席 大会全天通票 快速签到通道 VIP专属席位
              会议授权资料
            </div>
          </div>
        </div>
        <div id="together"></div>
        <div class="sponsor" style="margin-top:150px">主办方</div>
        <div class="sponsorimg">
          <img src="../active_wsfh/timing/Pic/tianxia.jpg" alt="天下网商" />
        </div>
        <div class="sponsor">合作支持</div>
        <div class="sponsorimg">
          <img src="../active_wsfh/timing/Pic/alibaba.jpg" alt="阿里巴巴" />
        </div>
        <div class="sponsor">活动支持</div>
        <div class="sponsorimg">
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN015IjLfV1laTeK6pC3c_!!6000000004835-2-tps-376-98.png"
            alt="活动行"
          />
        </div>
        <footer>
          <div id="contact">
            <p>联系我们</p>
            <ul>
              <li>
                <ul>
                  <h1>商务合作：</h1>
                  <h3>院长</h3>
                  <h4>wechat：edison6066</h4>
                  <h4>Email：wb-wds464292@alibaba-inc.com</h4>
                </ul>
              </li>
              <li>
                <ul>
                  <h1>媒体合作：</h1>
                  <h3>Suri</h3>
                  <h4>wechat：88288769</h4>
                  <h4>E-mail：wb-xjd259705@alibaba-inc.com</h4>
                </ul>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleSelect(index) {
      let returnEle = null;
      if (index === "1") {
        returnEle = document.querySelector("#home");
      }
      if (index === "2") {
        returnEle = document.querySelector("#comeperson");
      }
      if (index === "3") {
        returnEle = document.querySelector("#meeting");
      }
      if (index === "4") {
        returnEle = document.querySelector("#ticket");
      }
      if (index === "5") {
        returnEle = document.querySelector("#together");
      }
      if (index === "6") {
        returnEle = document.querySelector("#contact");
      }
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
  },
};
</script>

<style scoped>
.ticket {
  display: flex;
  width: 65%;
  margin: 50px auto 0;
}
.topticket {
  color: pink;
  text-align: center;
  font-size: 90px;
  font-weight: 700;
  letter-spacing: 15px;
}
.chooseticket {
  width: 200px;
  height: 100px;
  background-color: #ac642a;
  text-align: center;
  line-height: 100px;
  font-size: 40px;
  font-weight: 500;
  color: white;
}
.money {
  width: 400px;
  height: 100px;
  background-color: #f0a35f;
  text-align: center;
  line-height: 100px;
  font-size: 40px;
  font-weight: 500;
  color: blue;
  margin-bottom: 20px;
}
.link {
  width: 362px;
  height: 45px;
  background-color: rgb(83, 251, 116);
  margin: 0 auto;
  margin-bottom: 20px;
}
.link > span {
  padding: 6px;
  display: block;
  text-align: center;
}
.link > span > a {
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  color: black;
}
.desc {
  color: white;
  text-align: center;
}
.ticket2 {
  margin-left: 90px;
}
.sponsor {
  background-color: #f0a35f;
  width: 400px;
  height: 70px;
  margin: 30px auto;
  text-align: center;
  line-height: 70px;
  font-size: 40px;
  font-weight: 600;
}
.sponsorimg {
  width: 400px;
  height: 70px;
  margin: 0 auto;
  text-align: center;
}
footer {
  width: 100%;
  margin-top: 150px;
  min-width: 1600px;
  background-image: url("https://img.alicdn.com/tfs/TB1tCJY4Uz1gK0jSZLeXXb9kVXa-1920-407.jpg");
  background-size: cover;
  height: 410px;
}

footer > div {
  width: 1175px;
  height: 382px;
  margin: 0 auto;
}
footer > div > p {
  color: black;

  font-weight: bold;
  font-size: 37px;
  margin: 0 auto;
  width: 150px;
  height: 60px;
  padding-top: 39px;
}
footer > div > ul {
  width: 1015px;
  height: 175px;
  margin: 0 auto;
}
footer > div > ul > li {
  float: left;
  width: 415xp;
  height: 162px;
  list-style-type: none;
  margin: 0 70px;
}
/* 大会议程 */
.personsum {
  width: 900px;
  margin: 40px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: top;
  flex-wrap: wrap;
}
.person {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 40px 20px 0;
}
.name {
  text-align: center;
  color: #66c060;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 5px;
}
.position {
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 450;
  letter-spacing: 4px;
}
.pm {
  width: 250px;
  height: 90px;
  background-color: #8bf684;
  margin-left: 19%;
  border-radius: 25px;
  margin-top: 50px;
  text-align: center;
  font-size: 58px;
  font-weight: 900;
  line-height: 90px;
}
.pmmain {
  margin-left: 19%;
  display: flex;
  margin-top: 130px;
}
.pmtime {
  color: #8bf684;
  font-size: 35px;
  font-weight: 600;
}
.pmtime div {
  margin-bottom: 140px;
}
.pmstep {
  margin: 10px 0 0 60px;
}
.pmstep1 {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #8bf684;
}
.pmstep2 {
  height: 170px;
  border-right: 2px solid #8bf684;
  margin-right: 10px;
}
.pmcontent {
  margin: -30px 0 0 70px;
}
.pmcontent > div {
  height: 125px;
  width: 815px;
  border: 1px solid #8bf684;
  margin: 0 0 65px 0;
  padding: 15px 25px;
  box-sizing: border-box;
}
.pmcontent1 {
  color: #8bf684;
  font-size: 32px;
  font-weight: 500;
}
.pmcontent2 {
  color: white;
  font-size: 32px;
  font-weight: 500;
}
.am {
  width: 250px;
  height: 90px;
  background-color: #f0a35f;
  margin-left: 19%;
  border-radius: 25px;
  text-align: center;
  font-size: 58px;
  font-weight: 900;
  line-height: 90px;
}
.amtop {
  color: white;
  margin-left: 19%;
  margin-top: 20px;
  font-size: 45px;
  font-weight: 700;
}
.ammain {
  margin-left: 19%;
  display: flex;
  margin-top: 80px;
}
.amtime {
  color: #f0a35f;
  font-size: 35px;
  font-weight: 600;
}
.amtime div {
  margin-bottom: 140px;
}
.amstep {
  margin: 10px 0 0 60px;
}
.amstep1 {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #f0a35f;
}
.amstep2 {
  height: 170px;
  border-right: 2px solid #f0a35f;
  margin-right: 10px;
}
.amcontent {
  margin: -30px 0 0 70px;
}
.amcontent > div {
  height: 125px;
  width: 815px;
  border: 1px solid #f0a35f;
  margin: 0 0 65px 0;
  padding: 15px 25px;
  box-sizing: border-box;
}
.amcontent1 {
  color: #f0a35f;
  font-size: 32px;
  font-weight: 500;
}
.amcontent2 {
  color: white;
  font-size: 32px;
  font-weight: 500;
}
/* 参会嘉宾 */
.middle {
  width: 100%;
}
.topspan {
  color: aquamarine;
  margin-left: 20%;
  font-size: 5rem;
  font-weight: 700;
}
.forspan {
  background-image: -webkit-linear-gradient(left, pink, blue, green);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 19%;
  font-size: 4.5rem;
  font-weight: 600;
}
.comespan {
  color: white;
  margin-left: 19%;
  font-size: 3.1rem;
  font-weight: 400;
}
.boxsum {
  display: flex;
  margin-top: 50px;
}
.box1 {
  width: 17%;
  height: 150px;
}
.box2 {
  width: 50px;
  height: 150px;
  background-image: linear-gradient(to bottom right, green, yellow);
  margin-left: 40px;
}
.box3 {
  width: 8%;
  height: 150px;
  background-image: linear-gradient(to bottom right, green, yellow);
  margin-left: 40px;
}
.span1 {
  margin-left: 3%;
}
.span1 div {
  color: white;
  font-size: 3rem;
  font-weight: 700;
  display: block;
  margin: 0 0 10px 0;
}
.span2 {
  border-left: 2px solid white;
  margin-left: 120px;
}
.span2 div {
  color: white;
  font-size: 3rem;
  font-weight: 700;
  display: block;
  margin: 0 0 10px 0;
  padding-left: 120px;
}
header {
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 1600px;
  height: 90px;
  background-image: url("https://img.alicdn.com/tfs/TB1bekytfzO3e4jSZFxXXaP_FXa-1920-87.jpg");
  background-size: cover;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header span {
  margin: 0 15%;
}
.el-menu-demo {
  min-width: 650px;
}
.el-menu-item {
  font-size: 17px;
  font-weight: 500;
}
.el-menu-item:hover {
  background-color: transparent !important;
}
.el-submenu__title:hover {
  background-color: transparent !important;
}
.banner {
  height: 550px;
  min-width: 1600px;
  width: 100%;
}
.banner > img {
  width: 100%;
  height: 100%;
}
section {
  width: 100%;
  min-width: 1600px;
  height: 100%;
  background-image: url("https://img.alicdn.com/tfs/TB1CL85vz39YK4jSZPcXXXrUFXa-1920-7975.jpg");
  background-size: cover;
}
section > div {
  margin: 0 auto;
}
section > div:nth-child(1) {
  width: 1175px;
  height: 845px;
}
section > div:nth-child(1) > .left {
  width: 585px;
  height: 585px;
  padding-top: 80px;
}
section > div:nth-child(1) > .left > p {
  color: rgb(254, 254, 254);
  font-size: 26px;
  font-weight: 400;
  margin: 0 0 10px 0;
}

section > div:nth-child(1) > .right {
  width: 485px;
  height: 105px;
  float: right;

  margin-top: 100px;
}
section > div:nth-child(1) > .right > p {
  color: rgb(254, 254, 254);
  font-size: 26px;
  font-weight: 400;
  margin: 0 0 10px 80px;
}
</style>
