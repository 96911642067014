<template>
  <div class="bcgcolor">
    <section>
      <div class="main1">
        <div class="topicon">
          <div class="main1title2">
            <img
              width="100%"
              src="https://img.alicdn.com/imgextra/i4/O1CN011NmLzt1nW13CRocCJ_!!6000000005096-2-tps-3788-1057.png"
              alt=""
            />
          </div>
          <div class="main1title3">
            <img
              width="100%"
              src="https://img.alicdn.com/imgextra/i1/O1CN01EdfN0R1TAgHc16qfb_!!6000000002342-2-tps-81-91.png"
              alt=""
            />
          </div>
        </div>
        <div class="main1sum">
          <div class="main1title1">
            逛逛官方运营宝典
          </div>
        </div>
      </div>
      <div class="tabsum">
        <div class="tab tableft" @click="openmorea">官方规则</div>
        <div class="tab" @click="openmoreb">入门篇</div>
        <div class="tab" @click="openmorec">基础篇</div>
        <div class="tab" @click="openmored">进阶篇</div>
        <div class="tab tabright" @click="openmoree">
          双十一专题
          <img
            width="80px"
            src="https://img.alicdn.com/imgextra/i4/O1CN0175C50m22jUxBjmpnf_!!6000000007156-2-tps-248-283.png"
            alt=""
          />
        </div>
      </div>
      <div class="main2">
        <div>
          <div class="level" style="margin-top:40px">
            <div class="rumenimg">
              <img
                width="35px"
                src="https://img.alicdn.com/imgextra/i1/O1CN01EdfN0R1TAgHc16qfb_!!6000000002342-2-tps-81-91.png"
                alt=""
              />
              <div style="margin-left:20px">
                入门
              </div>
            </div>
          </div>
          <div class="studymore" @click="openmore">>>学习更多</div>
          <div class="levelimgsum">
            <div @click="openmore1" class="levelimg" style="margin-left:0">
              入住流程
            </div>
            <div @click="openmore2" class="levelimg">
              身份认证
            </div>
            <div @click="openmore3" class="levelimg">
              权限开通
            </div>
            <div @click="openmore4" class="levelimg">内容标准解读</div>
          </div>
          <div class="level">
            <div class="rumenimg">
              <img
                width="35px"
                src="https://img.alicdn.com/imgextra/i1/O1CN01EdfN0R1TAgHc16qfb_!!6000000002342-2-tps-81-91.png"
                alt=""
              />
              <div style="margin-left:20px">
                基础
              </div>
            </div>
          </div>
          <div class="studymore" @click="openmore">>>学习更多</div>
          <div class="levelimgsum">
            <div @click="openmore5" class="levelimg" style="margin-left:0">
              产品定位
            </div>
            <div @click="openmore6" class="levelimg">
              展现渠道
            </div>
            <div @click="openmore7" class="levelimg">
              内容调整
            </div>
            <div @click="openmore8" class="levelimg">渠道价值</div>
          </div>
          <div class="level">
            <div class="rumenimg">
              <img
                width="35px"
                src="https://img.alicdn.com/imgextra/i1/O1CN01EdfN0R1TAgHc16qfb_!!6000000002342-2-tps-81-91.png"
                alt=""
              />
              <div style="margin-left:20px">
                进阶
              </div>
            </div>
          </div>
          <div class="studymore" @click="openmore">>>学习更多</div>
          <div class="levelimgsum">
            <div @click="openmore9" class="levelimg" style="margin-left:0">
              短视频创作
            </div>
            <div @click="openmore10" class="levelimg">
              流量引入
            </div>
            <div @click="openmore11" class="levelimg">
              高质量内容产出
            </div>
            <div @click="openmore12" class="levelimg">商品下挂</div>
          </div>
        </div>
        <div class="btimg">
          <img
            width="600px"
            src="https://img.alicdn.com/imgextra/i4/O1CN019WyK721UDG8BiE04K_!!6000000002483-2-tps-4798-728.png"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    openmorea() {
      window.open("https://www.yuque.com/tbgg/tob/ses30g");
    },
    openmoreb() {
      window.open(
        "https://www.yuque.com/docs/share/9415c35c-1e75-484a-b2eb-fe48b00e7870"
      );
    },
    openmorec() {
      window.open(
        "https://www.yuque.com/docs/share/475723c1-6d64-4894-8809-1316684c69f5"
      );
    },
    openmored() {
      window.open(
        "https://www.yuque.com/docs/share/3c43bbdb-b2cb-4671-b03a-5cb98de6074a"
      );
    },
    openmoree() {
      window.open(
        "https://www.yuque.com/docs/share/1e592da4-215f-4a69-aa3b-c89c03cbbf3c"
      );
    },
    openmore() {
      window.open(
        "https://idaxue.taobao.com/page/class/info.jhtml?classId=100006951"
      );
    },
    openmore1() {
      window.open(
        "https://idaxue.taobao.com/play.jhtml?classId=100006951&chapterId=107033127"
      );
    },
    openmore2() {
      window.open(
        "https://idaxue.taobao.com/play.jhtml?classId=100006951&chapterId=107033129"
      );
    },
    openmore3() {
      window.open(
        "https://idaxue.taobao.com/play.jhtml?classId=100006951&chapterId=107033130"
      );
    },
    openmore4() {
      window.open(
        "https://idaxue.taobao.com/play.jhtml?classId=100006951&chapterId=107755414"
      );
    },
    openmore5() {
      window.open(
        "https://idaxue.taobao.com/play.jhtml?classId=100006951&chapterId=107497320"
      );
    },
    openmore6() {
      window.open(
        "https://idaxue.taobao.com/play.jhtml?classId=100006951&chapterId=107497321"
      );
    },
    openmore7() {
      window.open(
        "https://idaxue.taobao.com/play.jhtml?classId=100006951&chapterId=107497322"
      );
    },
    openmore8() {
      window.open(
        "https://idaxue.taobao.com/play.jhtml?classId=100006951&chapterId=107497323"
      );
    },
    openmore9() {
      window.open(
        "https://idaxue.taobao.com/live/ilive.jhtml?liveId=027787F98EF78C83FF0D092F25FFF719"
      );
    },
    openmore10() {
      window.open(
        "https://idaxue.taobao.com/live/ilive.jhtml?liveId=870D149A208B11AF01FF80D407F9B07F"
      );
    },
    openmore11() {
      window.open(
        "https://idaxue.taobao.com/live/ilive.jhtml?liveId=8522D4020481791B5AEF80BD4A411717"
      );
    },
    openmore12() {
      window.open(
        "https://idaxue.taobao.com/live/ilive.jhtml?liveId=9D535C900F98A79BC46EBA9409408E4F"
      );
    },
  },
};
</script>

<style scoped>
.tableft {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.tabright {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
}
.tab {
  width: 260px;
  height: 80px;
  line-height: 80px;
  background-color: #894df6;
  box-sizing: border-box;
  color: white;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 7px 7px 15px #eed097;
}
.btimg {
  width: 100%;
  height: 200px;
  line-height: 290px;
  background-color: black;
  margin: 150px auto 0;
  text-align: center;
}
.rumenimg {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.level {
  width: 1300px;
  height: 50px;
  line-height: 50px;
  background-color: #6429e0;
  color: white;
  font-size: 30px;
  font-weight: 600;
  margin: 50px auto 0;
}
.levelimgsum {
  width: 1300px;
  margin: 10px auto 0;
  display: flex;
}
.levelimg {
  color: white;
  font-size: 25px;
  font-weight: 500;
  line-height: 80px;
  width: 300px;
  height: 120px;
  margin-left: 40px;
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01Bbnwkf1KEV2vSr11L_!!6000000001132-2-tps-290-118.png");
  background-size: cover;
  cursor: pointer;
}
.studymore {
  width: 1300px;
  text-align: right;
  margin: 10px auto 0;
  font-size: 25px;
  font-weight: 500;
  cursor: pointer;
}
.main1title1 {
  color: white;
  font-size: 75px;
  font-weight: 700;
}
.main1title2 {
  /* top: 220px;
  left: 965px;
  position: absolute; */
  width: 150px;
  margin-left: 20px;
}
.main1title3 {
  /* top: 215px;
  left: 1130px;
  position: absolute; */
  width: 50px;
  margin-left: 20px;
}
.main1sum {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.topicon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 50px 0 0;
}
.tabsum {
  width: 1300px;
  text-align: center;
  display: flex;
  margin: 20px auto 0;
  cursor: pointer;
}
.main1 {
  min-width: 1300px;
  max-width: 1920px;
  height: 400px;
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01cohHcS1M1KtPUhAy3_!!6000000001374-2-tps-1920-510.png");
  background-size: cover;
  text-align: center;
}
.main2 {
  text-align: center;
}
.main3 {
  text-align: center;
  margin-top: 75px;
  margin-right: 35px;
}
.main4 {
  text-align: center;
  margin-top: 75px;
}
.main5 {
  text-align: center;
  margin-top: 75px;
}
.main6 {
  text-align: center;
  margin-top: 75px;
  margin-right: 25px;
}
.main7 {
  text-align: center;
  margin-top: 75px;
  margin-right: 25px;
  padding-bottom: 100px;
}
section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
  background-color: #f7f7f7;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
</style>
