<template>
  <div>
    <section>
      <div class="main1">
        <img
          width="100%"
          src="https://img.alicdn.com/imgextra/i3/O1CN01vgAUx51GOnfb7RNNQ_!!6000000000613-0-tps-1920-5157.jpg"
          alt=""
        />
        <div class="activity">
          <img :src="imgArr[index]" />
        </div>
        <div class="first" @click="switchToimg(0)"></div>
        <div class="second" @click="switchToimg(1)"></div>
        <div class="third" @click="switchToimg(2)"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rightshow: true,
      phoneshow: false,
      a: "visible",
      imgArr: [
        "https://img.alicdn.com/imgextra/i2/O1CN0128Bp2D1Ie24XoLKPU_!!6000000000917-2-tps-1249-799.png",
        "https://img.alicdn.com/imgextra/i2/O1CN014IOvs51StHPp4rueE_!!6000000002304-2-tps-1249-799.png",
        "https://img.alicdn.com/imgextra/i3/O1CN01hCJmtR1RKdqZEt9k3_!!6000000002093-2-tps-1249-799.png",
      ],
      index: 0,
    };
  },
  methods: {
    switchToimg(number) {
      this.index = number;
    }
  }
};
</script>

<style scoped>
.activity {
  position: absolute;
  top: 76.82%;
  left: 17.4%;
  width: 65%;
  height: 15.5%;
}

.activity img {
  width: 100%;
}
.first {
  position: absolute;
  width: 20%;
  height: 0.7%;
  top: 77.3%;
  left: 19.0%;
  cursor: pointer;
}

.second {
  position: absolute;
  width: 20%;
  height: 0.7%;
  top: 77.3%;
  left: 39.7%;
  cursor: pointer;
}

.third {
  position: absolute;
  width: 20%;
  height: 0.7%;
  top: 77.3%;
  left: 60.4%;
  cursor: pointer;
}

.main1 {
  text-align: center;
  padding-top: 50px;
  position: relative;
}

section {
  width: 100%;
  min-width: 1280px;
  max-width: 1920px;
  height: 100%;

  margin: -50px auto 0;
  box-shadow: inset 0px 45px 10px -15px white;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}

</style>
