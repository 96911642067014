<template>
  <div class="sectiona">
    <nav>
      <ul>
        <span class="adv_active" style="padding-top:10px;padding-left:40px">
          <img
            src="https://img.alicdn.com/tfs/TB1_6Gqvj39YK4jSZPcXXXrUFXa-105-36.png"
            alt=""
          />
        </span>
        <li><a href="#topic1" title="首页" class="link1">大会首页</a></li>
        <li><a href="#topic2" title="大会议程" class="link2">精彩瞬间</a></li>
        <li><a href="#topic3" title="合作媒体" class="link3">演讲回顾</a></li>
        <li><a href="#topic4" title="联系我们" class="link4">峰会资讯</a></li>
        <li><a href="#topic5" title="联系我们" class="link5">年度颁奖</a></li>
        <li><a href="#topic6" title="联系我们" class="link6">峰会集锦</a></li>
        <a
          class="btn-review"
          href="https://www.tianxiawangshang.com/activity/wsfh/newispower"
          title
          target="blank"
          >大会页面</a
        >
      </ul>
    </nav>
    <section>
<!-- 页头 -->
    <div class="header" id="topic1">
      <img
        src="https://img.alicdn.com/imgextra/i2/O1CN01BKGsLm1k0Ss88vaQ9_!!6000000004621-0-tps-1914-549.jpg"
        style="width:100%"
      />
      <!-- <video poster="https://image.laifeng.com/image/C54C5EF54B50431C8B089275EA9B9BBE" style="width: 70%" controls>
		<source src="https://vku.youku.com/live/ilproom?spm=a2h0c.8166622.PhoneSokuUgc_1.dscreenshot&id=8067740" type="video/mp4">
		</video> -->
      <!-- <a style="font-size: 15px; color:white" href="https://vku.youku.com/live/ilproom?spm=a2h0c.8166622.PhoneSokuUgc_1.dscreenshot&id=8067740">直播链接</a> -->
    </div>
    <div id="wrapper">
      <!-- 前言介绍 -->
      <div class="page-group" style="padding-bottom: 30px;">
        <img
          src="https://img.alicdn.com/imgextra/i2/O1CN019ZtI7J1sGVfnURegA_!!6000000005739-0-tps-1178-615.jpg"
          style="width:60%"
        />
      </div>
    </div>
    <!-- 演讲嘉宾 -->
    <div class="huigu-group-container" id="topic2" style="text-align:center">
      <img
        src="https://img.alicdn.com/imgextra/i2/O1CN013AMw7x22oXFxOfTvy_!!6000000007167-0-tps-1172-140.jpg"
        style="width:60%"
      />
      <div class="swipe" id="swipe">
        <span id="prev"></span>
        <ul>
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01Pz7eyS1F3RMDjUblZ_!!6000000000431-0-tps-5472-3648.jpg"
          />
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01Hpdoju1ekpGogoUbS_!!6000000003910-0-tps-5472-3648.jpg"
          />
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01SeYIKv1X0qBr4Wh0K_!!6000000002862-0-tps-5472-3648.jpg"
          />
          <img
            src="https://img.alicdn.com/imgextra/i2/O1CN01Z3ASen1owPRSNiHwF_!!6000000005289-0-tps-5472-3648.jpg"
          />
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01QpaYhB1ZF9nvN7ecv_!!6000000003164-0-tps-5472-3648.jpg"
          />
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01O5qvib27JSSYatbVc_!!6000000007776-0-tps-5472-3648.jpg"
          />
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01747bom1GGYJJyyWJb_!!6000000000595-0-tps-5472-3648.jpg"
          />
          <img
            src="https://img.alicdn.com/imgextra/i2/O1CN01uZrbsW1WkoKbDGeHg_!!6000000002827-0-tps-5472-3648.jpg"
          />
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01xJWBzE1D9HAncjBk3_!!6000000000173-0-tps-5472-3648.jpg"
          />
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01zD08Gz1gTzwsscryn_!!6000000004144-0-tps-5472-3648.jpg"
          />
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01fN7w1a1g9qWTI0Vuq_!!6000000004100-0-tps-5472-3648.jpg"
          />
          <img
            src="https://img.alicdn.com/imgextra/i3/O1CN01npSzZ11ZtSesRWBKf_!!6000000003252-0-tps-5472-3648.jpg"
          />
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01qjivft1MiO4pkWH4a_!!6000000001468-0-tps-5472-3648.jpg"
          />
          <img
            src="https://img.alicdn.com/imgextra/i3/O1CN01Yl0OLh1d6EYFU5nsD_!!6000000003686-0-tps-5472-3648.jpg"
          />
        </ul>
        <span id="next"></span>
      </div>
    </div>
    <div class="jiabing-group-container" id="topic3" style="text-align:center">
      <img
        src="https://img.alicdn.com/imgextra/i1/O1CN01go4z1J1YgLhuQdaHG_!!6000000003088-0-tps-1177-223.jpg"
        style="width:60%"
      />
      <div class="guest-video-a">
        <ul>
          <video
            controls
            poster="https://img.alicdn.com/imgextra/i2/O1CN01n7rijn1PRqcnPsKGK_!!6000000001838-0-tps-566-369.jpg"
            preload="metadata"
          >
            <source
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E5%90%B4%E6%95%8F%E8%8A%9D.mp4"
              type="video/mp4"
            />
          </video>
          <p>吴敏芝<i>阿里巴巴集团首席市场官</i></p>
        </ul>
        <ul>
          <video
            controls
            poster="https://img.alicdn.com/imgextra/i3/O1CN01COHxCW1NC9pCDJp2V_!!6000000001533-0-tps-565-369.jpg"
            preload="metadata"
          >
            <source
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E5%BC%A0%E6%97%A0%E5%BF%8C.mp4"
              type="video/mp4"
            />
          </video>
          <p>董本洪（张无忌)<i>阿里巴巴首席市场官</i></p>
        </ul>
      </div>
      <div class="guest-video-b">
        <ul>
          <video
            controls
            poster="https://img.alicdn.com/imgextra/i4/O1CN01ZJvJa41bRdpwE846g_!!6000000003462-0-tps-381-369.jpg"
            preload="metadata"
          >
            <source
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E8%91%9B%E6%99%AF%E6%A0%8B.mp4"
              type="video/mp4"
            />
          </video>
          <p>葛景栋<i>微博高级副总裁</i></p>
        </ul>
        <ul>
          <video
            controls
            poster="https://img.alicdn.com/imgextra/i3/O1CN010sbfKm24ALjwpcYoQ_!!6000000007350-0-tps-381-369.jpg"
            preload="metadata"
          >
            <source
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E6%BC%AB%E5%A4%A9.mp4"
              type="video/mp4"
            />
          </video>
          <p>漫天<i>淘宝内容电商事业部资深总监</i></p>
        </ul>
        <ul>
          <video
            controls
            poster="https://img.alicdn.com/imgextra/i1/O1CN01j02il11uiZAiwZI6B_!!6000000006071-0-tps-379-369.jpg"
            preload="metadata"
          >
            <source
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E5%91%A8%E6%A0%91%E9%A2%96.mp4"
              type="video/mp4"
            />
          </video>
          <p>周树颖<i>泡泡玛特首席消费者运营官</i></p>
        </ul>
      </div>
      <img
        src="https://img.alicdn.com/imgextra/i4/O1CN01YcXJUA1z6cR3KjIVg_!!6000000006665-0-tps-1177-141.jpg"
        style="width:60%"
      />
      <div class="guest-video-d">
        <ul>
          <video
            controls
            poster="https://img.alicdn.com/imgextra/i1/O1CN01dbeMUv1sCO74FIZVE_!!6000000005730-0-tps-381-369.jpg"
            preload="metadata"
          >
            <source
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E5%BA%A6%E5%B2%B3.mp4"
              type="video/mp4"
            />
          </video>
          <p>度岳<i>天猫快消全链路营销&商家运营中心总经理</i></p>
        </ul>
        <ul>
          <video
            controls
            poster="https://img.alicdn.com/imgextra/i2/O1CN01orw7CX1mfnr8vGGrz_!!6000000004982-0-tps-381-369.jpg"
            preload="metadata"
          >
            <source
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E8%93%9D%E9%A3%9E.mp4"
              type="video/mp4"
            />
          </video>
          <p>蓝飞<i>溪木源联合创始人</i></p>
        </ul>
        <ul>
          <video
            controls
            poster="https://img.alicdn.com/imgextra/i2/O1CN01lRcluQ1q2C43lNudi_!!6000000005437-0-tps-380-369.jpg"
            preload="metadata"
          >
            <source
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E5%AD%99%E4%BB%95%E5%86%9B.mp4"
              type="video/mp4"
            />
          </video>
          <p>孙仕军<i>认养一头牛联合创始人、CEO</i></p>
        </ul>
      </div>
      <img
        src="https://img.alicdn.com/imgextra/i2/O1CN01EmyHZ21HVUr6U4nLY_!!6000000000763-0-tps-1177-139.jpg"
        style="width:60%"
      />
      <div class="guest-video-c">
        <ul>
          <video
            controls
            poster="https://img.alicdn.com/imgextra/i4/O1CN01K9Xeoj1mOOo4Q8x1e_!!6000000004944-0-tps-566-369.jpg"
            preload="metadata"
          >
            <source
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E5%90%BE%E6%82%BE.mp4"
              type="video/mp4"
            />
          </video>
          <p>吾悾<i>天猫家装营销总经理</i></p>
        </ul>
        <ul>
          <video
            controls
            poster="https://img.alicdn.com/imgextra/i1/O1CN01JxTbUS1eWczkfBM9J_!!6000000003879-0-tps-568-369.jpg"
            preload="metadata"
          >
            <source
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E5%BE%A1%E5%AE%89.mp4"
              type="video/mp4"
            />
          </video>
          <p>御安<i>阿里妈妈高级用户研究专家</i></p>
        </ul>
      </div>
      <div class="guest-video-c">
        <ul>
          <video
            controls
            poster="https://img.alicdn.com/imgextra/i2/O1CN01LvV5cs1ddD4VGEXeK_!!6000000003758-0-tps-566-367.jpg"
            preload="metadata"
          >
            <source
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E6%9D%A8%E5%88%9A.mp4"
              type="video/mp4"
            />
          </video>
          <p>杨刚<i>喜临门家具股份有限公司总裁</i></p>
        </ul>
        <ul>
          <video
            controls
            poster="https://img.alicdn.com/imgextra/i1/O1CN01EOLWQF1r2wKIjQ7S5_!!6000000005574-0-tps-567-367.jpg"
            preload="metadata"
          >
            <source
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E9%92%B1%E6%99%94.mp4"
              type="video/mp4"
            />
          </video>
          <p>钱晔<i>索菲亚营销中心副总经理兼电商总经理</i></p>
        </ul>
      </div>
    </div>
    <!-- 峰会咨询 -->
    <div class="huigu-group-container" id="topic4">
      <img
        src="https://img.alicdn.com/imgextra/i1/O1CN01wDHNYN1iv8ePR0qWe_!!6000000004474-0-tps-1172-138.jpg"
        style="width:60%"
      />
      <div class="Zinfor">
        <a
          href="https://mp.weixin.qq.com/s/iYDHPM0VEM5hB20mpl7Rpw"
          target="_blank"
          >阿里沉淀20年秘籍公开！CCO吴敏芝：未来商业竞争力靠它</a
        >
      </div>
      <div class="Zinfor">
        <a
          href="https://mp.weixin.qq.com/s/ArpiSshuA1aR-ge7y2iZEQ"
          target="_blank"
          >抓住新商业机会！这一年，他们凭什么逆势增长？</a
        >
      </div>
      <div class="Zinfor">
        <a
          href="https://mp.weixin.qq.com/s/Fnd5s_ICGPI1xbOwaQ0iOw"
          target="_blank"
          >从断崖式下跌到支线飙升，今年最大行业黑马的增长密码是什么？</a
        >
      </div>
      <div class="Zinfor">
        <a
          href="https://mp.weixin.qq.com/s/PYv71fYB_wUwouklZmTKYA"
          target="_blank"
          >他们，是2020年新商业的造风者！</a
        >
      </div>
      <div class="Zinfor">
        <a
          href="https://mp.weixin.qq.com/s/MNRPmvT5eTDnoq_BgMuSTQ"
          target="_blank"
          >丁真们为什么会爆火？微博高管解密电商内容逻辑</a
        >
      </div>
      <div class="Zinfor">
        <a
          href="https://mp.weixin.qq.com/s/OMfqJeHma4ot2Apva-6LLA"
          target="_blank"
          >商家的新机会来了！直播电商酱油四个关键变化</a
        >
      </div>
      <div class="Zinfor">
        <a
          href="https://mp.weixin.qq.com/s/jZqZ5UA4r2pr_hVq9mAK5g"
          target="_blank"
          >商业新浪潮来了！品牌们在这里找到了“增长黑客“</a
        >
      </div>
    </div>
    <!-- 精彩回顾 -->
    <div class="huigu-group-container" id="topic5">
      <img
        src="https://img.alicdn.com/imgextra/i1/O1CN01yg4cE71NEuCM5NyU8_!!6000000001539-0-tps-1172-138.jpg"
        style="width:60%"
      />
      <img
        src="https://img.alicdn.com/imgextra/i1/O1CN01nPbf5N1wJx789qe7F_!!6000000006288-0-tps-1172-2255.jpg"
        style="width:60%"
      />
    </div>
    <!-- 主办方 -->
    <div class="huigu-group-container" id="topic6">
      <img
        src="https://img.alicdn.com/imgextra/i4/O1CN013FxZy021BJj0oVppA_!!6000000006946-0-tps-1172-138.jpg"
        style="width:60%"
      />
      <img
        src="https://img.alicdn.com/imgextra/i4/O1CN01TT53SO1cEeDAwpO5z_!!6000000003569-0-tps-1173-2070.jpg"
        style="width:60%"
      />
    </div>
    <div class="huigu-group-container">
      <img
        src="https://img.alicdn.com/imgextra/i1/O1CN01o3kDNF1q2eSYEDL56_!!6000000005438-0-tps-1173-138.jpg"
        style="width:60%"
      />
      <img
        src="https://img.alicdn.com/imgextra/i1/O1CN01lOsHXL1QOviL2OxqR_!!6000000001967-0-tps-1173-1540.jpg"
        style="width:60%"
      />
    </div>
    <!-- 联系我们 -->
    <div class="contact-group"></div>
    </section>
    
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    move1() {
      let returnEle = null;
      returnEle = document.querySelector("#home");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move2() {
      let returnEle = null;
      returnEle = document.querySelector("#person");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move3() {
      let returnEle = null;
      returnEle = document.querySelector("#agenda");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move4() {
      let returnEle = null;
      returnEle = document.querySelector("#Sale");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move5() {
      let returnEle = null;
      returnEle = document.querySelector("#media");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move6() {
      let returnEle = null;
      returnEle = document.querySelector("#contact");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
  },
};
</script>

<style scoped>
section {
  width: 100%;
  min-width: 1300px;
  height: 100%;
  background-color: black;
}
.hezuo-group .h-main .h-m-list li {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

nav,
nav ul,
.model-yicheng .model-yc-cont .yc-cont-area,
.model-yicheng .model-yc-cont .yc-cont-area-list ul,
.model-guest .guest-main .guest-list,
.model-contact,
.hezuo-group .h-main .h-m-list {
  *zoom: 1;
}
nav:before,
nav ul:before,
.model-yicheng .model-yc-cont .yc-cont-area:before,
.model-yicheng .model-yc-cont .yc-cont-area-list ul:before,
.model-guest .guest-main .guest-list:before,
.model-contact:before,
.hezuo-group .h-main .h-m-list:before,
nav:after,
nav ul:after,
.model-yicheng .model-yc-cont .yc-cont-area:after,
.model-yicheng .model-yc-cont .yc-cont-area-list ul:after,
.model-guest .guest-main .guest-list:after,
.model-contact:after,
.hezuo-group .h-main .h-m-list:after {
  content: "";
  display: table;
}
nav:after,
nav ul:after,
.model-yicheng .model-yc-cont .yc-cont-area:after,
.model-yicheng .model-yc-cont .yc-cont-area-list ul:after,
.model-guest .guest-main .guest-list:after,
.model-contact:after,
.hezuo-group .h-main .h-m-list:after {
  clear: both;
}

.page-group {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 * 0. sassCore's style
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  overflow-y: scroll;
  /* 0 */
  -webkit-overflow-scrolling: touch;
  /* 0 */
}

/**
 * 1. Remove default margin
 * 0. sassCore's style.
 */
body {
  margin: 0;
  /* 1 */
  font-size: 14px;
  /* 0 */
  line-height: 1.5;
  /* 0 */
  color: #333;
  /* 0 */
  background-color: #fff;
  /* 0 */
}

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11 and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 * 3. Correct `inline-block` display in IE 6/7.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
  *display: inline;
  /* 3 */
  *zoom: 1;
  /* 3 */
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none;
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background color from active links in IE 10.
 * 2. Improve readability when focused and also mouse hovered in all browsers.
 * 0. sassCore's style.
 */
a {
  background: transparent;
  /* 1 */
  /* 0 */
  text-decoration: none;
}
a:active,
a:hover {
  outline: 0;
  /* 2 */
}
a:hover {
  color: #006699;
}

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic;
}

/**
 * Address styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improve image quality when scaled in IE 7.
 * 0. sassCore's style.
 */
img {
  border: 0;
  /* 1 */
  vertical-align: middle;
  /* 0 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ========================================================================== */
/**
 * Address differences between Firefox and other browsers.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

/**
 * 1. Contain overflow in all browsers.
 * 2. Improve readability of pre-formatted text in all browsers.
 */
pre {
  overflow: auto;
  /* 1 */
  white-space: pre;
  /* 2 */
  white-space: pre-wrap;
  /* 2 */
  word-wrap: break-word;
  /* 2 */
}

/**
 * 1. Address odd `em`-unit font size rendering in all browsers.
 * 2. Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  _font-family: "courier new", monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
  *margin-left: -7px;
  /* 3 */
}

/**
 * 1. Remove default vertical scrollbar in IE 8/9/10/11.
 * 0. sassCore's style
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 0 */
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

/**
 * Address CSS quotes not supported in IE 6/7.
 */
q {
  quotes: none;
}

html,
button,
input,
select,
textarea {
  font-family: "Helvetica Neue", Helvetica, Tahoma, sans-serif;
}

h1,
hx,
h2,
h3,
h4,
h5,
h6,
p,
figure,
form,
blockquote {
  margin: 0;
}

ul,
ol,
li,
dl,
dd {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none outside none;
}

hx,
h2,
h3 {
  line-height: 2;
  font-weight: normal;
}

h1 {
  font-size: 21px;
}

hx,
h2 {
  font-size: 18.2px;
}

h3 {
  font-size: 16.38px;
}

h4 {
  font-size: 14px;
}

h5,
h6 {
  font-size: 11.9px;
  text-transform: uppercase;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #ccc;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #ccc;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #ccc;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #ccc;
}

/*
2020网商峰会
========================*/
@font-face {
  font-family: "webfont";
  src: url("//at.alicdn.com/t/webfont_we3ne9crqwa.eot");
  /* IE9*/
  src: url("//at.alicdn.com/t/webfont_we3ne9crqwa.eot?#iefix")
      format("embedded-opentype"),
    url("//at.alicdn.com/t/webfont_we3ne9crqwa.woff") format("woff"),
    url("//at.alicdn.com/t/webfont_we3ne9crqwa.ttf") format("truetype"),
    url("//at.alicdn.com/t/webfont_we3ne9crqwa.svg#NotoSansHans-Black")
      format("svg");
  /* iOS 4.1- */
}
.web-font {
  font-family: "webfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.sectiona {
  background-color: #000000;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}
/* 悬浮窗 */
nav {
  background-image: url(https://img.alicdn.com/imgextra/i1/O1CN01FJ5Sa11OEjfKVSvf7_!!6000000001674-0-tps-1914-61.jpg);
  height: 50px;
  position: fixed;
  width: 100%;
  z-index: 100;
}
nav .btn-buy {
  display: inline-table;
  float: right;
  width: 160px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: 400;
  color: #fff;
  font-size: 22px;
  margin-top: 15px;
  margin-right: 50px;
  background: -webkit-linear-gradient(right, #b940c1, #505ff1);
  background: -o-linear-gradient(right, #b940c1, #505ff1);
  background: -moz-linear-gradient(right, #b940c1, #505ff1);
  background: -mos-linear-gradient(right, #b940c1, #505ff1);
  background: linear-gradient(right, #b940c1, #505ff1);
}
nav ul {
  /* 导航 */
  float: center;
  padding-top: 5px;
}
nav .btn-review {
  float: right;
  width: 160px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-weight: 400;
  color: #fff;
  font-size: 18px;
  margin-top: 6px;
  margin-right: 80px;
  background: -webkit-linear-gradient(right, #e76363, #7142dd);
  /* background: -webkit-linear-gradient(right, #e76363, #7142dd);
  background: -o-linear-gradient(right, #e76363, #7142dd);
  background: -moz-linear-gradient(right, #e76363, #7142dd);
  background: -mos-linear-gradient(right, #e76363, #7142dd);
  background: linear-gradient(right, #e76363, #7142dd); */
}
nav ul span {
  padding-left: 20px;
  padding-right: 50px;
}
nav ul li {
  font-size: 14px;
  display: inline-block;
  margin-left: 35px;
}
nav ul li:first-child {
  font-size: 0;
}
nav ul li a {
  text-align: center;
  line-height: 40px;
  width: 70px;
  color: rgb(0, 0, 0);
  display: inline-block;
}
nav ul li a:hover {
  height: 40px;
  background-color: #3430f5;
  color: #fff;
}
nav ul li a.current {
  background-color: #3430f5;
}

.header {
  padding-top: 0%;
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 30px;
}
.header img {
  left: 0;
  top: 0;
  width: 100%;
}
.header video {
  width: 60%;
  position: top;
}
.video-container {
  margin: 0 auto;
  width: 60%;
}
.video-container video {
  padding: 8px;
  background-color: #fb0024;
  width: 100%;
}
[class^="-group"],
[class*="-group"] {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
}

.yicheng-group-container span {
  width: 30px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

#prev {
  display: inline-table;
  background-image: url(https://img.alicdn.com/imgextra/i2/O1CN01Kbnoo81ZlDX714YlY_!!6000000003234-0-tps-46-194.jpg);
  margin: 5px;
  cursor: pointer;
  font-weight: bold;
  width: 30px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
}

#next {
  display: inline-table;
  background-image: url(https://img.alicdn.com/imgextra/i4/O1CN01KrytMv1LVH7tPIa32_!!6000000001304-0-tps-46-194.jpg);
  margin: 5px;
  cursor: pointer;
  font-weight: bold;
  width: 30px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
}
.swipe {
  position: relative;
}

.swipe ul {
  width: 65%;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  list-style: none;
  padding: 0;
}
.swipe img {
  width: 35%;
  display: inline-block;
}
/*活动报道模块*/

/* .LeftBotton {
  height: 360px;
  width: 28px;
  background: url(../images/activity/arrow.jpg) no-repeat 0px 0;
  overflow: hidden;
  float: left;
  display: inline;
  margin: 1px 0 0 0;
  cursor: pointer;
}
.LeftBotton:hover {
  background: url(../images/activity/arrow.jpg) no-repeat -58px 0;
}
.RightBotton {
  height: 360px;
  width: 28px;
  background: url(../images/activity/arrow.jpg) no-repeat -174px 0;
  overflow: hidden;
  float: left;
  display: inline;
  margin: 1px 0 0 0;
  cursor: pointer;
}
.RightBotton:hover {
  background: url(../images/activity/arrow.jpg) no-repeat -116px 0;
} */

/* @font-face {
  font-family: myFirstFont;
  src: url(MF_jiahei.ttf);
} */
.jiabing-group-container img {
  margin-top: 30px;
  margin-bottom: 20px;
}
.guest-video-a {
  margin: 0 auto;
  text-align: center;
}
.guest-video-a ul {
  width: 30%;
  height: 30%;
  display: inline-table;
  padding: 1px;
  padding-top: 5px;
  background-color: #53fb74;
}
.guest-video-a ul video {
  padding: 2px;
  width: 98%;
}
.guest-video-a ul p {
  display: block;
  text-align: center;
  font-size: 25px;
  color: black;
}
.guest-video-a ul p i {
  display: block;
  font-size: 8px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: normal;
  color: black;
}

.guest-video-c {
  margin: 0 auto;
  text-align: center;
  margin-top: 5px;
}
.guest-video-c ul {
  width: 30%;
  height: 30%;
  display: inline-table;
  padding: 1px;
  padding-top: 5px;
  background-color: #ffa04f;
}
.guest-video-c ul video {
  padding: 2px;
  width: 98%;
}
.guest-video-c ul p {
  display: block;
  text-align: center;
  font-size: 25px;
  color: black;
}
.guest-video-c ul p i {
  display: block;
  font-size: 8px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: normal;
  color: black;
}
.guest-video-b {
  padding-top: 6px;
  margin: 0 auto;
  text-align: center;
}
.guest-video-b ul {
  width: 20%;
  display: inline-table;
  padding: 0px;
  padding-top: 5px;
  background-color: #53fb74;
}
.guest-video-b ul video {
  padding: 2px;
  width: 98%;
}
.guest-video-b ul p {
  display: block;
  text-align: center;
  font-size: 25px;
  color: black;
}
.guest-video-b ul p i {
  display: block;
  font-size: 8px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: normal;
  color: black;
}
.guest-video-d {
  padding-top: 6px;
  margin: 0 auto;
  text-align: center;
}
.guest-video-d ul {
  width: 20%;
  padding-top: 5px;
  display: inline-table;
  padding: 3px;
  background-color: #ffa04f;
}
.guest-video-d ul video {
  padding: 2px;
  width: 98%;
}
.guest-video-d ul p {
  display: block;
  text-align: center;
  font-size: 25px;
  color: black;
}
.guest-video-d ul p i {
  display: block;
  font-size: 8px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: normal;
  color: black;
}
.information-group {
  padding-top: 0px;
}
.page-group {
  text-align: center;
  font-size: 15px;
  color: #c5c5c5;
}
.page-group p {
  margin-bottom: 20px;
  transition: 1s;
}
.page-group p:hover {
  color: #fff;
}
.page-group p:last-child {
  margin-bottom: 0;
}

/* .guest-group-container {
  background-image: url(Picture/images/guestback.jpg);
  background-repeat: no-repeat;
  background-size: auto;
}
.pingxuan-group-container {
  background-image: url(Picture/images/prizeback_03.jpg);
  background-repeat: no-repeat;
  background-size: contain;
} */
/* .pingxuan-group .pingxuan-btn {
    width: 342px;
    height: 60px;
    color: #fff;
    font-size: 22px;
    line-height: 60px;
    border-radius: 30px;
    display: inline-block;
    background: url(https://img.alicdn.com/tfs/TB1Ea92jSzqK1RjSZFLXXcn2XXa-342-60.png); } */

/* .docomment-group .sign-up-button {
  display: block;
  width: 14%;
  height: 110px;
  opacity: 1 !important;
  background-image: url(Picture/images/anniu_03.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: fixed;
  z-index: 39;
  right: 2%;
  bottom: 6%;
  border: 0;
}
.docomment-group a:hover {
  background-image: url(Picture/images/redanniu_03.png);
  animation: inherit;
  -webkit-animation: inherit;
  -ms-animation: inherit;
  -moz-animation: inherit;
  -o-animation: inherit;
} */

[class^="model-"],
[class*="model-"] {
  background-position: top center;
  background-size: contain;
}

/* .model-yicheng {
  background-image: url(https://img.alicdn.com/tfs/TB1zMi3jSzqK1RjSZFpXXakSXXa-1920-1.jpg); }
  .model-yicheng .model-yc-top {
    background-image: url(https://img.alicdn.com/tfs/TB1mNy3jMTqK1RjSZPhXXXfOFXa-1920-73.jpg);
    background-repeat: no-repeat;
    height: 73px; }
  .model-yicheng .model-yc-bottom {
    background-image: url(https://img.alicdn.com/tfs/TB1oTG5jOrpK1RjSZFhXXXSdXXa-1920-135.jpg);
    height: 135px;
    background-position: bottom center;
    background-repeat: no-repeat; }
  .model-yicheng .model-yc-cont {
    width: 62.5%;
    margin: 0 auto;
    font-size: 0; } */

.model-title {
  width: 40%;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 40px;
}
/* .model-title.tit-1 {
  background-image: url("Pic/xianchang.jpg");
}
.model-title.tit-2 {
  background-image: url("Pic/yanjiang.jpg");
}
.model-title.tit-3 {
  height: 38px;
  background-image: url("Pic/lianxi.jpg");
}
.model-title.tit-4 {
  background-image: url("Pic/zixun.jpg");
}
.model-title.tit-5 {
  background-image: url("Pic/huigu.jpg");
} */

/* 峰会资讯 */
.zixun-group-container {
  text-align: center;
  margin-top: 50px;
}
.huigu-group-container {
  text-align: center;
  margin-top: 50px;
}
.Zinfor {
  margin: 0 auto;
  width: 60%;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  color: #53fa74;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-color: white;
  padding-bottom: 10px;
  padding-top: 10px;
}
.Zinfor a {
  font-size: 18px;
  font-weight: bold;
  color: #53fa74;
}
.Zinfor p {
  font-size: 14px;
  color: #ffffff;
}
.review-a {
  text-align: center;
}
.review-a img {
  margin: 3px;
  width: 15%;
}
.review-b {
  text-align: center;
}
.review-b img {
  padding: 6px 3px 6px 3px;
  width: 15%;
  background-color: #fb0024;
}
.review-c {
  text-align: center;
}
.review-c :first-child {
  display: inline-table;
  margin: 3px;
  width: 47%;
}
.review-c :last-child {
  display: inline-table;
  margin: 3px;
  width: 15%;
}
.review-d {
  text-align: center;
}
.review-d video {
  width: 15%;
  margin: 3px;
}
.model-contact {
  width: 62.5%;
  margin: 0 auto;
}
.model-contact .contact {
  float: left;
  width: 50%;
  margin-top: 20px;
}
.model-contact .contact li {
  color: #ffffff;
  font-size: 11px;
  font-weight: 100;
  line-height: 1.6;
  list-style: inside;
}
.model-contact .contact li.title {
  font-size: 18px;
  list-style: none;
  padding-bottom: 10px;
}
.model-contact .contact li span {
  color: #fff;
}

.hezuo-group .h-main {
  width: 62.5%;
  margin: 0 auto;
  padding-top: 23px;
}
.hezuo-group .h-main.first-child {
  padding-top: 0;
}
.hezuo-group .h-main h2 {
  padding-bottom: 20px;
  color: #00faff;
  font-size: 20px;
  text-align: center;
}
.hezuo-group .h-main .h-m-list {
  margin-top: -4px;
  text-align: center;
  font-size: 0;
}
.hezuo-group .h-main .h-m-list li {
  margin: 0 2px;
  margin-top: 4px;
  font-size: 0;
}
.hezuo-group .h-main .h-m-list li img {
  height: 38px;
  margin-left: 4px;
  margin-bottom: 4px;
}
/* .hezuo-group {
  background-image: url(guidang/hezuoback.jpg);
  background-position: center;
}
.contact-group {
  background-image: url(../propa/bottom.jpg);
} */

nav ul li.current {
  background: #3430f5;
}

.model-yicheng .model-yc-cont .yc-cont-area-list.border-line {
  min-height: 82vh;
}
.video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -9999;
  /*灰色调*/
  /*-webkit-filter:grayscale(100%)*/
}
</style>
