import request from "@/utils/request";

// 新闻列表数量
export function getNewsCount() {
  return request({
    url: "/api/product/content/newsCount",
    method: "get",
  });
}

// 新闻列表
export function getNews(params) {
  return request({
    url: "/api/product/content/newsList",
    method: "get",
    params,
  });
}
