<template>
  <div>
    <div>
      <div class="home">
        <home-header class="fixed-top" @showSearch="showSearch"></home-header>
        <div class="section">
          <div class="container">
            <home-leftaside
              class="fixed-aside"
              @showSearch="showSearch"
            ></home-leftaside>
            <div class="left-aside"></div>
            <home-section class="page"></home-section>
            <home-rightaside class="right-aside"></home-rightaside>
          </div>
        </div>
        <home-footer class="footer"></home-footer>
      </div>
    </div>
    <div>
      <home-search v-if="searchShow" @exitSearch="exitSearch"></home-search>
    </div>
  </div>
</template>

<script>
import HomeHeader from "../common/Header.vue";
import HomeLeftaside from "../common/Leftaside.vue";
import HomeSection from "./components/Section.vue";
import HomeRightaside from "../common/Rightaside.vue";
import HomeFooter from "../common/Footer.vue";
import HomeSearch from "../activity/components/Search.vue";

export default {
  components: {
    HomeHeader,
    HomeLeftaside,
    HomeSection,
    HomeRightaside,
    HomeFooter,
    HomeSearch,
  },
  data() {
    return {
      searchShow: false,
    };
  },
  methods: {
    showSearch() {
      this.searchShow = true;
    },
    exitSearch() {
      this.searchShow = false;
    },
  },
};
</script>

<style scoped>
@import "./../../assets/css/common.css";
.left-aside {
  float: left;
  width: 91px;
}
.page {
  float: left;
  width: 729px;
  margin: 0 30px;
}
.right-aside {
  width: 310px;
  float: left;
}
</style>
