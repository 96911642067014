import request from "@/utils/request";

// 达人合作
export function commitPeople(params) {
  return request({
    url: "/api/mcn/insertMcnExpert",
    method: "post",
    params,
  });
}

// 商家合作
export function commitShop(params) {
  return request({
    url: "/api/mcn/insertMcnBusiness",
    method: "post",
    params,
  });
}
