<template>
  <div>
    <div class="header">
      <div class="nav">
        <div v-if="this.meunshow" class="title" style="margin:10px 150px 0 0">
          <router-link to="/"
            ><img
              width="70%"
              src="https://img.alicdn.com/imgextra/i1/O1CN01H9NM2F1iGNOTHydWo_!!6000000004385-2-tps-105-36.png"
              alt=""
          /></router-link>
        </div>
        <div class="titlesum">
          <div class="title">
            <div class="titlea">营销</div>
            <div class="titlebsum">
              <div class="titleb" >品牌营销</div>
              <div class="titleb" >平台合作</div>
              <div class="titleb" >三花MCN</div>
            </div>
          </div>
          <div class="title">
            <div class="titlea">中小商家</div>
            <div class="titlebsum">
              <div class="titleb" @click="goHref4">商家培训</div>
              <div class="titleb" @click="goHref5">在线教育</div>
              <div class="titleb" @click="goHref10">智慧营销</div>
              <div class="titleb" @click="goHref2">天字网络</div>
            </div>
          </div>
          <div class="title">
            <div class="titlea">内容生态</div>
            <div class="titlebsum">
              <div class="titleb" @click="goHref6">产品服务</div>
              <div class="titleb" @click="goHref11">对话创始人</div>
            </div>
          </div>
          <div class="title">
            <div
              :class="{ titlea: true, activity: this.meunshow === true }"
              @click="goactivity"
            >
              活动
            </div>
          </div>
          <div class="title">
            <div class="titlea" @click="goHref9">关于我们</div>
          </div>
          <div class="title">
            <div class="search">
              <el-button type="text" @click="openSearch">
                <span
                  ><svg class="icon" aria-hidden="true">
                    <use xlink:href="#iconsearch1"></use>
                  </svg>搜索</span
                ></el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHomeList } from "@/api/home";

export default {
  data() {
    return {
      meunshow: false,
      message: "",
      isScroll: true,
      searchList: [],
      dialogVisible: false,
    };
  },
  methods: {
    goactivity() {
      this.$router.push({
        path: "/activity",
      });
    },
    goHref() {
      window.open("http://topic.tianxiawangshang.com/ddm");
    },
    goHref1() {
      window.open("http://topic.tianxiawangshang.com/argon");
    },
    goHref2() {
      window.open("http://tianziyingxiao.com/zebra");
    },
    goHref3() {
      window.open("http://topic.tianxiawangshang.com/yomo");
    },
    goHref4() {
      window.open("https://www.tianxiawangshang.com/market/products/sjpxs");
    },
    goHref5() {
      window.open("/market/products/zxjy");
    },
    goHref6() {
      window.open("/market/products/nrst");
    },
    goHref7() {
      window.open("http://topic.tianxiawangshang.com/internaltraining");
    },
    goHref8() {
      window.open("https://tbd.wshang.com/navi/homePage");
    },
    goHref9() {
      window.open("https://tianxiawangshang.com/market/products/aboutus");
    },
    goHref10() {
      window.open("/market/products/zhyxs");
    },
    goHref11() {
      window.open("/market/products/talk");
    },
    openSearch() {
      this.$emit("showSearch", "true");
    },
    searchArticle() {
      this.$router.push({
        path: "/searchlist/" + this.message,
        query: {
          key: +new Date(),
        },
      });
    },

    handleClose(done) {
      done();
    },
    getTag() {
      let params = {};
      params.page = 1;
      params.pageSize = 10;
      getHomeList(params).then((res) => {
        var hotList = res.data.data.result;

        for (var i = 0; i < hotList.length; i++) {
          if (hotList[i].tagList) {
            for (var j = 0; j < hotList[i].tagList.length; j++) {
              var hotData = hotList[i].tagList[j];
              this.searchList = this.searchList.concat(hotData);
            }
          }
        }
      });
    },
  },
  mounted() {
    this.getTag();
    var url = window.location.pathname;
    if (url.includes("activity") === true) {
      this.meunshow = true;
    }
  },
};
</script>

<style scoped>
.search {
  margin-left: 150px;
}
.search .icon {
  width: 20px;
  height: 20px;
  vertical-align: -4px;
  fill: currentColor;
  overflow: hidden;
  font-size: 20px;
}
.search span {
  color: #fff;
  font-weight: 400;
  font-size: 17px;
  display: block;
}
.titlea {
  line-height: 45px;
  color: rgb(255, 255, 255);
}
/* .titlea:hover {
  background-color: #fff;
  color: rgb(215, 12, 24);
} */
.activity {
  background-color: #fff;
  color: rgb(215, 12, 24);
}
.titleb {
  height: 20px;
  padding: 10px 20px;
  background-color: white;
  font-size: 14px;
  border-bottom: 1px solid rgba(212, 210, 210, 0.5);
}
.titleb:hover {
  background-color: rgb(233, 233, 233);
  color: rgb(215, 12, 24);
}
.titlebsum {
  overflow: hidden;
  display: none;
  animation-name: frames;
  animation-duration: 0.7s;
}
.title:hover .titlebsum {
  display: block;
}
@keyframes frames {
  from {
    height: 0px;
  }
  to {
    height: 200px;
  }
}
.title {
  text-align: center;
  cursor: pointer;
  width: 120px;
}
.titlesum {
  display: flex;
}
.header {
  background-color: rgb(215, 12, 24);
  height: 45px;
  min-width: 1190px;
  background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01U1AVXC1nsunj0pQD6_!!6000000005146-2-tps-3840-160.png");
  background-size: 100% 100%;
}
.nav {
  display: flex;
  justify-content: center;
}
/* .nav {
  width: 1190px;
  height: 50px;
  margin: 0 auto;
  display: flex;
  overflow: hidden;
}

.ico {
  width: 610px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
}
.title {
  display: flex;
  align-items: center;
  width: 700px;
  margin-left: 400px;
}
.title a {
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 2px;
  width: 120px;
  height: 25px;
}
.title span {
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-size: 17px;
  width: 100px;
  height: 25px;
  letter-spacing: 2px;
  cursor: pointer;
}
.el-dropdown {
  width: 120px;
  text-align: center;
}
.activity {
  text-align: center;
  font-weight: 400;
  font-size: 17px;
  width: 120px;
  height: 24px;
  letter-spacing: 2px;
  cursor: pointer;
}

.dropdown {
  color: #3e4047;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

.search {
  padding-top: 0px;
  width: 100px;
}
.search .icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  font-size: 20px;
}
.search span {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  display: block;
  padding: 10px;
}

.hot {
  margin-bottom: 15px;
}
.hot span a {
  color: #bbbbbb;
  font-size: 14px;
  padding: 0 10px;
  background-color: rgb(245, 246, 250);
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  text-decoration: none;
}
.hot span a:hover {
  background-color: rgb(215, 12, 24);
  color: #fff;
} */
</style>
