<template>
  <div class="bcgcolor">
    <div class="rightfix">
      <div class="rightdiv" @click="goshop" :style="{ visibility: a }">
        <img
          src="https://img.alicdn.com/imgextra/i2/O1CN01Cmwxqw26L0II6YIdu_!!6000000007644-2-tps-34-29.png"
          alt=""
        />
        <div>免费店铺诊断</div>
      </div>
      <div
        class="rightdiv"
        style="margin-top:10px"
        @click="phoneshow = !phoneshow"
        :style="{ visibility: a }"
      >
        <img
          src="https://img.alicdn.com/imgextra/i4/O1CN01D8rO321mHWydq23JV_!!6000000004929-2-tps-34-38.png"
          alt=""
        />
        <div>咨询课程</div>
      </div>
      <div class="phone" v-show="phoneshow && a === 'visible'">15158158903</div>
      <div @click="changeStyle" class="rightdiv1" style="margin-top:10px">
        <img
          v-show="a === 'visible'"
          src="https://img.alicdn.com/imgextra/i1/O1CN01crw2Fp1vsTT387wqv_!!6000000006228-2-tps-32-33.png"
          alt=""
        />
        <img
          v-show="a === 'hidden'"
          style="transform: rotate(180deg);"
          src="https://img.alicdn.com/imgextra/i1/O1CN01crw2Fp1vsTT387wqv_!!6000000006228-2-tps-32-33.png"
          alt=""
        />
      </div>
    </div>

    <section>
      <div class="main1">
        <img
          width="100%"
          src="https://img.alicdn.com/imgextra/i1/O1CN01O9lLNf1KiGrHluiR4_!!6000000001197-2-tps-1920-4025.png"
          alt=""
        />
      </div>
      <!-- <div class="main1">
        <img
          width="50%"
          src="https://img.alicdn.com/imgextra/i2/O1CN01OivWoP1W2qQ3FIQM3_!!6000000002731-2-tps-980-595.png"
          alt=""
        />
      </div>
      <div class="main1">
        <img
          width="50%"
          src="https://img.alicdn.com/imgextra/i2/O1CN01ClVBlg1z6cXi1ppNR_!!6000000006665-2-tps-1164-2281.png"
          alt=""
        />
      </div>
      <div class="main1">
        <img
          width="50%"
          src="https://img.alicdn.com/imgextra/i1/O1CN012i0vKe1hDnf3sUb6d_!!6000000004244-2-tps-999-9248.png"
          alt=""
        />
      </div>
      <div class="main1">
        <img
          width="50%"
          src="https://img.alicdn.com/imgextra/i3/O1CN01XMquzl21PyVea6ClY_!!6000000006978-2-tps-992-5991.png"
          alt=""
        />
      </div>
      <div class="btmcontact">
        <div>杭州天下网商教育科技有限公司 | </div>
        <div style="margin-left:29px">
          未经许可不得转载 | 浙ICP备2022019277号
        </div>
        <div style="margin-left:29px;display:flex">
          <a
            target="_blank"
            style="display:flex;align-items:center"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002016529"
            ><img
              width="100%"
              style="width:25px;height:25px"
              src="https://img.alicdn.com/imgextra/i4/O1CN01e3m4Ai1TKl44rzRRx_!!6000000002364-2-tps-20-20.png"
            />
            <p style="color: rgb(248, 237, 237);margin-left:5px">
              浙公网安备 33011002016529号
            </p></a
          >
        </div>
      </div> -->
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rightshow: true,
      phoneshow: false,
      a: "visible",
    };
  },
  methods: {
    changeStyle() {
      if (this.a === "visible") {
        this.a = "hidden";
      } else {
        this.a = "visible";
      }
    },
    gobuy() {
      let returnEle = null;
      returnEle = document.querySelector("#buy");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    goshop() {
      window.open(
        "https://tz0pp4t9ht.jiandaoyun.com/f/62e34b5f34b0a100083ff393"
      );
    },
    buy() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/413");
    },
  },
};
</script>

<style scoped>
.phone {
  position: absolute;
  top: 170px;
  right: 70px;
  width: 120px;
  height: 30px;
  line-height: 30px;
  padding: 8px 8px;
  background-color: whitesmoke;
  text-align: center;
  border-radius: 10px;
  color: #6b2f03;
  font-weight: 550;
}
.rightdiv {
  width: 40px;
  height: 100px;
  padding: 10px 8px;
  background-color: whitesmoke;
  text-align: center;
  border-radius: 10px;
  color: #6b2f03;
  font-weight: 550;
  cursor: pointer;
}
.rightdiv1 {
  width: 40px;
  line-height: 40px;
  height: 40px;
  padding: 10px 8px;
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  color: #6b2f03;
  font-weight: 550;
  cursor: pointer;
}
.rightfix {
  position: fixed;
  top: 200px;
  right: 18%;
}
.btmcontact {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid white;
  margin-top: 20px;
  padding-top: 30px;
  color: rgb(248, 237, 237);
}
.main8title {
  top: 260px;
  left: 780px;
  position: absolute;
  font-size: 22px;
  font-weight: 600;
}
.main8sum {
  width: 1150px;
  position: relative;
  margin: 0 auto;
}
.title {
  width: 110px;
  height: 30px;
  background-color: rgb(251, 199, 1);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.title1 {
  width: 110px;
  height: 30px;
  background-color: rgb(14, 210, 250);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.bttitle {
  margin-left: 10px;
  width: 150px;
  height: 30px;
  font-size: 16px;
  font-weight: 450;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.carousel1 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 570px;
}
.carousel2 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 100px;
}
#buy {
  position: absolute;
  top: 0;
}
.buy {
  width: 470px;
  height: 55px;
  color: white;
  position: absolute;
  bottom: 13%;
  left: 50%;
  line-height: 50px;
}
.buy:hover {
  cursor: pointer;
}
.buysum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1 {
  text-align: center;
  padding-top: 50px;
}
.main2 {
  text-align: center;
  margin-top: 65px;
}
.main3 {
  text-align: center;
  margin-top: 75px;
}
.main4 {
  text-align: center;
  margin-top: 75px;
}
.main5 {
  text-align: center;
  margin-top: 75px;
}
.main6 {
  text-align: center;
  margin-top: 75px;
}
.main7 {
  text-align: center;
  margin-top: 75px;
}
.main8 {
  text-align: center;
  margin-top: 70px;
  background-color: #ffefef;
}
.main9 {
  text-align: center;
  padding-top: 70px;
}
.main10 {
  text-align: center;
  padding-top: 70px;
}
.main11 {
  text-align: center;
  padding-top: 70px;
  padding-right: 85px;
}
section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01xiijkX1VVPV83kkGL_!!6000000002658-2-tps-1920-3500.png");
  background-size: cover;
  margin: -50px auto 0;
  box-shadow: inset 0px 45px 10px -15px white;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
.headimg {
  position: absolute;
  width: 25%;
  bottom: 25%;
  left: 52%;
  transform: translateX(-50%);
}
.headimg:hover {
  cursor: pointer;
}
</style>
