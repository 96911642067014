<template>
  <div class="bcgcolor">
    <header>
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i1/O1CN01YjLmly1lUyzgkj0Sz_!!6000000004823-2-tps-1920-794.png"
        alt=""
      />
    </header>
    <section>
      <div class="main1">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i2/O1CN01xRVbnp1z2UyqXtrZ7_!!6000000006656-2-tps-1132-319.png"
          alt=""
        />
      </div>
      <div class="main2">
        <div class="buysum">
          <img
            width="1000px"
            src="https://img.alicdn.com/imgextra/i4/O1CN01YZfjbh1nTGk1w1MXb_!!6000000005090-2-tps-1132-1507.png"
            alt=""
          />
          <div class="comorpriv">
            <div
              class="common"
              @click="ifCommond = true"
              :class="{ iscommon: ifCommond }"
            >
              公域拉新用户
            </div>
            <div
              class="private"
              @click="ifCommond = false"
              :class="{ iscommon: !ifCommond }"
            >
              私域消费转化
            </div>
          </div>
          <div class="comorprivimg">
            <img
              v-show="ifCommond"
              width="85%"
              src="https://img.alicdn.com/imgextra/i2/O1CN01Nb9HKF1uBalQh6sp5_!!6000000005999-2-tps-1009-520.png"
              alt=""
            />
            <img
              v-show="!ifCommond"
              width="85%"
              src="https://img.alicdn.com/imgextra/i4/O1CN01TXkXq91nYlUp06UX8_!!6000000005102-2-tps-1009-520.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="main3">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i2/O1CN01vtPZx020LZ5KfFUiF_!!6000000006833-2-tps-1132-1026.png"
          alt=""
        />
      </div>
      <div class="main4">
        <div class="buysum">
          <img
            width="1000px"
            src="https://img.alicdn.com/imgextra/i1/O1CN018pjg2h203hdY6G9v0_!!6000000006794-2-tps-1133-1101.png"
            alt=""
          />
          <div class="main4img">
            <div
              class="main4img1"
              @mouseenter="moveIn('img1')"
              @mouseout="moveOut('img1')"
            >
              <img
                width="100%"
                id="img1"
                src="https://img.alicdn.com/imgextra/i4/O1CN013GkkaK22UNrwH0bOU_!!6000000007123-0-tps-1080-6313.jpg"
                alt=""
              />
            </div>
            <div
              class="main4img1"
              style="margin-left:45px"
              @mouseenter="moveIn('img2')"
              @mouseout="moveOut('img2')"
            >
              <img
                width="100%"
                id="img2"
                src="https://img.alicdn.com/imgextra/i3/O1CN01iCbs4s26pgks4b3M9_!!6000000007711-0-tps-1080-5504.jpg"
                alt=""
              />
            </div>
            <div
              class="main4img1"
              style="margin-left:45px"
              @mouseenter="moveInVideo('video1')"
              @mouseout="moveOutVideo('video1')"
            >
              <video
                width="100%"
                id="video1"
                src="https://g.tbtx-inc.com/video/talk.mp4"
              ></video>
            </div>
            <div
              class="main4img1"
              style="margin-left:45px"
              @mouseenter="moveInVideo('video2')"
              @mouseout="moveOutVideo('video2')"
            >
              <video
                width="100%"
                id="video2"
                src="https://g.tbtx-inc.com/video/search.mp4"
              ></video>
            </div>
          </div>
        </div>
      </div>
      <div class="main5">
        <div class="buysum">
          <img
            width="1000px"
            src="https://img.alicdn.com/imgextra/i2/O1CN01Zl2nHV1vtqegtEyZJ_!!6000000006231-2-tps-1112-701.png"
            alt=""
          />
          <div class="main5content">
            <!-- <div class="maindiv">
              <div class="maindiv1">优享套餐</div>
              <div class="maindiv2">
                包含逛逛评测视频、逛逛开箱视频、猜你喜欢视频、有好货视频、逛逛种草图文、逛逛话题图文、有好货图文等公域内容；微详情视频、多品上新/主题清单、图文搭配/评测、青云计划、商家逛逛图文等私域内容；共计45项。
              </div>
              <div class="maindiv3">
                价 格 :
                <span style="font-size:32px;">8800</span> 元
              </div>
              <div class="maindiv4" @click="gubuy1"></div>
              <div style="width:80px;margin:0 auto">
                <el-popover
                  placement="bottom-start"
                  width="950"
                  trigger="hover"
                >
                  <div class="shuoming">
                    <strong>服务时间：</strong> 排期后1个月内<br />
                    <strong>服务内容：</strong><br />
                    <strong>【公域资源】</strong><br />
                    <strong>1、逛逛评测视频：</strong>
                    1支，时长30~40s左右；画面比例3:4；达人真实口播讲解，围绕核心卖点进行产品使用评测。<br />
                    <strong>2、逛逛开箱视频：</strong>
                    1支，时长20s左右；画面比例3:4；配音或花字介绍，进行产品开箱/外观细节展示，搭配简单使用场景。<br />
                    <strong>3、猜你喜欢视频：</strong>
                    1支，时长15~20s；画面比例3:4；产品利益点和黄金镜头前置，演示产品如何解决痛点。<br />
                    <strong>4、有好货视频：</strong>
                    1支，时长15~30s；画面比例9:16；口语化真实评测，具有感染力；在商品应用场景下能快速抓住消费者。<br />
                    <strong>5、逛逛种草图文：</strong>1篇<br />
                    <strong>6、逛逛话题图文：</strong>1篇<br />
                    <strong>7、有好货图文：</strong>1篇<br />
                    <strong>【私域资源】</strong><br />
                    <strong>1、微详情视频（物料，可装修）：</strong>1支<br />
                    <strong>2、逛逛图文-商家端：</strong>2篇<br />
                    <strong>3、多品上新/主题清单：</strong>10篇<br />
                    <strong>4、图文搭配/评测：</strong>20篇<br />
                    <strong>5、青云计划（图文搭配）：</strong>5篇<br />
                  </div>
                  <div slot="reference" class="maindiv5">服务说明</div>
                </el-popover>
              </div>
            </div> -->
            <div class="maindiv">
              <div class="maindiv1">尊享套餐</div>
              <div class="maindiv2">
                包含深度评测视频、逛逛评测视频、逛逛开箱视频、猜你喜欢视频、有好货视频及图文、逛逛种草图文、逛逛话题图文等公域内容；微详情视频、多品上新/主题清单、图文搭配/评测、青云计划、商家逛逛/有好货/猜你喜欢图文等私域内容；共计60项。
              </div>
              <div class="maindiv3">
                价 格 :
                <span style="font-size:32px;">12800</span> 元
              </div>
              <div class="maindiv4" @click="gubuy2"></div>
              <div style="width:80px;margin:0 auto">
                <el-popover placement="bottom" width="950" trigger="hover">
                  <div class="shuoming">
                    <div class="remark-content">
                      <strong>服务时间：</strong> 排期后1个月内<br />
                      <strong>服务内容：</strong><br />
                      <strong>【公域资源】</strong><br />
                      <strong>1、深度评测视频：</strong>
                      1支，时长40~60s左右；画面比例3:4；真实达人讲解、评测产品的外观、功效、使用步骤等，配合产品测评进行上身试用、真实操作等，口播进行真实的使用心得、经验分享。可用于淘宝短视频渠道投稿、产品详情页、店铺动态等。（如需其他画面比例请提前沟通）<br />
                      <strong>2、逛逛评测视频：</strong>
                      1支，时长30~40s左右；画面比例3:4；达人真实口播讲解，围绕核心卖点进行产品使用评测。<br />
                      <strong>3、逛逛开箱视频：</strong>
                      1支，时长20s左右；画面比例3:4；配音或花字介绍，进行产品开箱/外观细节展示，搭配简单使用场景。<br />
                      <strong>4、猜你喜欢视频：</strong>
                      2支，时长15~20s；画面比例3:4；产品利益点和黄金镜头前置，演示产品如何解决痛点。<br />
                      <strong>5、有好货视频：</strong>
                      1支，时长15~30s；画面比例9:16；口语化真实评测，具有感染力；在商品应用场景下能快速抓住消费者。<br />
                      <strong>6、逛逛种草图文：</strong>2篇<br />
                      <strong>7、逛逛话题图文：</strong>2篇<br />
                      <strong>8、有好货图文：</strong>2篇<br />
                      <strong>【私域资源】</strong><br />
                      <strong>1、微详情视频（物料，可装修）：</strong>1支<br />
                      <strong>2、逛逛图文-商家端：</strong>2篇<br />
                      <strong>3、有好货图文-商家端：</strong>2篇<br />
                      <strong>4、猜你喜欢图文-商家端：</strong>2篇<br />
                      <strong>5、多品上新/主题清单：</strong>15篇<br />
                      <strong>6、图文搭配/评测：</strong>20篇<br />
                      <strong>7、青云计划（图文搭配）：</strong>6篇<br />
                    </div>
                  </div>
                  <div slot="reference" class="maindiv5">服务说明</div>
                </el-popover>
              </div>
            </div>
            <div style="margin-left: 182px;" class="maindiv">
              <div class="maindiv1">全域VIP定制方案</div>
              <div class="maindiv2">
                包含直播、深度评测视频、逛逛评测视频、猜你喜欢视频、有好货视频及图文、逛逛种草及话题图文等公域内容；多品上新/主题清单、图文搭配/评测、青云计划、商家逛逛/有好货/猜你喜欢图文、好物点评团等私域内容；抖音带货视频、小红书视频及图文等站外内容，共计118项。
              </div>
              <div class="maindiv3">
                价 格 :
                <span style="font-size:32px;">30000</span> 元
              </div>
              <div class="maindiv4" @click="gubuy3"></div>
              <div style="width:80px;margin:0 auto">
                <el-popover placement="bottom-end" width="950" trigger="hover">
                  <div class="shuoming">
                    <div class="remark-content">
                      <strong>服务时间：</strong> 排期后1个月内<br />
                      <strong>服务内容：</strong><br />
                      <strong>【公域资源】</strong><br />
                      <strong>1、直播（混场单链接）：</strong>1场<br />
                      <strong>2、深度评测视频：</strong>
                      2支，时长40~60s左右；画面比例3:4；真实达人讲解、评测产品的外观、功效、使用步骤等，配合产品测评进行上身试用、真实操作等，口播进行真实的使用心得、经验分享。可用于淘宝短视频渠道投稿、产品详情页、店铺动态等。（如需其他画面比例请提前沟通）<br />
                      <strong>3、逛逛评测视频：</strong>
                      2支，时长30~40s左右；画面比例3:4；达人真实口播讲解，围绕核心卖点进行产品使用评测。<br />
                      <strong>4、猜你喜欢视频：</strong>
                      2支，时长15~20s；画面比例3:4；产品利益点和黄金镜头前置，演示产品如何解决痛点。<br />
                      <strong>5、有好货视频：</strong>
                      2支，时长15~30s；画面比例9:16；口语化真实评测，具有感染力；在商品应用场景下能快速抓住消费者。<br />
                      <strong>6、逛逛种草图文：</strong>5篇<br />
                      <strong>7、逛逛话题图文：</strong>5篇<br />
                      <strong>8、有好货图文：</strong>5篇<br />
                      <strong>【私域资源】</strong><br />
                      <strong>1、逛逛图文-商家端：</strong>10篇<br />
                      <strong>2、有好货图文-商家端：</strong>5篇<br />
                      <strong>3、猜你喜欢图文-商家端：</strong>5篇<br />
                      <strong>4、多品上新/主题清单：</strong>20篇<br />
                      <strong>5、图文搭配/评测：</strong>20篇<br />
                      <strong>6、青云计划（图文搭配）：</strong>20篇<br />
                      <strong>7、好物点评团（交付已投内容）：</strong>5篇<br />
                      <strong>【站外资源】</strong><br />
                      <strong>1、抖音带货视频：</strong>2支<br />
                      <strong>2、小红书种草视频：</strong>2支<br />
                      <strong>3、小红书种草图文：</strong>5篇<br />
                    </div>
                  </div>
                  <div slot="reference" class="maindiv5">服务说明</div>
                </el-popover>
              </div>
            </div>
          </div>
          <div class="beizhu">
            <el-popover placement="bottom" width="950" trigger="hover">
              <div class="shuoming">
                <div class="remark-content">
                  <strong>备注：</strong><br />
                  1、有好货多元化、猜你喜欢等，需按渠道规范提供图片，若因图片或选品池等官方规则限制导致内容无法投放的，执行中需做等价渠道替换。<br />
                  2、服务执行中，如果因渠道规则限制等原因，导致内容无法上线的，根据不同渠道，服务人员将默认按照以下规则进行替换投放：<br />
                  （1）有好货内容多元化，若因产品不在官方选品池内导致无法投放时，可替换为1篇逛逛图文；<br />
                  （2）逛逛渠道有图片要求，一般需下单后邮寄到我方进行拍摄，若因为所推产品是大件或商用物品等不方便邮寄拍摄的，需要商家提供实拍买家秀图片不少于3张，尺寸不小于750px；<br />
                  （3）若有更多渠道资源替换，以服务执行人员的具体方案为准。<br />
                  *本次活动最终解释权归天字网络所有
                </div>
              </div>
              <div slot="reference" class="maindiv5" style="color:white">
                服务备注
              </div>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="main6">
        <div class="buysum">
          <img
            width="1000px"
            src="https://img.alicdn.com/imgextra/i2/O1CN01sEEOqA1MBPfjd5VAJ_!!6000000001396-2-tps-1132-727.png"
            alt=""
          />
          <div class="main6content">
            <div class="main6left">
              <img
                v-show="activeImg === 0"
                width="100%"
                src="https://img.alicdn.com/imgextra/i2/O1CN01xypkBC1aiIdwtdjWl_!!6000000003363-2-tps-270-552.png"
                alt=""
              />
              <img
                v-show="activeImg === 1"
                width="100%"
                src="https://img.alicdn.com/imgextra/i3/O1CN01Swjt411HojVOdk8sn_!!6000000000805-2-tps-270-552.png"
                alt=""
              />
              <img
                v-show="activeImg === 2"
                width="100%"
                src="https://img.alicdn.com/imgextra/i2/O1CN01RdNIJH1JxYOL4jxRR_!!6000000001095-0-tps-265-552.jpg"
                alt=""
              />
              <img
                v-show="activeImg === 3"
                width="100%"
                src="https://img.alicdn.com/imgextra/i1/O1CN015AUEsw1REE5h4R81l_!!6000000002079-2-tps-265-552.png"
                alt=""
              />
              <img
                v-show="activeImg === 4"
                width="100%"
                src="https://img.alicdn.com/imgextra/i2/O1CN01fS8BTP1Rr9p8cLtZF_!!6000000002164-2-tps-256-532.png"
                alt=""
              />
            </div>
            <div class="main6right">
              <div
                class="main6div"
                v-for="(item, index) in sixArr"
                :key="index"
                @mouseenter="changeImg(index)"
              >
                <div class="main6rightimg">
                  <img
                    v-show="activeImg === index"
                    width="100%"
                    :src="item.activeImg"
                    alt=""
                  />
                  <img
                    v-show="activeImg !== index"
                    width="100%"
                    :src="item.img"
                    alt=""
                  />
                </div>
                <div class="main6righttitle" v-show="activeImg !== index">
                  {{ item.title }}
                </div>
                <div class="main6righttitle1" v-show="activeImg === index">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main7">
        <div class="buysum">
          <img
            width="1000px"
            src="https://img.alicdn.com/imgextra/i1/O1CN01zrFbLy1Nfvg8sF6qc_!!6000000001598-2-tps-1136-1190.png"
            alt=""
          />
          <div class="main7content" v-show="carouselIndex === 0">
            <div class="main7title1">【天**肉】千粉迷你小店</div>
            <div class="main7title2">粉丝数量不多，但却个个被抓牢。</div>
            <div class="main7title2">
              订阅内容投放仅一个月，粉丝触达率、活跃率远超同行同层三倍，订阅及逛逛端流量跃进店铺流量前十，小店也能运营粉丝“奇迹”。
            </div>
          </div>
          <div class="main7content" v-show="carouselIndex === 1">
            <div class="main7title1">【福**厂】知名茶厂</div>
            <div class="main7title2">
              配合天猫大促活动，及店铺优惠让利，在订阅内容上字字斟酌定制专属图文。
            </div>
            <div class="main7title2">
              将品牌心智贴近茶友，创造订阅及逛逛端稳居店铺免费流量前十，订阅端日均销售额5000+的成绩。
            </div>
          </div>
          <div class="main7content" v-show="carouselIndex === 2">
            <div class="main7title1">
              【PO**旗舰店】星空投影灯，以光学科技创造
            </div>
            <div class="main7title2">
              经过一个月的公私域全域运营，店铺全屏页短视频流量提升100%，尤其是在猜你喜欢视频和微详情视频中。
            </div>
            <div class="main7title2">
              在店铺内容全局表现中，内容种草从4月开始直线上升。
            </div>
          </div>
          <div class="main7content" v-show="carouselIndex === 3">
            <div class="main7title1">【八**马具】专业马具遇上专业团队</div>
            <div class="main7title2">
              优质专业的视频、图文内容结合有格调的产品，
            </div>
            <div class="main7title2">
              在运营期间为店铺全店流量，尤其在短视频流量上大涨60%，渠道成交日日递进。
            </div>
          </div>
        </div>
      </div>
      <div class="main8">
        <el-carousel
          :interval="4000"
          type="card"
          height="300px"
          @change="carouselChange"
        >
          <el-carousel-item v-for="(item, index) in carouselImg" :key="index">
            <div>
              <img width="100%" :src="item.img" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="main8btm">
          <a
            href="http://www.tbtx-inc.com/product/pc2019/jptbVideo.htm?type=3"
            target="_blank"
            >查看更多案例 &gt;&gt;</a
          >
        </div>
      </div>
      <div class="main9">
        <div class="buysum">
          <img
            width="1000px"
            src="https://img.alicdn.com/imgextra/i3/O1CN01CQFfsg1kviUrGbtW3_!!6000000004746-2-tps-1132-1689.png"
            alt=""
          />
          <div class="buy" @click="gowang"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carouselImg: [
        {
          img:
            "https://img.alicdn.com/imgextra/i1/1031765836/O1CN013Sdycc1sywCH9WRw8_!!1031765836-0-daren.jpg",
        },
        {
          img:
            "https://img.alicdn.com/imgextra/i2/1031765836/O1CN01U0aktZ1sywCCvAe9M_!!1031765836-0-daren.jpg",
        },
        {
          img:
            "https://img.alicdn.com/imgextra/i4/1031765836/O1CN01FBcZWw1sywCJrJ4Cr_!!1031765836-0-daren.jpg",
        },
        {
          img:
            "https://img.alicdn.com/imgextra/i3/1031765836/O1CN01s0VttB1sywCFNR5WW_!!1031765836-0-daren.jpg",
        },
      ],
      sixArr: [
        {
          activeImg:
            "https://img.alicdn.com/imgextra/i2/O1CN01RXjKKf1zGhALwGiSI_!!6000000006687-2-tps-124-58.png",
          img:
            "https://img.alicdn.com/imgextra/i1/O1CN01fYDDOs1aFRgnqFdak_!!6000000003300-2-tps-124-58.png",
          title:
            "混场直播，每场可放1个商品链接，每件商品5分钟左右口播时间，最真实最快速的让消费者看到产品，带货效果更明显。",
        },
        {
          activeImg:
            "https://img.alicdn.com/imgextra/i3/O1CN01GW1G8c1f2EL8q2NlH_!!6000000003948-2-tps-124-58.png",
          img:
            "https://img.alicdn.com/imgextra/i1/O1CN01z8WTUe1OVgLCEhsMJ_!!6000000001711-2-tps-124-58.png",
          title:
            "精品导购平台，为消费者挖掘高格调、高品质、有设计美感的商品。围绕单品进行创作，通过个性化、高品质的场景化导购内容，使消费者有体验感从而被种草。",
        },
        {
          activeImg:
            "https://img.alicdn.com/imgextra/i3/O1CN01fAHqml1fAvrkaE5Gj_!!6000000003967-2-tps-124-58.png",
          img:
            "https://img.alicdn.com/imgextra/i2/O1CN01soSTMQ1Slws1NgiI6_!!6000000002288-2-tps-124-58.png",
          title:
            "对宝贝360度的解读与真实使用、晒图、心得经验分享。千人千面精准透出，极大的提高粉丝点阅积极性，引导粉丝长草种草，帮助好物高效转化。",
        },
        {
          activeImg:
            "https://img.alicdn.com/imgextra/i4/O1CN01kJ9ABG1VyilLmRglf_!!6000000002722-2-tps-124-58.png",
          img:
            "https://img.alicdn.com/imgextra/i2/O1CN01S17MsY1D1USSBnv2R_!!6000000000156-2-tps-124-58.png",
          title:
            "淘宝重磅打造的平台级内容产品，是围绕已购商品进行实拍实测的使用心得分享社区，一站式解决消费者从种草到拔草的购物需求。",
        },
        {
          activeImg:
            "https://img.alicdn.com/imgextra/i2/O1CN01qr1C951JeJnEiUImw_!!6000000001053-2-tps-124-58.png",
          img:
            "https://img.alicdn.com/imgextra/i1/O1CN01YgrYB81SCgRAuJH8Y_!!6000000002211-2-tps-124-58.png",
          title:
            "在手淘首页的最上面，与“推荐”并列。商家可以在“订阅”里面，展示商品，爆款，上架，优惠促销，现场直播等内容，只要订阅了店铺的消费者，就能更快速更集中地看到有关店铺所展示的全部内容。",
        },
      ],
      ifCommond: true,
      activeImg: 0,
      carouselIndex: 0,
    };
  },
  methods: {
    carouselChange(index) {
      this.carouselIndex = index;
    },
    changeImg(value) {
      this.activeImg = value;
    },
    moveInVideo(ele) {
      let video = document.querySelector("#" + ele);
      video.play();
    },
    moveOutVideo(ele) {
      let video = document.querySelector("#" + ele);
      video.currentTime = 0;
      video.pause();
    },
    moveIn(ele) {
      let img = document.querySelector("#" + ele);
      img.style.position = "relative";
      let num = 0;
      this.timer = setInterval(function() {
        num = num - 1;
        img.style.top = num + "px";
      }, 15);
    },
    moveOut(ele) {
      clearInterval(this.timer);
      let img = document.querySelector("#" + ele);
      img.style.top = "0px";
    },
    gubuy1() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/548");
    },
    gubuy2() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/549");
    },
    gubuy3() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/550");
    },
    gowang() {
      window.open(
        "https://amos.alicdn.com/getcid.aw?ver=3&groupid=0&uid=%E7%83%AD%E5%B7%B4_tbtx&site=cntaobao&status=1&charset=utf-8"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.main8btm {
  margin-top: 20px;
  text-align: center;
  a {
    font-size: 20px;
    cursor: pointer;
    color: #666;
    text-decoration: none;
  }
}
.main7title1 {
  font-size: 22px;
  font-weight: 750;
  color: white;
  margin-bottom: 20px;
}
.main7title2 {
  font-size: 16px;
  font-weight: 550;
  color: #151515;
  line-height: 1.6;
}
.main7content {
  position: absolute;
  text-align: left;
  bottom: 7.2%;
  left: 12.5%;
}
.main6rightimg {
  width: 140px;
}
.main6div {
  width: 690px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;
}
.main6righttitle {
  width: 600px;
  padding: 0 50px 0 20px;
  font-size: 14px;
  color: #4c4c4c;
  line-height: 24px;
  text-align: justify;
  font-weight: 550;
}
.main6righttitle1 {
  width: 600px;
  padding: 0 50px 0 20px;
  font-size: 14px;
  color: #ef7d31;
  line-height: 24px;
  text-align: justify;
  font-weight: 550;
}
.main6left {
  width: 240px;
}
.main6right {
  margin-left: 40px;
}
.main6content {
  height: 500px;
  position: absolute;
  bottom: 5%;
  left: 12%;
  display: flex;
}
.beizhu {
  position: absolute;
  bottom: -5%;
  left: 47%;
}
.shuoming {
  width: 850px;
  padding: 34px 42px 36px;
  border: 10px solid #fb7f0b;
  border-radius: 10px;
  background: #fff;
  color: #333;
  font-size: 15px;
  text-align: left;
  box-shadow: 1px 3px 8px #fb7f0b;
}
.maindiv1 {
  color: #fe6000;
  text-align: center;
  font-size: 28px;
  margin-top: 34px;
  font-weight: bold;
  margin-bottom: 50px;
}
.maindiv2 {
  text-align: left;
  width: 370px;
  height: 170px;
  color: #6d6b6b;
  font-weight: 550;
  margin-bottom: 10px;
  padding-top: 30px;
}
.maindiv3 {
  text-align: center;
  color: #ffa148;
  font-weight: 550;
  margin-bottom: 10px;
}
.maindiv4 {
  width: 280px;
  height: 45px;
  margin: 0 auto;
  cursor: pointer;
}
.maindiv5 {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 550;
  text-decoration: underline;
  color: #6d6b6b;
  cursor: pointer;
}
.main5content {
  position: absolute;
  bottom: 6.3%;
  left: 11%;
  display: flex;
}
.main4img1 {
  width: 185px;
  height: 400px;
  border-radius: 20px;
  overflow: hidden;
}
.main4img {
  position: absolute;
  bottom: 13.3%;
  left: 13.5%;
  display: flex;
}
.comorprivimg {
  position: absolute;
  bottom: 4.3%;
  left: 8%;
}
.iscommon {
  background-color: #ef7d31 !important;
  color: white !important;
  z-index: 999;
}
.common {
  background-color: white;
  padding: 10px 50px;
  border-radius: 25px;
  color: black;
  font-size: 20px;
  font-weight: 550;
  border: 1px solid #ef7d31;
  cursor: pointer;
}
.private {
  background-color: white;
  padding: 10px 50px;
  border-radius: 25px;
  color: black;
  font-size: 20px;
  font-weight: 550;
  border: 1px solid #ef7d31;
  margin-left: -42px;
  cursor: pointer;
}
.comorpriv {
  position: absolute;
  bottom: 40.3%;
  left: 33%;
  display: flex;
}
.video {
  width: 145px;
  height: 640px;
  position: absolute;
  top: 40.3%;
  left: 11%;
}
.video2 {
  width: 145px;
  height: 640px;
  position: absolute;
  top: 40.3%;
  left: 23.9%;
}
.video3 {
  width: 145px;
  height: 640px;
  position: absolute;
  top: 40.3%;
  left: 36.8%;
}
.video4 {
  width: 145px;
  height: 640px;
  position: absolute;
  top: 40.3%;
  left: 50.9%;
}
.video5 {
  width: 145px;
  height: 640px;
  position: absolute;
  top: 40.3%;
  left: 63.9%;
}
.video6 {
  width: 145px;
  height: 640px;
  position: absolute;
  top: 40.3%;
  left: 77%;
}
/* .video1 {
  width: 126px;
  height: 255px;
  border-radius: 10px;
} */
div /deep/.el-carousel__arrow {
  width: 40px;
  height: 40px;
  font-size: 18px;
}
/* .bcgcolor {
  background-color: white;
  background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01KuGleO1KRmWXUd91Y_!!6000000001161-2-tps-1920-7475.png");
  background-size: cover;
} */
.carousel-control.left {
  background-color: aqua;
}
.title {
  width: 110px;
  height: 30px;
  background-color: rgb(251, 199, 1);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.title1 {
  width: 110px;
  height: 30px;
  background-color: rgb(14, 210, 250);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.bttitle {
  margin-left: 10px;
  width: 150px;
  height: 30px;
  font-size: 16px;
  font-weight: 450;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.carousel1 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 570px;
}
.carousel2 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 100px;
}
#buy {
  position: absolute;
  top: 0;
}
.buy {
  width: 30px;
  height: 30px;
  color: white;
  position: absolute;
  bottom: 13%;
  left: 79%;
  line-height: 50px;
}
.buy:hover {
  cursor: pointer;
}
.buysum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1 {
  text-align: center;
  padding-top: 190px;
}
.main2 {
  text-align: center;
  margin-top: 65px;
}
.main3 {
  text-align: center;
  margin-top: 75px;
}
.main4 {
  text-align: center;
  margin-top: 75px;
}
.main5 {
  text-align: center;
  margin-top: 75px;
}
.main6 {
  text-align: center;
  margin-top: 75px;
}
.main7 {
  text-align: center;
  margin-top: 75px;
}
.main8 {
  margin: 0 auto;
  width: 1000px;
  padding-top: 40px;
}
.main9 {
  text-align: center;
  padding-top: 70px;
}
.main10 {
  text-align: center;
  padding-top: 70px;
}
.main11 {
  text-align: center;
  padding-top: 70px;
  padding-right: 85px;
}
section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01WAvRqN27o96wvjGrP_!!6000000007843-2-tps-1920-10204.png");
  background-size: cover;
  margin: -255px auto 0;
  padding-bottom: 100px;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
.headimg {
  position: absolute;
  width: 25%;
  bottom: 25%;
  left: 52%;
  transform: translateX(-50%);
}
.headimg:hover {
  cursor: pointer;
}
</style>
