<template>
  <div class="bcgcolor">
    <section>
      <div class="main1">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i1/O1CN01rRbutk1aBKC3WaXpm_!!6000000003291-2-tps-983-438.png"
          alt=""
        />
      </div>
      <div class="main2">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i3/O1CN01M6GCPO21zhNnuZ15D_!!6000000007056-2-tps-980-615.png"
          alt=""
        />
      </div>
      <div class="main3">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i2/O1CN01dChAKl1ueu5QvTkuz_!!6000000006063-2-tps-981-2092.png"
          alt=""
        />
      </div>
      <div class="main3">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i4/O1CN01qzw9Cj1m4FSzDXk0C_!!6000000004900-2-tps-980-903.png"
          alt=""
        />
      </div>
      <div class="main5">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i4/O1CN01t9J4H01GVD4yCapAr_!!6000000000627-2-tps-980-3339.png"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    gobuy() {
      let returnEle = null;
      returnEle = document.querySelector("#buy");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    buy() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/413");
    },
  },
};
</script>

<style scoped>
.main8title {
  top: 260px;
  left: 780px;
  position: absolute;
  font-size: 22px;
  font-weight: 600;
}
.main8sum {
  width: 1150px;
  position: relative;
  margin: 0 auto;
}
.title {
  width: 110px;
  height: 30px;
  background-color: rgb(251, 199, 1);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.title1 {
  width: 110px;
  height: 30px;
  background-color: rgb(14, 210, 250);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.bttitle {
  margin-left: 10px;
  width: 150px;
  height: 30px;
  font-size: 16px;
  font-weight: 450;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.carousel1 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 570px;
}
.carousel2 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 100px;
}
#buy {
  position: absolute;
  top: 0;
}
.buy {
  width: 470px;
  height: 55px;
  color: white;
  position: absolute;
  bottom: 13%;
  left: 50%;
  line-height: 50px;
}
.buy:hover {
  cursor: pointer;
}
.buysum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1 {
  text-align: center;
  padding-top: 100px;
}
.main2 {
  text-align: center;
  margin-top: 65px;
}
.main3 {
  text-align: center;
  margin-top: 75px;
}
.main4 {
  text-align: center;
  margin-top: 75px;
}
.main5 {
  text-align: center;
  margin-top: 75px;
}
.main6 {
  text-align: center;
  margin-top: 75px;
}
.main7 {
  text-align: center;
  margin-top: 75px;
}
.main8 {
  text-align: center;
  margin-top: 70px;
  background-color: #ffefef;
}
.main9 {
  text-align: center;
  padding-top: 70px;
}
.main10 {
  text-align: center;
  padding-top: 70px;
}
.main11 {
  text-align: center;
  padding-top: 70px;
  padding-right: 85px;
}
section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01Uc5N9O1xBzwAU0ZVE_!!6000000006406-2-tps-1920-8080.png");
  background-size: cover;
  margin: -40px auto 0;
  padding-bottom: 100px;
  box-shadow: inset 0px 45px 10px -15px white;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
.headimg {
  position: absolute;
  width: 25%;
  bottom: 25%;
  left: 52%;
  transform: translateX(-50%);
}
.headimg:hover {
  cursor: pointer;
}
</style>
