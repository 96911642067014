<template>
  <div>
    <div>
      <div class="home">
        <home-header class="fixed-top" @showSearch="showSearch"></home-header>
        <div class="section">
          <div class="container">
            <home-leftaside
              class="fixed-aside"
              @showSearch="showSearch"
            ></home-leftaside>

            <div class="left-aside"></div>
            <home-section class="page"></home-section>
          </div>
        </div>
        <home-footer></home-footer>
      </div>
    </div>
    <div>
      <home-search v-if="searchShow" @exitSearch="exitSearch"></home-search>
    </div>
  </div>
</template>

<script>
import HomeHeader from "../common/Header.vue";
import HomeLeftaside from "../common/Leftaside.vue";
import HomeSection from "./components/Section.vue";
import HomeFooter from "../common/Footer.vue";
import HomeSearch from "../activity/components/Search.vue";

export default {
  components: {
    HomeHeader,
    HomeLeftaside,
    HomeSection,
    HomeFooter,
    HomeSearch,
  },
  data() {
    return {
      searchShow: false,
    };
  },
  watch: {
    $route() {
      this.$router.go(0);
      sessionStorage.removeItem("currentPage");
    },
  },
  methods: {
    showSearch() {
      this.searchShow = true;
    },
    exitSearch() {
      this.searchShow = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "Articledetail") {
      next();
    } else {
      sessionStorage.removeItem("currentPage");
      next();
    }
  },
};
</script>

<style scoped>
@import "./../../assets/css/common.css";
</style>
