<template>
  <div :class="{ 'fixed-aside': isFixed }" :style="{ position: pos }">
    <ul>
      <li>
        <router-link
          to="/"
          style="background-color:white"
          @click.native="refresh"
          ><img :src="img1" alt=""
        /></router-link>
      </li>
      <li>
        <router-link to="/" exact @click.native="refresh">首页</router-link>
      </li>
      <li>
        <router-link
          to="/news"
          :class="{
            routerlinkactive:
              this.isHover || this.columnId === 25 || this.columnId === 24,
          }"
          >热点</router-link
        >
      </li>

      <li>
        <router-link to="/newlist/4?name=视频">视频</router-link>
      </li>
      <li>
        <router-link
          to="/newlist/5?name=科技"
          :class="{
            routerlinkactive: this.columnId === 5,
          }"
          >科技</router-link
        >
      </li>
      <li>
        <router-link
          to="/newlist/6?name=生活"
          :class="{
            routerlinkactive: this.columnId === 6,
          }"
          >生活</router-link
        >
      </li>
      <li>
        <span class="active-item" @click="more"
          >更多
          <div v-show="showColumn" class="dialog">
            <li
              v-for="(item, index) in $store.state.columnList"
              :key="index"
              v-show="index > 2 && index < 6"
              style="margin:10px auto;"
            >
              <router-link
                :to="'/newlist/' + item.columnId + '?name=' + item.columnName"
                class="more"
                >{{ item.columnName }}</router-link
              >
            </li>
          </div></span
        >
      </li>

      <li style="background-color:rgb(245,246,250)">
        <el-button type="text" @click="openSearch" style="padding-top:5px">
          <span class="search"
            ><svg class="icon" aria-hidden="true">
              <use xlink:href="#iconsearch2"></use></svg>搜索</span
          ></el-button
        >
        <el-dialog
          title="请输入关键词"
          :visible.sync="dialogVisible"
          width="50%"
          :before-close="handleClose"
          :lock-scroll="isScroll"
          append-to-body
        >
          <el-input
            type="text"
            v-model="message"
            @keyup.enter.native="searchArticle"
          />
          <h2>#热门搜索#</h2>
          <div class="hot">
            <span
              v-for="(item, index) in searchList"
              :key="index"
              v-show="index < 4"
            >
              <router-link
                :to="'/taglist/' + item.tagId + '?name=' + item.tagName"
                >{{ item.tagName }}</router-link
              >
            </span>
          </div>

          <div class="hot">
            <span
              v-for="(item, index) in searchList"
              :key="index"
              v-show="index < 8 && index >= 4"
            >
              <router-link
                :to="'/taglist/' + item.tagId + '?name=' + item.tagName"
                >{{ item.tagName }}</router-link
              >
            </span>
          </div>
        </el-dialog>
      </li>
    </ul>
    <div class="media">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#iconmedia"></use>
      </svg>
      <router-link to="/cooperate">
        <span>业务合作</span>
      </router-link>
    </div>

    <div class="bg">
      <img
        src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/txws_wechat.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { getHomeList } from "@/api/home";

export default {
  data() {
    return {
      columnId: "",
      message: "",
      pos: "",
      isFixed: false,
      img1:
        "https://txws-media.oss-cn-hangzhou.aliyuncs.com/txws.png",
      showColumn: false,
      searchList: [],
      dialogVisible: false,
      isScroll: true,
      isHover: false,
    };
  },
  methods: {
    refresh() {
      this.$router.push("/news");
      setTimeout(() => {
        this.$router.push("/");
      }, 100);
    },
    openSearch() {
      this.$emit("showSearch", "true");
    },
    getColumnId() {
      this.$bus.on("sendColumnId", (val) => {
        this.columnId = val;
      });
    },
    searchArticle() {
      this.$router.push({
        path: "/searchlist/" + this.message,
        query: {
          key: +new Date(),
        },
      });
    },
    more() {
      this.showColumn = !this.showColumn;
    },
    handleClose(done) {
      done();
    },

    getTag() {
      let params = {};
      params.page = 1;
      params.pageSize = 10;
      getHomeList(params).then((res) => {
        var hotList = res.data.data.result;

        for (var i = 0; i < hotList.length; i++) {
          if (hotList[i].tagList) {
            for (var j = 0; j < hotList[i].tagList.length; j++) {
              var hotData = hotList[i].tagList[j];
              this.searchList = this.searchList.concat(hotData);
            }
          }
        }
      });
    },
  },

  mounted() {
    this.getColumnId();

    var that = this;
    window.addEventListener("scroll", function() {
      //监听滚动
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 1600) {
        that.pos = "absolute";
      } else {
        that.pos = "";
      }
    });

    window.addEventListener("scroll", function() {
      //监听滚动
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 0) {
        that.isFixed = true;
      } else {
        that.isFixed = false;
      }
    }),
      this.getTag();
  },
};
</script>

<style scoped>
#ishover:hover {
  overflow: hidden;
}
.fixed-aside {
  width: 91px;
  position: fixed;
  z-index: 100;
  margin-right: 504px;
  right: 50%;
}

@media screen and (max-width: 1190px) {
  .fixed-aside {
    width: 91px;
    position: fixed;
    z-index: 100;
    left: 0;
  }
}
ul {
  height: 390px;
  margin: 0;
  padding: 0;
}
ul li {
  width: 91px;
  height: 30px;
  line-height: 30px;
  list-style: none;
  margin-bottom: 12px;
  text-align: center;
  vertical-align: middle;
}
ul li a {
  text-decoration: none;
  color: #27282d;
  font-size: 18px;
  font-weight: 500;
  width: 91px;
  height: 30px;
  display: inline-block;
}
.routerclike > a {
  width: 30px;
  height: 20px;
  background-color: rgb(215, 12, 24);
  color: #fff;
}
.routerlinkactive {
  background-color: rgb(215, 12, 24);
  color: #fff;
}
.active-item {
  color: #27282d;
  font-size: 18px;
  font-weight: 500;
  width: 91px;
  height: 30px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
ul li .search {
  font-size: 18px;
  font-weight: 400;
  color: #27282d;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.media {
  height: 40px;
  width: 91px;
  background-color: rgb(255, 229, 231);
  line-height: 40px;
  border: 1px solid rgb(215, 12, 24);
  display: flex;
  justify-content: center;
  align-items: center;
}
.media .icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  color: rgb(229, 89, 97);
  font-size: 17px;
}
.media span {
  color: rgb(215, 12, 24);
  font-size: 14px;
  font-weight: 400;
}

.dialog {
  width: 105px;
  height: 125px;
  background-color: #fff;
  border: 1px solid rgb(239, 239, 239);
  color: #27282d;
  position: absolute;
  left: 75px;
  top: -50px;
  z-index: 100;
  border-radius: 7px;
}
.dialog::before {
  content: "";
  border: 10px solid;
  border-color: transparent rgb(239, 239, 239) transparent transparent;

  width: 0px;
  height: 0px;
  position: absolute;
  left: -20px;
  top: 52px;
}
.dialog::after {
  content: "";
  width: 0;
  height: 0;
  border: 10px solid;
  position: absolute;
  left: -19px;
  top: 52px;
  border-color: transparent #fff transparent transparent;
}
.more {
  font-size: 16px;
  font-weight: 400;
}

.bg {
  width: 91px;
  height: 88px;
  background-color: #fff;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  margin-top: 15px;
}
.bg > img {
  width: 81px;
  height: 81px;
  display: block;
  padding: 5px;
}

.hot {
  margin-bottom: 15px;
}
.hot span a {
  color: #bbbbbb;
  font-size: 14px;
  padding: 0 10px;
  background-color: rgb(245, 246, 250);
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  text-decoration: none;
}
.hot span a:hover {
  background-color: rgb(215, 12, 24);
  color: #fff;
}
</style>
