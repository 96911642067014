<template>
  <div>
    <header>
      <span>
        <img
          width="120%"
          src="https://img.alicdn.com/imgextra/i1/O1CN01AESlgG1cAz6m62av9_!!6000000003561-2-tps-89-31.png"
          alt=""
        />
      </span>
      <div class="menu">
        <div class="menuitem" @click="move1">大会首页</div>
        <div class="menuitem" @click="move2">精彩瞬间</div>
        <div class="menuitem" @click="move3">演讲回顾</div>
        <div class="menuitem" @click="move4">峰会咨询</div>
        <div class="menuitem" @click="move5">年度颁奖</div>
        <div class="menuitem" @click="move6">峰会集锦</div>
        <div class="menuitem1" @click="move7">大会页面</div>
      </div>
    </header>
    <section>
      <div class="main1" id="main1">
        <img
          width="100%"
          src="https://img.alicdn.com/imgextra/i1/O1CN01DItR7425tWUrGgfCH_!!6000000007584-2-tps-1920-568.png"
          alt=""
        />
      </div>
      <div style="margin-top:-5px" id="spoiler"></div>
      <div class="middle">
        <div class="boxsum" style="margin-top:160px">
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01s1lMK41JhyxUU6rsC_!!6000000001061-2-tps-476-110.png"
            alt=""
          />
        </div>
        <div class="carousel">
          <el-carousel height="300px" class="topcarou" :autoplay="false">
            <el-carousel-item>
              <div class="carouselsum">
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i3/O1CN01SbscXQ1myaAa17sOH_!!6000000005023-0-tps-3000-1853.jpg"
                    alt=""
                  />
                </div>

                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i1/O1CN0175Lc931XFUwgnJqrB_!!6000000002894-0-tps-5759-3544.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i3/O1CN01KMfUPU29k8qW7JLfO_!!6000000008105-0-tps-5724-3513.jpg"
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carouselsum">
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01qB30jM26AT9Grt1Im_!!6000000007621-0-tps-4500-2880.jpg"
                    alt=""
                  />
                </div>

                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i2/O1CN01JnLxGH26XMxCyvpVa_!!6000000007671-0-tps-4062-2545.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i3/O1CN018Xquf21ODoxFZPKp8_!!6000000001672-0-tps-5759-3655.jpg"
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carouselsum">
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i2/O1CN01FyWiND1k8FgYfh58V_!!6000000004638-0-tps-5760-3840.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i4/O1CN01wHPi4g1ibu5z4RPz9_!!6000000004432-0-tps-5249-3408.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i4/O1CN01GImmV5230RdNsGoiN_!!6000000007193-0-tps-5760-3840.jpg"
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carouselsum">
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01bWGBQC1ns061nLEej_!!6000000005144-0-tps-5564-3217.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i4/O1CN01L4QBY527I5LwJ6yWS_!!6000000007773-0-tps-4500-2560.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01Rb0BV01k1q8rqbFEW_!!6000000004624-0-tps-3000-1653.jpg"
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carouselsum">
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i2/O1CN01jAPVUv1kZH3KpfFye_!!6000000004697-0-tps-4212-2726.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01a8nsPi1WFfTfJRMH8_!!6000000002759-0-tps-4500-2890.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i2/O1CN01pzI25l1WAd7Y4GGwT_!!6000000002748-0-tps-4088-2610.jpg"
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carouselsum">
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i3/O1CN01qp5BSx1zDUS8RCBzY_!!6000000006680-0-tps-4122-2748.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i3/O1CN01lNGUH01RCqxuECLBd_!!6000000002076-0-tps-3000-2000.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01wjtgKt1vysyaFy6CI_!!6000000006242-0-tps-3000-2000.jpg"
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carouselsum">
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01YVidcb1SLNxPxoxGf_!!6000000002230-0-tps-4500-3000.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i3/O1CN01HI7IOX1qtK5Ri3Bi4_!!6000000005553-0-tps-3000-1926.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i2/O1CN01DLUqW922UNuRrQEZt_!!6000000007123-0-tps-3828-2448.jpg"
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carouselsum">
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01v4dHtK278vQlI1lUX_!!6000000007753-0-tps-5760-3457.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01EPyhzQ1LxDMbnkoFu_!!6000000001365-0-tps-3000-1785.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i3/O1CN01NB38Tf1Zx7uLcXPxf_!!6000000003260-0-tps-5016-2929.jpg"
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="boxsum" id="review">
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01HuLqly1brI5iW4htl_!!6000000003518-2-tps-476-109.png"
            alt=""
          />
        </div>
        <div class="partvideosum">
          <div class="main8sum">
            <img
              width="100%"
              src="https://img.alicdn.com/imgextra/i3/O1CN01XlKUvG1CE1jyU8KUA_!!6000000000048-2-tps-1377-1483.png"
              alt=""
            />
            <div class="topvideo1">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i4/O1CN01yepp0Y1xAck8sCfLE_!!6000000006403-2-tps-641-358.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E6%96%B0%E7%BD%91%E5%95%86/%E5%BC%A0%E9%98%94720P.mp4"
              ></video>
            </div>
            <div class="topvideo2">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i1/O1CN01KVYZJS1jQHfHSYP9R_!!6000000004542-2-tps-640-352.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E6%96%B0%E7%BD%91%E5%95%86/%E7%8E%8B%E7%90%A6720P.mp4"
              ></video>
            </div>
            <div class="topvideo3">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i1/O1CN01RduWkq1wOzYHPkeYf_!!6000000006299-2-tps-636-355.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E6%96%B0%E7%BD%91%E5%95%86/%E8%A7%86%E9%A2%912/%E7%A9%86%E5%B0%94720P.mp4"
              ></video>
            </div>
            <div class="topvideo4">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i1/O1CN01FOP7DG1xMX2bGrGEO_!!6000000006429-2-tps-631-356.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E6%96%B0%E7%BD%91%E5%95%86/%E8%A7%86%E9%A2%91/%E5%8D%95%E4%BA%BA720P/%E5%BC%BA%E5%B0%8F%E6%98%8E720P.mp4"
              ></video>
            </div>
            <div class="topvideo5">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i3/O1CN014OSQOD1mZqmW1qR80_!!6000000004969-2-tps-636-360.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E6%96%B0%E7%BD%91%E5%95%86/%E8%A7%86%E9%A2%912/%E9%87%91%E5%A5%8E720P.mp4"
              ></video>
            </div>
            <div class="topvideo6">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i1/O1CN01daaUt81t0lkvjCJGG_!!6000000005840-2-tps-636-351.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E6%96%B0%E7%BD%91%E5%95%86/%E8%A7%86%E9%A2%912/%E9%A9%AC%E5%86%9B720P.mp4"
              ></video>
            </div>
            <div class="topvideo7">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i4/O1CN01Jgi83j1Sik9sKTplq_!!6000000002281-2-tps-641-361.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E6%96%B0%E7%BD%91%E5%95%86/%E8%A7%86%E9%A2%91/%E5%8D%95%E4%BA%BA720P/%E8%8B%A5%E6%9F%AF720P.mp4"
              ></video>
            </div>
            <div class="topvideo8">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i3/O1CN01fiZTMi1lGKHheP4nZ_!!6000000004791-2-tps-636-356.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E6%96%B0%E7%BD%91%E5%95%86/%E8%A7%86%E9%A2%912/%E5%A4%A7%E5%A3%AE720P.mp4"
              ></video>
            </div>
          </div>
        </div>

        <div class="boxsum" id="information" style="margin-top:70px">
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01tMoOLM1OEHLkhLZex_!!6000000001673-2-tps-476-109.png"
            alt=""
          />
        </div>
        <div class="information">
          <div class="informationsum">
            <div class="information1" @click="open1">
              2021，谁在抢占这些最火的市场？
            </div>
          </div>
          <div class="informationsum">
            <div class="information1" @click="open2">
              跨境电商新风口，20万商家抢着入驻这个平台
            </div>
          </div>
          <div class="informationsum">
            <div class="information1" @click="open3">
              400多万商家都在用的平台，抓住了未来生意增长的重要风口
            </div>
          </div>
          <div class="informationsum">
            <div class="information1" @click="open4">
              9万亿电商蓝海！机遇在前，但这些“坑”也别忽略
            </div>
          </div>
          <div class="informationsum" style="border-bottom:1px solid #7B6CBB">
            <div class="information1" @click="open5">
              去元宇宙做生意，到底该怎么玩
            </div>
          </div>
        </div>
        <div class="boxsum" id="highlights">
          <img
            src="https://img.alicdn.com/imgextra/i3/O1CN01QS0P0v1pS0qx7EsEj_!!6000000005358-2-tps-476-109.png"
            alt=""
          />
        </div>
        <div class="yearpeople">
          <img
            width="65%"
            src="https://img.alicdn.com/imgextra/i4/O1CN01VlEyot1FezuYGhqYS_!!6000000000513-2-tps-1380-2539.png"
            alt=""
          />
        </div>
        <div class="boxsum" style="margin-top:70px" id="last">
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01GyMKcs21zhNEHrbhE_!!6000000007056-2-tps-476-109.png"
            alt=""
          />
        </div>
        <div class="yearpeople">
          <img
            width="65%"
            src="https://img.alicdn.com/imgextra/i4/O1CN01OFNRpp1uWCYN1QahD_!!6000000006044-2-tps-1376-3735.png"
            alt=""
          />
        </div>
        <div class="sponsor" style="margin-top:50px">主办方</div>
        <div class="sponsorimg">
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01lmZvbf1PeDKldOFzZ_!!6000000001865-2-tps-194-68.png"
            alt="天下网商"
          />
        </div>
        <div class="sponsor">合作支持</div>
        <div class="sponsorimg">
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01C9Yopl28KCk3dwQBv_!!6000000007913-2-tps-352-55.png"
            alt="阿里巴巴"
          />
        </div>
        <div class="sponsor">活动支持</div>
        <div class="sponsorimg">
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01mVpGZr1zpVGWLtNpV_!!6000000006763-2-tps-196-56.png"
            alt="活动行"
          />
        </div>
        <img
          style="margin-top:80px"
          width="100%"
          src="https://img.alicdn.com/imgextra/i1/O1CN01KKcIzB27kTuJlOOsf_!!6000000007835-2-tps-1920-425.png"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    open1() {
      window.open("https://mp.weixin.qq.com/s/rhM63y5YPm_kdyM48moUaA");
    },
    open2() {
      window.open("https://mp.weixin.qq.com/s/ySA_oXwG5i1e1hHE8N1x7A");
    },
    open3() {
      window.open("https://mp.weixin.qq.com/s/Onj9TIGP3Ak0lpSI9Oos8w");
    },
    open4() {
      window.open("https://mp.weixin.qq.com/s/kh2EW_M2mgSVBeHrPT12DA");
    },
    open5() {
      window.open("https://mp.weixin.qq.com/s/fdFBpErByq5ozMOlUqtt3g");
    },
    buyticket() {
      window.open("http://hdxu.cn/mhuQZ");
    },
    move1() {
      let returnEle = null;
      returnEle = document.querySelector("#main1");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move2() {
      let returnEle = null;
      returnEle = document.querySelector("#spoiler");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move3() {
      let returnEle = null;
      returnEle = document.querySelector("#review");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move4() {
      let returnEle = null;
      returnEle = document.querySelector("#information");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move5() {
      let returnEle = null;
      returnEle = document.querySelector("#highlights");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move6() {
      let returnEle = null;
      returnEle = document.querySelector("#last");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move7() {
      window.location.href =
        "https://www.tianxiawangshang.com/activity/wsfh/remould";
    },
  },
};
</script>

<style scoped>
.yearpeople {
  text-align: center;
  margin-top: 70px;
}
.topvideo1 {
  top: 5px;
  left: 5px;
  position: absolute;
  width: 585px;
  height: 327px;
}
.topvideo2 {
  top: 5px;
  left: 610px;
  position: absolute;
  width: 585px;
  height: 327px;
}
.topvideo3 {
  top: 532px;
  left: 5px;
  position: absolute;
  width: 382px;
  height: 200px;
}
.topvideo4 {
  top: 532px;
  left: 410px;
  position: absolute;
  width: 382px;
  height: 200px;
}
.topvideo5 {
  top: 532px;
  left: 815px;
  position: absolute;
  width: 382px;
  height: 200px;
}
.topvideo6 {
  top: 939px;
  left: 5px;
  position: absolute;
  width: 382px;
  height: 200px;
}
.topvideo7 {
  top: 939px;
  left: 410px;
  position: absolute;
  width: 382px;
  height: 200px;
}
.topvideo8 {
  top: 939px;
  left: 815px;
  position: absolute;
  width: 382px;
  height: 200px;
}
.main8sum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1 {
  padding-top: 100px;
}
.highlights {
  margin: 50px auto 130px;
  width: 1400px;
}
.highlightsum {
  display: flex;
  margin-top: 20px;
}
.highlightsum1 {
  width: 310px;
  height: 550px;
  margin-left: 20px;
}
.highlightsum2 {
  width: 1300px;
  height: 730px;
  margin-left: 20px;
}
.highlightsum3 {
  width: 420px;
  height: 760px;
  margin-left: 20px;
}
.highlightsum4 {
  width: 650px;
  height: 1150px;
  margin-left: 20px;
}
.informationsum {
  border-top: 1px solid #7b6cbb;
  padding: 25px 0;
  text-align: left;
}
.information {
  margin: 50px auto 130px;
  width: 1200px;
}
.information1 {
  font-size: 25px;
  color: #009aea;
}
.information1:hover {
  cursor: pointer;
}
.information2 {
  font-size: 35px;
  color: white;
}
.carouselsum {
  display: flex;
  margin-left: 40px;
}
.topcarousel {
  width: 400px;
  height: 270px;
  margin-left: 30px;
}
.carousel {
  margin: 60px auto 120px;
  width: 1400px;
}
.partvideosum {
  margin: 50px auto 0;
  width: 1200px;
  display: flex;
}
.partvideo {
  margin: 35px auto 80px;
}
.partvideo1 {
  width: 425px;
  height: 240px;
  border: 2px solid rgb(0, 239, 255);
  box-sizing: border-box;
}
.partvideo11 {
  width: 625px;
  height: 350px;
  border: 2px solid rgb(0, 239, 255);
  box-sizing: border-box;
}
.partvideo2 {
  width: 425px;
  height: 100px;
  background-color: rgb(0, 239, 255);
}
.partvideo22 {
  width: 625px;
  height: 100px;
  background-color: rgb(0, 239, 255);
}
.partvideotitle1 {
  padding-top: 15px;
  font-size: 30px;
  font-weight: 550;
  margin-left: 20px;
}
.partvideotitle2 {
  font-size: 17px;
  margin-left: 20px;
}

.buttomdiv {
  margin-top: 200px;
  width: 100%;
  height: 50px;
  background-color: black;
  text-align: center;
  color: white;
  font-size: 26px;
  line-height: 50px;
  letter-spacing: 6px;
}
.ticket {
  display: flex;
  width: 55%;
  margin: 50px auto 0;
}
.ticketimg {
  width: 502px;
  height: 195px;
  border: 3px solid white;
}
.ticketbutton {
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01rzgOWe1h7qQSO0h0r_!!6000000004231-2-tps-502-195.png");
}
.chooseticket {
  width: 200px;
  height: 100px;
  background-color: #ac642a;
  text-align: center;
  line-height: 100px;
  font-size: 40px;
  font-weight: 500;
  color: white;
}
.money {
  width: 400px;
  height: 100px;
  background-color: #f0a35f;
  text-align: center;
  line-height: 100px;
  font-size: 40px;
  font-weight: 500;
  color: blue;
  margin-bottom: 20px;
}
.link {
  width: 220px;
  height: 55px;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01Ho0y8v21Kw3mQQGF1_!!6000000006967-2-tps-217-52.png");
  margin: 0 auto;
  margin-top: 25px;
}
.link > span {
  display: block;
  text-align: center;
  line-height: 50px;
}
.link > span > a {
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  color: white;
  letter-spacing: 6px;
}
.desc {
  color: white;
  text-align: center;
}
.ticket2 {
  margin-left: 120px;
}
.sponsor {
  background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01rBvphP1HojZ87H833_!!6000000000805-2-tps-460-72.png");
  width: 460px;
  height: 70px;
  margin: 30px auto;
  border-radius: 15px;
  text-align: center;
  line-height: 70px;
  font-size: 40px;
  font-weight: 600;
}
.sponsorimg {
  width: 400px;
  height: 70px;
  margin: 0 auto;
  text-align: center;
}
.sponsorimg1 {
  width: 600px;
  height: 205px;
  margin: 0 auto;
  text-align: center;
}
footer {
  width: 100%;
  margin-top: 50px;
  min-width: 1300px;
  height: 310px;
  color: white;
  box-sizing: border-box;
}

footer > div {
  width: 1175px;

  margin: 0 auto;
}

footer > div > ul {
  width: 1015px;
  height: 175px;
  margin: 0 auto;
}
footer > div > ul > li {
  float: left;
  width: 415xp;
  height: 162px;
  list-style-type: none;
  margin: 0 70px;
}
/* 大会议程 */
.contentsum {
  display: flex;
  align-items: center;
  margin-top: 60px;
}
.pmimg {
  width: 210px;
  height: 210px;
}
.pmtitle {
  margin-left: 30px;
}
.pmtitle1 {
  color: white;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
}
.pmtitle2 {
  color: white;
  font-size: 25px;
  font-weight: 400;
  margin-left: 15px;
}

/* 大会剧透 */
.middle {
  width: 100%;
}
.pianchang {
  margin: 20px auto 0;
  width: 80%;
  color: rgb(0, 207, 255);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pianchang1 {
  margin: -40px auto 0;
  width: 80%;
  color: rgb(0, 207, 255);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pian1 {
  font-size: 30px;
}
.pian2 {
  font-size: 55px;
}
.part1 {
  height: 100px;
  color: white;
  margin: 50px 0 0 19%;
  font-size: 40px;
  font-weight: 500;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01UJvZ6a1dYAjNzSWFL_!!6000000003747-2-tps-360-84.png");
}
.part2 {
  height: 100px;
  color: white;
  margin: 0 0 0 19%;
  font-size: 40px;
  font-weight: 500;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01iIVC4f22uUQSuPFwS_!!6000000007180-2-tps-360-86.png");
}
.part3 {
  height: 100px;
  color: white;
  margin: 0 0 0 19%;
  font-size: 40px;
  font-weight: 500;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i1/O1CN012rWZx21pepsrRDYIi_!!6000000005386-2-tps-361-87.png");
}
.part4 {
  height: 100px;
  color: white;
  margin: 0 0 0 19%;
  font-size: 40px;
  font-weight: 500;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01siW5os1xCSGNKRtDN_!!6000000006407-2-tps-364-84.png");
}
.parttitle {
  margin: 0 0 0 250px;
  padding-top: 55px;
}
.boxsum {
  text-align: center;
}
.box2 {
  width: 50px;
  height: 150px;
  background-image: linear-gradient(to bottom right, green, yellow);
  margin-left: 40px;
}
.box3 {
  width: 8%;
  height: 150px;
  background-image: linear-gradient(to bottom right, green, yellow);
  margin-left: 40px;
}
.span1 {
  margin-left: 3%;
}
.span1 div:nth-child(1) {
  color: white;
  font-size: 76px;
  font-weight: 700;
  display: block;
  margin: -20px 0 -5px 0;
}
.span1 div:nth-child(2) {
  color: white;
  font-size: 26px;
  font-weight: 400;
  display: block;
}
header {
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 1400px;
  height: 80px;
  background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01Rml089292dChXdb0l_!!6000000008010-2-tps-1920-66.png");
  background-size: cover;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
header span {
  margin: 0 0 0 0;
}
.menu {
  display: flex;
}
.menuitem {
  width: 180px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 23px;
  font-weight: 500;
  color: black;
}
.menuitem:hover {
  cursor: pointer;
}
.menuitem1 {
  width: 130px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  font-weight: 500;
  margin-left: 20px;
  background-size: cover;
  color: white;
  background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01Qu5CY81Er4k90As7l_!!6000000000404-2-tps-122-40.png");
}
.menuitem1:hover {
  cursor: pointer;
}
.el-menu-demo {
  margin-right: 5%;
  min-width: 500px;
}
.el-menu-item {
  font-size: 17px;
  font-weight: 500;
}
.el-menu-item:hover {
  background-color: transparent !important;
}
.el-submenu__title:hover {
  background-color: transparent !important;
}
video {
  width: 100%;
}
.left {
  height: 750px;
  margin: 90px 0 0 350px;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i1/O1CN01KeLOH31JLXUIpoj3K_!!6000000001012-2-tps-336-546.png");
}
.left p {
  font-size: 24px;
  font-weight: 400;
  color: white;
  margin-left: 300px;
}
section {
  width: 100%;
  min-width: 1400px;
  height: 100%;
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01xShKp81tdF1d88O1S_!!6000000005924-2-tps-1920-12454.png");
  background-size: cover;
  margin-top: 75px;
}
</style>
