import axios from "axios";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  // baseURL: "http://10.64.84.150:4026",
  // baseURL: "https://139.129.236.203:4033",
  // baseURL: "http://114.55.52.103:4033",
  // baseURL: "https://test.tianxiawangshang.com/iwshang/",
  timeout: 60000, // 请求超时时间
});

export default service;
