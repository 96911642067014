<template>
  <div>
    <div class="cont-01">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i4/O1CN01OFsWbJ1rAjAzKbZIk_!!6000000005591-2-tps-1920-595.png"
        alt=""
      />
    </div>
    <div class="cont-02">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i2/O1CN01th5lD51RB1NP7eyg9_!!6000000002072-2-tps-1920-563.png"
        alt=""
      />
    </div>
    <div class="cont-03">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i1/O1CN01A9dINt21UYTqMsTeq_!!6000000006988-2-tps-1920-939.png"
        alt=""
      />
    </div>
    <div class="cont-04">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i1/O1CN01kau5zY1KnJDlEA2CH_!!6000000001208-2-tps-1920-642.png"
        alt=""
      />
    </div>
    <div class="cont-05">
      <img
        width="100%"
        src="https://img.alicdn.com/tfs/TB17w7uVHY1gK0jSZTEXXXDQVXa-1920-747.png"
        alt=""
      />
    </div>
    <div class="cont-06">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i2/O1CN01EVSMbu1Dn7iFMEnnT_!!6000000000260-2-tps-1920-1761.png"
        alt=""
      />
      <!--       <div class="w-1150"> -->
      <div class="first">
        <a
          href="https://www.tianxiawangshang.com/tzyx/zebra/product/470"
          target="_blank"
          class="a1"
        ></a>
      </div>
      <div class="first1">
        <a
          href="https://www.tianxiawangshang.com/tzyx/zebra/product/471"
          target="_blank"
          class="a1"
        ></a>
      </div>
      <div class="first2">
        <a
          href="https://www.tianxiawangshang.com/tzyx/zebra/product/472"
          target="_blank"
          class="a1"
        ></a>
      </div>
      <div class="second">
        <a
          href="https://www.tianxiawangshang.com/tzyx/zebra/product/473"
          target="_blank"
          class="a1"
        ></a>
      </div>
      <div class="second1">
        <a
          href="https://www.tianxiawangshang.com/tzyx/zebra/product/474"
          target="_blank"
          class="a1"
        ></a>
      </div>
      <div class="second2">
        <a
          href="https://www.tianxiawangshang.com/tzyx/zebra/product/475"
          target="_blank"
          class="a1"
        ></a>
      </div>
      <!--       </div> -->
    </div>
    <div class="bottom">
      <img
        width="100%"
        src="https://img.alicdn.com/tfs/TB1EiAqk8FR4u4jSZFPXXanzFXa-1920-752.png"
        alt=""
      />
    </div>
    <div class="phone">
      <div class="phonetitle">天下网商-付费流量推广组合营销</div>
      <div class="phonepic">
        <div style="margin-right: 50px;">反馈与建议：0571-85022088-79324</div>
        <div style="margin-right: 35px;">
          旺旺：天下网商-客满
          <a
            target="_blank"
            href="https://www.taobao.com/go/market/webww/ww.php?ver=3&touid=天下网商-客满&siteid=cntaobao&status=1&charset=utf-8"
            ><img
              src="https://img.alicdn.com/imgextra/i3/O1CN01pshhXZ1px9jw3DGAk_!!6000000005426-1-tps-77-19.gif"
              alt=""
          /></a>
        </div>
      </div>
      <div class="phonepic2">
        <div class="phonepic1">
          <div style="margin-right: 10px;">咨询订购旺旺：天下网商-咨询</div>
          <a
            target="_blank"
            href="https://www.taobao.com/go/market/webww/ww.php?ver=3&touid=天下网商-咨询&siteid=cntaobao&status=1&charset=utf-8"
            ><img
              src="https://img.alicdn.com/imgextra/i3/O1CN01pshhXZ1px9jw3DGAk_!!6000000005426-1-tps-77-19.gif"
              alt=""
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.cont-01 {
  max-width: 1920px;
  min-width: 1200px;
}

.cont-02 {
  max-width: 1920px;
  min-width: 1200px;
  margin-top: -5px;
}

.cont-03 {
  max-width: 1920px;
  min-width: 1200px;
}

.cont-04 {
  max-width: 1920px;
  min-width: 1200px;
}

.cont-05 {
  max-width: 1920px;
  min-width: 1200px;
}

.cont-06 {
  max-width: 1920px;
  min-width: 1200px;
  position: relative;
}

.bottom {
  max-width: 1920px;
  min-width: 1200px;
}

.first {
  position: absolute;
  width: 170px;
  height: 50px;
  top: 17.5%;
  left: 21.5%;
}

.a1 {
  display: block;
  width: 175px;

  height: 45px;
}

.first1 {
  position: absolute;
  width: 170px;
  height: 50px;
  top: 31.5%;
  left: 21.5%;
}

.first2 {
  position: absolute;
  width: 170px;
  height: 50px;
  top: 46%;
  left: 21.5%;
}

.second {
  position: absolute;
  width: 120px;
  height: 60px;
  top: 58.5%;
  left: 72.3%;
}

.second1 {
  position: absolute;
  width: 120px;
  height: 60px;
  top: 72.5%;
  left: 72.3%;
}
.second2 {
  position: absolute;
  width: 170px;
  height: 40px;
  top: 91.1%;
  left: 59.3%;
}

.phone {
  width: 100%;
  height: 300px;
  background-color: #323232;
  color: white;
  font-size: 20px;
  text-align: left;
  margin-top: -5px;
  max-width: 1920px;
  min-width: 1200px;
}

.phonetitle {
  text-align: center;
  padding-top: 50px;
  font-size: 27px;
}

.phonepic {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}

.phonepic1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.phonepic2 {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
</style>
