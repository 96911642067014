<template>
  <div class="footer">
    <div class="footer-top">
      <div class="container-top">
        <div class="about">
          <h2>关于我们</h2>
          <ul>
            <li>
              <a href="https://tianxiawangshang.tmall.com/" target="_blank"
                >订阅杂志</a
              >
            </li>
            <li>
              <a
                href="http://topic.tianxiawangshang.com/aboutus"
                target="_blank"
              >
                公司简介</a
              >
            </li>
            <li>
              <a
                href="/cooperate"
                target="_blank"
              >
                联系我们</a
              >
            </li>
          </ul>
        </div>
        <div class="together">
          <h2>合作网站</h2>
          <ul>
            <li>
              <a href="http://www.imaijia.com/#/" target="_blank">电商在线</a>
            </li>
            <li>
              <a href="http://www.aliresearch.com/cn/index" target="_blank"
                >阿里研究院</a
              >
            </li>
            <li><a href="https://www.huxiu.com/" target="_blank">虎嗅网</a></li>
            <li><a href="https://36kr.com/" target="_blank">36kr</a></li>
            <li>
              <a
                href="https://www.alibabagroup.com/cn/global/home"
                target="_blank"
                >阿里巴巴</a
              >
            </li>
            <li>
              <a href="https://www.hupan.com/o/web/index.html#/" target="_blank"
                >湖畔大学</a
              >
            </li>
            <li><a href="http://www.cztv.com/" target="_blank">新蓝网</a></li>
            <li>
              <a
                href="https://waimaoquan.alibaba.com/newbbs/category/196/%E5%85%AC%E5%91%8A%E6%B4%BB%E5%8A%A8%E5%8C%BA"
                target="_blank"
                >阿里外贸论坛</a
              >
            </li>
            <li>
              <a href="http://www.cbndata.com/home" target="_blank">CBNData</a>
            </li>
            <li>
              <a href="https://www.foodtalks.cn/" target="_blank"
                >FoodTalks食品</a
              >
            </li>
          </ul>
        </div>
        <div class="codes">
          <div class="code">
            <div class="bg">
              <img
                src="https://img.alicdn.com/imgextra/i2/O1CN01NRwh0723Xsn4qFBjL_!!6000000007266-0-tps-1280-1280.jpg"
                alt=""
              />
            </div>
            <span>企业公众号</span>
          </div>
          <div class="code">
            <div class="bg">
              <img
                src="https://img.alicdn.com/imgextra/i2/O1CN01oK09oK23sx0ciN4qJ_!!6000000007312-0-tps-1280-1280.jpg"
                alt=""
              />
            </div>
            <span>天下网商</span>
          </div>
          <div class="code">
            <div class="bg">
              <img
                src="https://img.alicdn.com/imgextra/i3/O1CN015FwuU71sB1F7Cyj5F_!!6000000005727-0-tps-1280-1280.jpg"
                alt=""
              />
            </div>
            <span>当代企业</span>
          </div>
          <div class="code">
            <div class="bg">
              <img
                src="https://img.alicdn.com/imgextra/i2/O1CN01W3NZXz205zpNExJTk_!!6000000006799-0-tps-1280-1280.jpg"
                alt=""
              />
            </div>
            <span>视频号</span>
          </div>
          <div class="code">
            <div class="bg">
              <img
                src="https://img.alicdn.com/imgextra/i2/O1CN01ABPlFK23KbIrmJLJY_!!6000000007237-2-tps-1280-1280.png"
                alt=""
                style="width: 76px; height: 76px; padding: 13px"
              />
            </div>
            <span>播客</span>
          </div>
        </div>
        <div class="cnzz">
          <a
            href="https://www.cnzz.com/stat/website.php?web_id=1280667220"
            target="_blank"
            title="站长统计"
          >
            <img src="https://icon.cnzz.com/img/pic2.gif" alt=""
          /></a>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container-bottom">
        <ul>
          <li>浙江天下网商网络传媒有限公司</li>
          <li>|</li>
          <li>未经许可不得转载</li>
          <li>|</li>
          <li style="position: relative">
            <a href="https://beian.miit.gov.cn" target="_blank"
              >浙ICP备11003104号
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    gocnzz() {
      window.open("https://www.cnzz.com/stat/website.php?web_id=1280667220");
    },
  },
};
</script>

<style scoped>
.cnzz {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.footer-top {
  min-width: 1190px;
  background-color: rgb(50, 50, 50);
  height: 210px;
  margin-top: 30px;
}
.container-top {
  width: 1190px;
  height: 210px;
  margin: 0 auto;
  display: flex;
}
.container-top .about {
  width: 215px;
  margin-right: 30px;
}
.container-top .about h2 {
  color: #e1e1e1;
  font-size: 16px;
  padding-top: 20px;
  font-weight: 500;
}
.container-top .about ul {
  width: 165px;
  padding: 0;
}
.container-top .about ul li {
  float: left;
  padding-right: 30px;
  padding-bottom: 10px;
  list-style: none;
  font-weight: 400;
}
.container-top .about ul li a {
  color: #e1e1e1;
  font-size: 14px;
}
.container-top .together {
  width: 300px;
}
.container-top .together h2 {
  color: #e1e1e1;
  font-size: 16px;
  padding-top: 20px;
  font-weight: 500;
}
.container-top .together ul {
  width: 330px;
  padding: 0;
}

.container-top .together ul li {
  float: left;
  padding-right: 24px;
  list-style: none;

  font-weight: 400;
  width: 95px;
  height: 24px;
}
.container-top .together ul li a {
  color: #e1e1e1;
  font-size: 14px;
}
.container-top .codes {
  width: 800px;
  height: 200px;
  padding-top: 40px;
  position: relative;
}
.container-top .codes .code {
  width: 100px;
  height: 120px;
  float: left;
  margin-left: 30px;
  margin-right: 0;
  text-align: center;
  vertical-align: middle;
}
.container-top .codes .code .bg {
  background-color: #fff;
  width: 100px;
  height: 100px;
}
.container-top .codes .code img {
  display: inline-block;
  width: 86px;
  height: 86px;
  padding: 7px;
}
.container-top .codes .code span {
  font-size: 16px;
  color: #e1e1e1;
  font-weight: 400;
  display: inline-block;
  margin-top: 10px;
}
.footer-bottom {
  min-width: 1190px;
  background-color: rgb(215, 12, 24);
  height: 50px;
}
.container-bottom {
  width: 1190px;
  height: 50px;
  margin: 0 auto;
}
.container-bottom ul {
  width: 500px;
  height: 40px;
  margin: 0 auto;
  padding: 10px 0 0 0;
}
.container-bottom ul li {
  float: left;
  padding: 6px;
  list-style: none;
  color: rgb(255, 218, 218);
  font-size: 14px;
  font-weight: 300;
}
.container-bottom ul li a {
  font-size: 14px;
  text-decoration: none;
  color: rgb(255, 218, 218);
  font-weight: 300;
}
</style>
