<template>
  <div class="searchDialog">
    <div class="topimg">
      <img
        width="100%"
        src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/txws.png"
        alt=""
      />
    </div>
    <div class="exit">
      <i @click="exit" style="font-size:50px" class="el-icon-close"></i>
    </div>
    <div class="search">
      <el-input
        type="text"
        size="middle"
        ref="seach_input"
        v-model="message"
        placeholder="请输入关键词"
        @keyup.enter.native="searchArticle"
      />
      <h2>#热门搜索#</h2>
      <div class="hot">
        <div
          class="text"
          v-for="(item, index) in searchList"
          :key="index"
          @click="tagClick(item)"
          v-show="index < 8"
        >
          <router-link
            :to="'/taglist/' + item.tagId + '?name=' + item.tagName"
            >{{ item.tagName }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHomeList } from "@/api/home";

export default {
  data() {
    return {
      searchList: [],
      searchShow: false,
      message: "",
    };
  },
  created() {
    this.stop();
    this.getTag();
    this.$nextTick(() => {
      this.$refs.seach_input.focus();
    });
  },
  methods: {
    tagClick(item) {
      this.move();
      this.$router.push({
        path: "/taglist/" + item.tagId + "?name=" + item.tagName,
      });
    },
    exit() {
      this.move();
      this.$emit("exitSearch", "false");
    },
    searchArticle() {
      this.move();
      this.$router.push({
        path: "/searchlist/" + this.message,
        query: {
          key: +new Date(),
        },
      });
    },
    getTag() {
      let params = {};
      params.page = 1;
      params.pageSize = 10;
      getHomeList(params).then((res) => {
        var hotList = res.data.data.result;

        for (var i = 0; i < hotList.length; i++) {
          if (hotList[i].tagList) {
            for (var j = 0; j < hotList[i].tagList.length; j++) {
              var hotData = hotList[i].tagList[j];
              this.searchList = this.searchList.concat(hotData);
            }
          }
        }
      });
    },
    stop() {
      var mo = function(e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    move() {
      var mo = function(e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    fixedBody() {
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.prevBodyCss = document.body.style.cssText;
      document.body.style.cssText +=
        "position:fixed;width:100%;top:-" + scrollTop + "px;";
    },
  },
};
</script>

<style lang="less" scoped>
.exit {
  position: absolute;
  top: 100px;
  right: 200px;
  cursor: pointer;
}
.topimg {
  width: 130px;
  height: 130px;
  position: absolute;
  top: 100px;
  left: 160px;
}
div/deep/.el-input__inner {
  border: 0;
  height: 100px;
  color: black;
  font-size: 40px;
  opacity: 0.7;
}
.hot {
  display: flex;
  margin-right: 20px;
}
.text {
  color: black;
  font-size: 17px;
  text-align: center;
  padding: 0 20px;
  height: 100%;
  line-height: 40px;
  background-color: rgba(153, 153, 153, 0.3);
  margin: 0 10px;
  text-decoration: none;
  cursor: pointer;
}
.text a {
  color: rgb(114, 112, 112);
}
.text:hover {
  background-color: rgb(215, 12, 24);
  a {
    color: white;
  }
}

.search {
  width: 70%;
  margin: 0 auto;
  align-items: center;
  margin-top: 250px;
}
.searchDialog {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.97);
  position: fixed;
  top: 0;
  z-index: 9999;
}
</style>
