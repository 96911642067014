<template>
  <div>
    <div>
      <div class="home">
        <home-header class="fixed-top" @showSearch="showSearch"></home-header>
        <div class="section">
          <home-banner></home-banner>
          <home-section></home-section>
        </div>
        <home-footer class="footer"></home-footer>
      </div>
    </div>
    <div>
      <home-search v-if="searchShow" @exitSearch="exitSearch"></home-search>
    </div>
  </div>
</template>

<script>
import HomeHeader from "../common/Header.vue";
import HomeBanner from "./components/Banner.vue";
import HomeSection from "./components/Section.vue";
import HomeFooter from "../common/Footer.vue";
import HomeSearch from "./components/Search.vue";

export default {
  components: {
    HomeHeader,
    HomeBanner,
    HomeSection,
    HomeFooter,
    HomeSearch,
  },
  data() {
    return {
      searchShow: false,
    };
  },
  methods: {
    showSearch() {
      this.searchShow = true;
    },
    exitSearch() {
      this.searchShow = false;
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === "Articledetail") {
      next();
    } else {
      sessionStorage.removeItem("currentPage");
      next();
    }
  },
};
</script>

<style scoped>
@import "./../../assets/css/common.css";
</style>
