<template>
  <div>
    <section class="article-content">
      <div class="article" v-for="(item, index) in articlelist" :key="index">
        <div class="article-left">
          <router-link :to="'/articledetail/' + item.contentId">
            <img :src="item.thumb" style=" width: 220px;height: 146px;"
          /></router-link>
        </div>
        <div class="article-right">
          <ul>
            <li>
              <router-link :to="'/articledetail/' + item.contentId">
                <h2>{{ item.title }}</h2></router-link
              >
            </li>
            <li>
              <router-link :to="'/articledetail/' + item.contentId">
                <span>{{ item.intro }}</span></router-link
              >
            </li>
            <li>
              <div class="tag">
                <div class="tag-left">
                  <span v-for="(item, index) in item.tagList" :key="index">
                    <router-link
                      :to="'/taglist/' + item.tagId + '?name=' + item.tagName"
                      >{{ item.tagName }}</router-link
                    >
                  </span>
                </div>
                <div class="tag-right">
                  <img :src="img2" alt="" />
                  <span>{{ item.author }}</span>

                  <span>{{ item.createTime }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getSearch, getSearchCount } from "@/api/search";
export default {
  data() {
    return {
      tagname: "",
      newscount: 0,
      img2: "http://cdnupload.iwshang.com/23-23.png",
      articlelist: [],
      page: 1,
      value: true,
    };
  },

  methods: {
    //给sessionStorage存值
    setContextData: function(key, value) {
      if (typeof value == "string") {
        sessionStorage.setItem(key, value);
      } else {
        sessionStorage.setItem(key, JSON.stringify(value));
      }
    },
    // 从sessionStorage取值
    getContextData: function(key) {
      const str = sessionStorage.getItem(key);
      if (typeof str == "string") {
        try {
          return JSON.parse(str);
        } catch (e) {
          return str;
        }
      }
      return;
    },
    getArticlelist(keywords, i) {
      let params = {};
      params.keyword = "阿里妈妈双11专题";
      params.page = i;
      params.pageSize = 10;

      getSearch(params).then((res) => {
        this.articlelist = res.data.data.result.slice(0, 4);
      });
    },
    getTagname(keywords) {
      this.tagname = keywords;
      document.title = keywords + "-天下网商-传播新商业,赋能新商业,成就新商业";
    },
    getNewscount(keywords) {
      let params = {};
      params.keyword = keywords;

      getSearchCount(params).then((res) => {
        this.newscount = res.data.data.result;
      });
    },
    prevChange(val) {
      val--;
      this.getArticlelist(this.$route.params.keywords, val);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    nextChange(val) {
      val++;
      this.getArticlelist(this.$route.params.keywords, val);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    currentChange(val) {
      this.getArticlelist(this.$route.params.keywords, val);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.page = val;
      this.setContextData("currentPage", this.page);
    },
  },

  created() {
    this.page = this.getContextData("currentPage") || 1;
  },
  mounted() {
    this.getArticlelist(this.$route.params.keywords, this.page);
    // this.getTagname(this.$route.params.keywords);
    // this.getNewscount(this.$route.params.keywords);
  },
};
</script>

<style scoped>
@import "../../../assets/css/common.css";

.list {
  background-color: #f6f6f6;
  height: 30px;
  padding: 10px 0 0 30px;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 40px;
  width: 800px;
}
.list span {
  display: block;
  float: left;
  padding-right: 5px;
  line-height: 20px;
}
.list em {
  float: left;
  white-space: nowrap;
  display: block;
  background-color: #4aabf0;
  border-radius: 2px;
  color: #fff;
  height: 20px;
  line-height: 20px;
  padding: 0 5px;
  margin-right: 20px;
}
.list span cite {
  color: #4faced;
}
</style>
