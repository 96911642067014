<template>
  <div class="home">
    <home-header class="fixed-top"></home-header>
    <div class="section">
      <div class="container">
        <home-section class="page"></home-section>
      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>

<script>
import HomeHeader from "../common/Header.vue";
import HomeSection from "./components/Section.vue";
import HomeFooter from "../common/Footer.vue";

export default {
  components: {
    HomeHeader,
    HomeSection,
    HomeFooter,
  },
};
</script>

<style scoped>
@import "./../../assets/css/common.css";
</style>
