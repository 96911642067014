<template>
  <div>
    <!-- <div class="toptitle">
      6月16日当天18点来飞猪逛吃团直播间（上飞猪搜“飞猪逛吃团”），领1000-66/2000-116/3000-166红包更划算哦！还有神秘boss做客直播间，发618大红包。
    </div> -->
    <div>
      <div class="midtable" @scroll.passive="getScroll($event)" ref="box">
        <div class="topth">
          <div class="topth1"></div>
          <div class="topth2">图片</div>
          <div class="topth2">商品名称</div>
          <div class="topth2">目的地</div>
          <div class="topth2">最低活动</div>
          <div class="topth2">复制口令</div>
        </div>
        <div class="middletablesum" v-show="this.activated === 6">
          <div
            :class="{ double: index % 2 === 0 }"
            v-for="(item, index) in tableData6"
            :key="index"
          >
            <div
              :class="{
                middletable: true,
                middletop: index - 1 === activeindex,
              }"
              v-show="index !== activeindex"
            >
              <div class="tr1">{{ item.serialNum }}</div>
              <div class="tr2">
                <img :src="item.picUrl" style="width: 60px" />
              </div>
              <div class="tr4">
                {{ item.name }}
              </div>
              <div class="tr3">
                {{ item.location }}
              </div>
              <div style="color:red" class="tr5">
                {{ item.price }}
              </div>
              <div class="tr6">
                <div class="copy" @click="handleCopy(item.password)">
                  复制口令
                </div>
              </div>
            </div>
            <div v-show="index === activeindex" class="activeindex">
              <div class="tr7">{{ item.serialNum }}</div>
              <div>
                <img :src="item.picUrl" style="width: 30vw;margin-left:5px" />
              </div>
              <div>
                <div style="margin-left:20px">
                  <div class="activetitle1">{{ item.name }}</div>
                  <div class="activetitle2">{{ item.location }}</div>
                  <div style="display:flex;align-items:center">
                    <div class="activeprice">{{ item.price }}</div>
                    <div class="activecopy" @click="handleCopy(item.password)">
                      复制口令
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="middletablesum" v-show="this.activated === 7">
          <div
            :class="{ double: index % 2 === 0 }"
            v-for="(item, index) in tableData7"
            :key="index"
          >
            <div
              :class="{
                middletable: true,
                middletop: index - 1 === activeindex,
              }"
              v-show="index !== activeindex"
            >
              <div class="tr1">{{ item.serialNum }}</div>
              <div class="tr2">
                <img :src="item.picUrl" style="width: 60px" />
              </div>
              <div class="tr4">
                {{ item.name }}
              </div>
              <div class="tr3">
                {{ item.location }}
              </div>
              <div style="color:red" class="tr5">
                {{ item.price }}
              </div>
              <div class="tr6">
                <div class="copy" @click="handleCopy(item.password)">
                  复制口令
                </div>
              </div>
            </div>
            <div v-show="index === activeindex" class="activeindex">
              <div class="tr7">{{ item.serialNum }}</div>
              <div>
                <img :src="item.picUrl" style="width: 30vw;margin-left:5px" />
              </div>
              <div>
                <div style="margin-left:20px">
                  <div class="activetitle1">{{ item.name }}</div>
                  <div class="activetitle2">{{ item.location }}</div>
                  <div style="display:flex;align-items:center">
                    <div class="activeprice">{{ item.price }}</div>
                    <div class="activecopy" @click="handleCopy(item.password)">
                      复制口令
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="middletablesum" v-show="this.activated === 8">
          <div
            :class="{ double: index % 2 === 0 }"
            v-for="(item, index) in tableData8"
            :key="index"
          >
            <div
              :class="{
                middletable: true,
                middletop: index - 1 === activeindex,
              }"
              v-show="index !== activeindex"
            >
              <div class="tr1">{{ item.serialNum }}</div>
              <div class="tr2">
                <img :src="item.picUrl" style="width: 14vw" />
              </div>
              <div class="tr4">
                {{ item.name }}
              </div>
              <div class="tr3">
                {{ item.location }}
              </div>
              <div style="color:red" class="tr5">
                {{ item.price }}
              </div>
              <div class="tr6">
                <div class="copy" @click="handleCopy(item.password)">
                  复制口令
                </div>
              </div>
            </div>
            <div v-show="index === activeindex" class="activeindex">
              <div class="tr7">{{ item.serialNum }}</div>
              <div>
                <img :src="item.picUrl" style="width: 30vw;margin-left:5px" />
              </div>
              <div>
                <div style="margin-left:20px">
                  <div class="activetitle1">{{ item.name }}</div>
                  <div class="activetitle2">{{ item.location }}</div>
                  <div style="display:flex;align-items:center">
                    <div class="activeprice">{{ item.price }}</div>
                    <div class="activecopy" @click="handleCopy(item.password)">
                      复制口令
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="middletablesum" v-show="this.activated === 9">
          <div
            :class="{ double: index % 2 === 0 }"
            v-for="(item, index) in tableData9"
            :key="index"
          >
            <div
              :class="{
                middletable: true,
                middletop: index - 1 === activeindex,
              }"
              v-show="index !== activeindex"
            >
              <div class="tr1">{{ item.serialNum }}</div>
              <div class="tr2">
                <img :src="item.picUrl" style="width: 60px" />
              </div>
              <div class="tr4">
                {{ item.name }}
              </div>
              <div class="tr3">
                {{ item.location }}
              </div>
              <div style="color:red" class="tr5">
                {{ item.price }}
              </div>
              <div class="tr6">
                <div class="copy" @click="handleCopy(item.password)">
                  复制口令
                </div>
              </div>
            </div>
            <div v-show="index === activeindex" class="activeindex">
              <div class="tr7">{{ item.serialNum }}</div>
              <div>
                <img :src="item.picUrl" style="width: 30vw;margin-left:5px" />
              </div>
              <div>
                <div style="margin-left:20px">
                  <div class="activetitle1">{{ item.name }}</div>
                  <div class="activetitle2">{{ item.location }}</div>
                  <div style="display:flex;align-items:center">
                    <div class="activeprice">{{ item.price }}</div>
                    <div class="activecopy" @click="handleCopy(item.password)">
                      复制口令
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="middletablesum" v-if="this.activated === 10">
          <div
            :class="{ double: index % 2 === 0 }"
            v-for="(item, index) in tableData10"
            :key="index"
          >
            <div
              :class="{
                middletable: true,
                middletop: index - 1 === activeindex,
              }"
              v-show="index !== activeindex"
            >
              <div class="tr1">{{ item.serialNum }}</div>
              <div class="tr2">
                <img :src="item.picUrl" style="width: 60px" />
              </div>
              <div class="tr4">
                {{ item.name }}
              </div>
              <div class="tr3">
                {{ item.location }}
              </div>
              <div style="color:red" class="tr5">
                {{ item.price }}
              </div>
              <div class="tr6">
                <div class="copy" @click="handleCopy(item.password)">
                  复制口令
                </div>
              </div>
            </div>
            <div v-show="index === activeindex" class="activeindex">
              <div class="tr7">{{ item.serialNum }}</div>
              <div>
                <img :src="item.picUrl" style="width: 30vw;margin-left:5px" />
              </div>
              <div>
                <div style="margin-left:20px">
                  <div class="activetitle1">{{ item.name }}</div>
                  <div class="activetitle2">{{ item.location }}</div>
                  <div style="display:flex;align-items:center">
                    <div class="activeprice">{{ item.price }}</div>
                    <div class="activecopy" @click="handleCopy(item.password)">
                      复制口令
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="middletablesum" v-if="this.activated === 11">
          <div
            :class="{ double: index % 2 === 0 }"
            v-for="(item, index) in tableData11"
            :key="index"
          >
            <div
              :class="{
                middletable: true,
                middletop: index - 1 === activeindex,
              }"
              v-show="index !== activeindex"
            >
              <div class="tr1">{{ item.serialNum }}</div>
              <div class="tr2">
                <img :src="item.picUrl" style="width: 60px" />
              </div>
              <div class="tr4">
                {{ item.name }}
              </div>
              <div class="tr3">
                {{ item.location }}
              </div>
              <div style="color:red" class="tr5">
                {{ item.price }}
              </div>
              <div class="tr6">
                <div class="copy" @click="handleCopy(item.password)">
                  复制口令
                </div>
              </div>
            </div>
            <div v-show="index === activeindex" class="activeindex">
              <div class="tr7">{{ item.serialNum }}</div>
              <div>
                <img :src="item.picUrl" style="width: 30vw;margin-left:5px" />
              </div>
              <div>
                <div style="margin-left:20px">
                  <div class="activetitle1">{{ item.name }}</div>
                  <div class="activetitle2">{{ item.location }}</div>
                  <div style="display:flex;align-items:center">
                    <div class="activeprice">{{ item.price }}</div>
                    <div class="activecopy" @click="handleCopy(item.password)">
                      复制口令
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="middletablesum" v-if="this.activated === 12">
          <div
            :class="{ double: index % 2 === 0 }"
            v-for="(item, index) in tableData12"
            :key="index"
          >
            <div
              :class="{
                middletable: true,
                middletop: index - 1 === activeindex,
              }"
              v-show="index !== activeindex"
            >
              <div class="tr1">{{ item.serialNum }}</div>
              <div class="tr2">
                <img :src="item.picUrl" style="width: 60px" />
              </div>
              <div class="tr4">
                {{ item.name }}
              </div>
              <div class="tr3">
                {{ item.location }}
              </div>
              <div style="color:red" class="tr5">
                {{ item.price }}
              </div>
              <div class="tr6">
                <div class="copy" @click="handleCopy(item.password)">
                  复制口令
                </div>
              </div>
            </div>
            <div v-show="index === activeindex" class="activeindex">
              <div class="tr7">{{ item.serialNum }}</div>
              <div>
                <img :src="item.picUrl" style="width: 30vw;margin-left:5px" />
              </div>
              <div>
                <div style="margin-left:20px">
                  <div class="activetitle1">{{ item.name }}</div>
                  <div class="activetitle2">{{ item.location }}</div>
                  <div style="display:flex;align-items:center">
                    <div class="activeprice">{{ item.price }}</div>
                    <div class="activecopy" @click="handleCopy(item.password)">
                      复制口令
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="middletablesum" v-if="this.activated === 13">
          <div
            :class="{ double: index % 2 === 0 }"
            v-for="(item, index) in tableData13"
            :key="index"
          >
            <div
              :class="{
                middletable: true,
                middletop: index - 1 === activeindex,
              }"
              v-show="index !== activeindex"
            >
              <div class="tr1">{{ item.serialNum }}</div>
              <div class="tr2">
                <img :src="item.picUrl" style="width: 60px" />
              </div>
              <div class="tr4">
                {{ item.name }}
              </div>
              <div class="tr3">
                {{ item.location }}
              </div>
              <div style="color:red" class="tr5">
                {{ item.price }}
              </div>
              <div class="tr6">
                <div class="copy" @click="handleCopy(item.password)">
                  复制口令
                </div>
              </div>
            </div>
            <div v-show="index === activeindex" class="activeindex">
              <div class="tr7">{{ item.serialNum }}</div>
              <div>
                <img :src="item.picUrl" style="width: 30vw;margin-left:5px" />
              </div>
              <div>
                <div style="margin-left:20px">
                  <div class="activetitle1">{{ item.name }}</div>
                  <div class="activetitle2">{{ item.location }}</div>
                  <div style="display:flex;align-items:center">
                    <div class="activeprice">{{ item.price }}</div>
                    <div class="activecopy" @click="handleCopy(item.password)">
                      复制口令
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <el-table
          v-if="this.activated === 6"
          :data="tableData6"
          stripe
          border
          style="width: 100%;font-size: 10px"
          height="100%"
          :header-cell-style="{ background: '#ffba3d', color: 'white' }"
        >
          <el-table-column type="index" width="37" align="center">
          </el-table-column>
          <el-table-column label="图片" align="center">
            <template slot-scope="scope">
              <img :src="scope.row.picUrl" style="width: 14vw" />
            </template>
          </el-table-column>
          <el-table-column
            prop="location"
            label="目的地"
            width="60"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="划重点"
            align="center"
          ></el-table-column>
          <el-table-column label="最低活动" align="center">
            <template slot-scope="scope">
              <div style="color:red">¥{{ scope.row.price }}</div>
            </template>
          </el-table-column>
          <el-table-column label="复制口令" align="center" width="75">
            <template slot-scope="scope" style="text-align:center">
              <div class="copy" @click="handleCopy(scope.$index, scope.row)">
                复制口令
              </div>
            </template>
          </el-table-column>
        </el-table> -->
      </div>
      <div class="btmtabs">
        <div
          :class="{
            tabs: this.activated !== 6,
            activated: this.activated === 6,
          }"
          @click="gotab(6)"
        >
          必买爆款
        </div>
        <div
          :class="{
            tabs: this.activated !== 7,
            activated: this.activated === 7,
          }"
          @click="gotab(7)"
        >
          全国通兑
        </div>
        <div
          :class="{
            tabs: this.activated !== 8,
            activated: this.activated === 8,
          }"
          @click="gotab(8)"
        >
          长三角
        </div>
        <div
          :class="{
            tabs: this.activated !== 9,
            activated: this.activated === 9,
          }"
          @click="gotab(9)"
        >
          海南云南
        </div>
        <div
          :class="{
            tabs: this.activated !== 10,
            activated: this.activated === 10,
          }"
          @click="gotab(10)"
        >
          西北川渝
        </div>
        <div
          :class="{
            tabs: this.activated !== 11,
            activated: this.activated === 11,
          }"
          @click="gotab(11)"
        >
          东北滑雪
        </div>
        <div
          :class="{
            tabs: this.activated !== 12,
            activated: this.activated === 12,
          }"
          @click="gotab(12)"
        >
          珠三角
        </div>
        <div
          :class="{
            tabs: this.activated !== 13,
            activated: this.activated === 13,
          }"
          @click="gotab(13)"
        >
          其他
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="70%">
      <div style="text-align:center;font-weight:500;font-size:15px">
        复制成功，快去飞🐷抢购吧
      </div>
      <div class="ok" @click="close">OK</div>
    </el-dialog>
  </div>
</template>

<script>
import { getFliggy } from "@/api/caicai";
export default {
  metaInfo: {
    title: "重磅！飞猪双十一爆款清单",
    // meta: [
    //   { name: "discription", content: "展览11111111" },
    //     { name: "keywords", content: "作品展,品牌展,广告设计展" },
    // ],
  },
  data() {
    return {
      activated: 6,
      tableData: [],
      tableData6: [],
      tableData7: [],
      tableData8: [],
      tableData9: [],
      tableData10: [],
      tableData11: [],
      tableData12: [],
      tableData13: [],
      activeindex: 2,
      dialogVisible: false,
    };
  },
  //   beforeCreate() {
  //     var im = document.createElement("img"); //创建图片
  //     im.style.display = "none";
  //     im.style.width = "100px";
  //     im.style.height = "100px";
  //     im.src =
  //       "https://img.alicdn.com/imgextra/i4/O1CN01iq6zv31TseJYkkSBJ_!!6000000002438-2-tps-737-496.png";
  //     document.querySelector("body").appendChild(im);
  //   },
  created() {
    this.getData();
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.box.scrollTop = 500;
    });
    // window.onload = function() {
    //   setTimeout(function() {
    //     this.$refs.box.scrollTop = 500;
    //   }, 100); //100ms for example
    // };
    // let ul_module = document.getElementById("ul_module");
    // ul_module.scrollTop(10);
    // document.body.scrollTop = 100;
    // window.addEventListener("scroll", this.handleScroll, true);
    // console.log(document.documentElement.scrollTop);
  },
  methods: {
    goto() {
      this.$refs.box.scrollTop = 500;
    },
    close() {
      this.dialogVisible = false;
    },
    getScroll(event) {
      this.allevent = event;
      if (
        -50 < event.target.scrollTop &&
        event.target.scrollTop < 12 &&
        event.target.scrollTop !== 0
      ) {
        if (this.activated === 6) {
          event.target.scrollTop = this.tableData6.length * 50 + 20;
        }
        if (this.activated === 7) {
          event.target.scrollTop = this.tableData7.length * 50 + 20;
        }
        if (this.activated === 8) {
          event.target.scrollTop = this.tableData8.length * 50 + 20;
        }
        if (this.activated === 9) {
          event.target.scrollTop = this.tableData9.length * 50 + 20;
        }
        if (this.activated === 10) {
          event.target.scrollTop = this.tableData10.length * 50 + 20;
        }
        if (this.activated === 11) {
          event.target.scrollTop = this.tableData11.length * 50 + 20;
        }
        if (this.activated === 12) {
          event.target.scrollTop = this.tableData12.length * 50 + 20;
        }
        if (this.activated === 13) {
          event.target.scrollTop = this.tableData13.length * 50 + 20;
        }
        if (this.activated === 12) {
          event.target.scrollTop = this.tableData12.length * 50 + 20;
        }
      }
      if (
        this.activated === 6 &&
        this.tableData6.length * 100 - 700 < event.target.scrollTop &&
        event.target.scrollTop < this.tableData6.length * 100
      ) {
        event.target.scrollTop = this.tableData6.length * 50 - 600;
      }
      if (
        this.activated === 7 &&
        this.tableData7.length * 100 - 700 < event.target.scrollTop &&
        event.target.scrollTop < this.tableData7.length * 100
      ) {
        event.target.scrollTop = this.tableData7.length * 50 - 600;
      }
      if (
        this.activated === 8 &&
        this.tableData8.length * 100 - 700 < event.target.scrollTop &&
        event.target.scrollTop < this.tableData8.length * 100
      ) {
        event.target.scrollTop = this.tableData8.length * 50 - 600;
      }
      if (
        this.activated === 9 &&
        this.tableData9.length * 100 - 700 < event.target.scrollTop &&
        event.target.scrollTop < this.tableData9.length * 100
      ) {
        event.target.scrollTop = this.tableData9.length * 50 - 600;
      }
      if (
        this.activated === 10 &&
        this.tableData10.length * 100 - 700 < event.target.scrollTop &&
        event.target.scrollTop < this.tableData10.length * 100
      ) {
        event.target.scrollTop = this.tableData10.length * 50 - 600;
      }
      if (
        this.activated === 11 &&
        this.tableData11.length * 100 - 700 < event.target.scrollTop &&
        event.target.scrollTop < this.tableData11.length * 100
      ) {
        event.target.scrollTop = this.tableData11.length * 50 - 600;
      }
      if (
        this.activated === 12 &&
        this.tableData12.length * 100 - 700 < event.target.scrollTop &&
        event.target.scrollTop < this.tableData12.length * 100
      ) {
        event.target.scrollTop = this.tableData12.length * 50 - 600;
      }
      if (
        this.activated === 13 &&
        this.tableData13.length * 100 - 700 < event.target.scrollTop &&
        event.target.scrollTop < this.tableData13.length * 100
      ) {
        event.target.scrollTop = this.tableData13.length * 50 - 600;
      }
      //   if (1500 < event.target.scrollTop && event.target.scrollTop < 2200) {
      //     if (this.activated === 13) {

      //     }
      //   }
      this.scrollTop = event.target.scrollTop + 300;
      this.$nextTick(() => {
        this.activeindex = Math.floor((this.scrollTop - 50) / 100);
      });
      //   this.$set(this.activeindex);
    },
    getData() {
      getFliggy()
        .then((res) => {
          this.tableData = res.data.data.result;
          this.tableData.forEach((item) => {
            if (item.tabId === 6) {
              this.tableData6.push(item);
            }
            if (item.tabId === 7) {
              this.tableData7.push(item);
            }
            if (item.tabId === 8) {
              this.tableData8.push(item);
            }
            if (item.tabId === 9) {
              this.tableData9.push(item);
            }
            if (item.tabId === 10) {
              this.tableData10.push(item);
            }
            if (item.tabId === 11) {
              this.tableData11.push(item);
            }
            if (item.tabId === 12) {
              this.tableData12.push(item);
            }
            if (item.tabId === 13) {
              this.tableData13.push(item);
            }
          });
          this.concatCopy(this.tableData6);
          this.concatCopy(this.tableData7);
          this.concatCopy(this.tableData8);
          this.concatCopy(this.tableData9);
          this.concatCopy(this.tableData10);
          this.concatCopy(this.tableData11);
          this.concatCopy(this.tableData12);
          this.concatCopy(this.tableData13);
        })
        .catch(() => {});
    },
    concatCopy(arr) {
      const copyarr = arr;
      copyarr.forEach((item) => {
        arr.push(item);
      });
    },
    gotab(value) {
      this.activated = value;
      if (this.allevent) {
        this.allevent.target.scrollTop = 0;
      }
    },
    handleCopy(item) {
      this.copyData = item;
      this.copy(this.copyData);
    },
    copy(data) {
      let url = data;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.dialogVisible = true;
      oInput.remove();
    },
  },
};
</script>

<style scoped>
.ok {
  text-align: center;
  font-size: 15px;
  border-top: 1px solid rgb(238, 236, 236);
  margin-top: 20px;
  padding-top: 10px;
  color: #54cf65;
}
.middletop {
  margin-top: 160px;
}
.activetitle1 {
  width: 210px;
  height: 50px;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #c00710;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.activetitle2 {
  font-size: 14px;
  font-weight: 450;
  padding-bottom: 5px;
}
.activecopy {
  color: white;
  text-align: center;
  width: 100px;
  height: 40px;
  line-height: 40px;
  border-radius: 10px;
  font-size: 15px;
  background-color: #ffba3d;
  margin-left: -10px;
}
.activeprice {
  width: 30%;
  color: red;
  font-size: 600;
  font-weight: 500;
  width: 100px;
  height: 40px;
  background-color: rgb(238, 236, 236);
  border-radius: 10px;
  text-align: center;
  line-height: 40px;
}
.activeindex {
  display: flex;
  align-items: center;
  position: fixed;
  top: 230px;
  width: 100%;
  height: 150px;
  border-radius: 10px;
  background-color: white;
  box-shadow: darkgrey 10px 10px 30px 5px;
}
.tr1 {
  width: 5%;
  height: 90px;
  line-height: 90px;
  text-align: center;
}
.tr2 {
  width: 19%;
  height: 90px;
  line-height: 90px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tr3 {
  width: 17%;
  height: 90px;
  text-align: center;
  font-weight: 600;
  color: red;
  display: flex;
  align-items: center;
  padding: 0 2%;
  box-sizing: border-box;
}
.tr4 {
  height: 80px;
  width: 21%;
  text-align: center;
  font-size: 12.5px;
  padding: 5px 0 15px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}
.tr5 {
  height: 90px;
  width: 19%;
  text-align: center;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.tr6 {
  height: 90px;
  line-height: 90px;
  width: 19%;
  text-align: center;
  display: flex;
  align-items: center;
}
.tr7 {
  width: 5%;
  text-align: center;
  margin-left: 5px;
}
.middletable {
  display: flex;
  align-items: center;
  scroll-snap-type: both;
}
.middletablesum {
  margin-top: 35px;
}
.topth {
  display: flex;
  position: fixed;
  top: 0;
  overflow: auto;
}
.topth1 {
  width: 5vw;
  background-color: #ffba3d;
}
.topth2 {
  width: 19vw;
  height: 35px;
  line-height: 35px;
  font-size: 13px;
  font-weight: 550;
  background-color: #ffba3d;
  color: white;
  text-align: center;
}
.activated {
  width: 20vw;
  height: 5vh;
  line-height: 5vh;
  text-align: center;
  background-color: white;
  color: #ffba3d;
  font-size: 10px;
  font-weight: 500;
}
.btmtabs {
  width: 100vw;
  height: 5vh;
  display: flex;
  position: fixed;
  bottom: 0;
  overflow: auto;
}
.tabs {
  width: 20vw;
  height: 5vh;
  line-height: 5vh;
  text-align: center;
  background-color: #ffba3d;
  color: white;
  font-size: 10px;
  font-weight: 500;
}
.tabs {
  cursor: pointer;
}
.toptitle {
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 10px 2vw;
  box-sizing: border-box;
  height: 80px;
  font-size: 13px;
  background-color: rgb(238, 236, 236);
}
.midtable {
  width: 100%;
  overflow: auto;
  position: absolute;
  z-index: 10;
  top: 1px;
  bottom: 5vh;
}
.copy {
  color: white;
  text-align: center;
  width: 14.14vw;
  height: 5.86vw;
  line-height: 5.86vw;
  border-radius: 2.93vw;
  font-size: 2vw;
  background-color: #ffba3d;
}
.copy:hover {
  cursor: pointer;
}
.double {
  background-color: rgb(238, 236, 236);
}
</style>
