<template>
  <div class="bcgcolor">
    <header>
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i1/O1CN01Z68xcm1JDkp1G7kJs_!!6000000000995-2-tps-1920-936.png"
        alt=""
      />
      <div class="headimg" @click="gobuy">
        <img
          width="90%"
          src="https://img.alicdn.com/imgextra/i3/O1CN01tiK80P1pnXR5dSPhw_!!6000000005405-2-tps-438-119.png"
          alt=""
        />
      </div>
    </header>
    <section>
      <div class="main1">
        <img
          width="1010px"
          src="https://img.alicdn.com/imgextra/i1/O1CN01CMoLbK1szOZNYvhxj_!!6000000005837-2-tps-1122-336.png"
          alt=""
        />
      </div>
      <div class="main2">
        <img
          width="1060px"
          src="https://img.alicdn.com/imgextra/i2/O1CN01AJUkCC270DuHhXc5e_!!6000000007734-2-tps-1178-891.png"
          alt=""
        />
      </div>
      <div class="main3">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i3/O1CN01S9XRPB29tl8LTmywu_!!6000000008126-2-tps-1106-960.png"
          alt=""
        />
      </div>
      <div class="main4">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i4/O1CN01a4nJ7f1yb19NZ9aLm_!!6000000006596-2-tps-1106-960.png"
          alt=""
        />
      </div>
      <div class="main5">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i3/O1CN01abRGAK1RpKCnlVfmH_!!6000000002160-2-tps-1106-960.png"
          alt=""
        />
      </div>
      <div class="main6">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i4/O1CN016x6ls91Icehoo7D4W_!!6000000000914-2-tps-1106-960.png"
          alt=""
        />
      </div>
      <div class="main7">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i2/O1CN01P172tJ1sfhVZTFiMx_!!6000000005794-2-tps-1106-961.png"
          alt=""
        />
      </div>
      <div class="main8">
        <div class="buysum">
          <img
            width="1000px"
            src="https://img.alicdn.com/imgextra/i4/O1CN01zT958W1vyswWlI1Gl_!!6000000006242-2-tps-1122-1068.png"
            alt=""
          />
          <div id="buy"></div>
          <div class="buy" @click="buy"></div>
        </div>
      </div>
      <div class="main9">
        <div class="videosum">
          <img
            width="1000px"
            src="https://img.alicdn.com/imgextra/i4/O1CN01AJW5ud1LCUw7IgQOf_!!6000000001263-2-tps-1122-656.png"
            alt=""
          />
          <div class="video">
            <video
              controls
              width="100%"
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%85%A8%E5%9F%9F%E8%81%94%E5%8A%A8/%E5%85%A8%E5%B1%8F%E9%A1%B5%E8%A7%86%E9%A2%91%E6%A1%88%E4%BE%8BFinalVideo_1620441219.309778.MP4"
            ></video>
          </div>
          <div class="video2">
            <video
              controls
              width="100%"
              src="https://cloud.video.taobao.com/play/u/351149007/p/1/e/6/t/1/304136776723.mp4"
            ></video>
          </div>
          <div class="video3">
            <video
              controls
              poster="https://img.alicdn.com/imgextra/i2/O1CN01b6Xw261ODMWyBfopW_!!6000000001671-2-tps-141-254.png"
              width="100%"
              src="https://cloud.video.taobao.com/play/u/283601870/p/1/e/6/t/1/229138431322.mp4"
            ></video>
          </div>
          <div class="video4">
            <video
              controls
              width="100%"
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%85%A8%E5%9F%9F%E8%81%94%E5%8A%A8/FinalVideo_1620442519.437095.MP4"
            ></video>
          </div>
          <div class="video5">
            <video
              controls
              poster="https://img.alicdn.com/imgextra/i1/O1CN01I9HNwM1vL2UNoxSrc_!!6000000006155-2-tps-147-258.png"
              width="100%"
              src="https://cloud.video.taobao.com/play/u/4246583232/p/1/e/6/t/1/31
2803933806.mp4"
            ></video>
          </div>
          <div class="video6">
            <video
              controls
              poster="https://img.alicdn.com/imgextra/i3/O1CN01HLFO9k1z8uSiUMD1N_!!6000000006670-2-tps-149-261.png"
              width="100%"
              src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%85%A8%E5%9F%9F%E8%81%94%E5%8A%A8/%E9%B9%8C%E9%B9%91%E7%9A%AE%E8%9B%8B%E5%AE%9A%E7%A8%BF.MP4"
            ></video>
          </div>
        </div>
      </div>
      <div class="main10">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i2/O1CN01DFBjnS1y8cbAie6pz_!!6000000006534-2-tps-1122-682.png"
          alt=""
        />
      </div>
      <div class="main11">
        <img
          width="1070px"
          src="https://img.alicdn.com/imgextra/i2/O1CN01WjLEIW1Cjd2omqvMw_!!6000000000117-2-tps-1208-1237.png"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    gobuy() {
      let returnEle = null;
      returnEle = document.querySelector("#buy");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    buy() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/413");
    },
  },
};
</script>

<style scoped>
.video {
  width: 145px;
  height: 640px;
  position: absolute;
  top: 40.3%;
  left: 11%;
}
.video2 {
  width: 145px;
  height: 640px;
  position: absolute;
  top: 40.3%;
  left: 23.9%;
}
.video3 {
  width: 145px;
  height: 640px;
  position: absolute;
  top: 40.3%;
  left: 36.8%;
}
.video4 {
  width: 145px;
  height: 640px;
  position: absolute;
  top: 40.3%;
  left: 50.9%;
}
.video5 {
  width: 145px;
  height: 640px;
  position: absolute;
  top: 40.3%;
  left: 63.9%;
}
.video6 {
  width: 145px;
  height: 640px;
  position: absolute;
  top: 40.3%;
  left: 77%;
}
/* .video1 {
  width: 126px;
  height: 255px;
  border-radius: 10px;
} */
div /deep/.el-carousel__arrow {
  width: 40px;
  height: 40px;
  font-size: 18px;
}
/* .bcgcolor {
  background-color: white;
  background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01KuGleO1KRmWXUd91Y_!!6000000001161-2-tps-1920-7475.png");
  background-size: cover;
} */
.carousel-control.left {
  background-color: aqua;
}
.title {
  width: 110px;
  height: 30px;
  background-color: rgb(251, 199, 1);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.title1 {
  width: 110px;
  height: 30px;
  background-color: rgb(14, 210, 250);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.bttitle {
  margin-left: 10px;
  width: 150px;
  height: 30px;
  font-size: 16px;
  font-weight: 450;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.carousel1 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 570px;
}
.carousel2 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 100px;
}
#buy {
  position: absolute;
  top: 0;
}
.buy {
  width: 470px;
  height: 55px;
  color: white;
  position: absolute;
  bottom: 13%;
  left: 50%;
  line-height: 50px;
}
.buy:hover {
  cursor: pointer;
}
.buysum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1 {
  text-align: center;
  padding-top: 100px;
}
.main2 {
  text-align: center;
  margin-top: 65px;
  padding-left: 60px;
}
.main3 {
  text-align: center;
  margin-top: 75px;
}
.main4 {
  text-align: center;
  margin-top: 75px;
}
.main5 {
  text-align: center;
  margin-top: 75px;
}
.main6 {
  text-align: center;
  margin-top: 75px;
}
.main7 {
  text-align: center;
  margin-top: 75px;
}
.main8 {
  text-align: center;
  padding-top: 70px;
}
.main9 {
  text-align: center;
  padding-top: 70px;
}
.main10 {
  text-align: center;
  padding-top: 70px;
}
.main11 {
  text-align: center;
  padding-top: 70px;
  padding-right: 85px;
}
section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01TdkBLK1lI9q606Z18_!!6000000004795-2-tps-1920-10682.png");
  background-size: cover;
  margin: -255px auto 0;
  padding-bottom: 150px;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
.headimg {
  position: absolute;
  width: 25%;
  bottom: 25%;
  left: 52%;
  transform: translateX(-50%);
}
.headimg:hover {
  cursor: pointer;
}
</style>
