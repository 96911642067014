<template>
  <div>
    <section>
      <div class="main1">
        <img
          width="100%"
          src="https://img.alicdn.com/imgextra/i2/O1CN01z87HmD1WjRJZnM3kc_!!6000000002824-0-tps-1923-6637.jpg"
          alt=""
        />
        <div class="first" @click="goshop"></div>
        <div class="first1" @click="goshop1"></div>
        <div class="first2" @click="goshop2"></div>
        <div class="second" @click="goshop3"></div>
        <div class="second1" @click="goshop4"></div>
        <div class="second2" @click="goshop5"></div>
        <div class="wangwang" @click="gowang"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rightshow: true,
      phoneshow: false,
      a: "visible",
    };
  },
  methods: {
    changeStyle() {
      if (this.a === "visible") {
        this.a = "hidden";
      } else {
        this.a = "visible";
      }
    },
    gobuy() {
      let returnEle = null;
      returnEle = document.querySelector("#buy");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    goshop() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/470");
    },
    goshop1() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/471");
    },
    goshop2() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/472");
    },
    goshop3() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/473");
    },
    goshop4() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/474");
    },
    goshop5() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/475");
    },
    gowang() {
      window.open("https://www.taobao.com/go/market/webww/ww.php?ver=3&touid=txws大伟&siteid=cntaobao&status=1&charset=utf-8");
    },
  },
};
</script>

<style scoped>
.first {
  position: absolute;
  width: 8.81%;
  height: 0.59%;
  top: 59.4%;
  left: 21.8%;
  cursor: pointer;
}

.first1 {
  position: absolute;
  width: 8.81%;
  height: 0.59%;
  top: 63.2%;
  left: 21.8%;
  cursor: pointer;
}

.first2 {
  position: absolute;
  width: 8.81%;
  height: 0.59%;
  top: 66.83%;
  left: 21.8%;
  cursor: pointer;
}

.second {
  position: absolute;
  width: 6.6%;
  height: 0.86%;
  top: 70.15%;
  left: 72%;
  cursor: pointer;
}

.second1 {
  position: absolute;
  width: 6.6%;
  height: 0.85%;
  top: 73.85%;
  left: 72%;
  cursor: pointer;
}
.second2 {
  position: absolute;
  width: 8.9%;
  height: 0.7%;
  top: 78.65%;
  left: 59.5%;
  cursor: pointer;
}
 .wangwang {
  position: absolute;
  width: 6.01%;
  height: 0.36%;
  left: 54.15%;
  top: 98%;
  cursor: pointer;
} 

#buy {
  position: absolute;
  top: 0;
}
.buy {
  width: 470px;
  height: 55px;
  color: white;
  position: absolute;
  bottom: 13%;
  left: 50%;
  line-height: 50px;
}
.buy:hover {
  cursor: pointer;
}
.buysum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1 {
  text-align: center;
  padding-top: 50px;
  position: relative;
}

section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;

  margin: -50px auto 0;
  box-shadow: inset 0px 45px 10px -15px white;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
.headimg {
  position: absolute;
  width: 25%;
  bottom: 25%;
  left: 52%;
  transform: translateX(-50%);
}
.headimg:hover {
  cursor: pointer;
}
</style>
